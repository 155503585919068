/**Packages */
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import { FavoriteRecipe } from "../FavoriteRecipe";
import { RateRecipe } from "../RateRecipe";

/**Utils */
import { getCurrentLanguage, getIconByComponent, getClassNameByComponent } from "../../helpers/utils";
import { getIfRecipeDetailEnabled } from "../../helpers/enviroment";
import { getIfFavorite } from "../../helpers/menu";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_recipe.scss";

const Recipe = ({
    id,
    recipe,
    favoriteRecipeEnabled = true,
    onRecipeClick = () => {},
    rateRecipeEnabled = false,
    onRateRecipeClicked = () => {},
    resetUnderComponent = false,
}) => {
    const dispatch = useDispatch();
    const { api } = useContext(FirebaseContext);
    const [t, i18n] = useTranslation();
    const selectedProfile = useSelector(state => state?.user?.selectedProfile);
    const favoriteRecipes = selectedProfile?.favorite_recipes ? selectedProfile?.favorite_recipes : [];

    const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
    const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });

    const { id: idRecipe, course_name, name, occasion_id, isOccasionSingle } = recipe;

    const [isFavorite, setIsFavorite] = useState(getIfFavorite(favoriteRecipes, idRecipe));

    const isClickable = getIfRecipeDetailEnabled() && recipe;

    const toggleFavorite = async e => {
        e.preventDefault();
        e.stopPropagation();

        activateLoader();
        try {
            await api?.toggleRecipeFavorite(selectedProfile.docId, recipe);
        } catch (e) {
            console.log("errore");
            console.log(e);
        } finally {
            setIsFavorite(!isFavorite);
            deactivateLoader();
        }
    };

    const hideComponent = isOccasionSingle;
    let className = hideComponent
        ? "col-12 recipe-container recipe-container__only-text"
        : "col-12 col-lg-6 col-xl-6 recipe-container";

    className = isClickable ? className + " clickable" : className;

    const cssComponentClassName = getClassNameByComponent(
        occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
    );

    className += ` ${cssComponentClassName}`;

    if (rateRecipeEnabled) {
        className += ` with-rate-component`;
    }

    const key = rateRecipeEnabled ? `recipe-${idRecipe}-rate-container` : `recipe-${idRecipe}`;

    return !hideComponent ? (
        <>
            <div id={idRecipe} key={key} className={className} onClick={e => onRecipeClick(e, recipe)}>
                {getIconByComponent(
                    occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
                )}
                <h3 className="recipe__title">{!hideComponent ? course_name[getCurrentLanguage()] : ""}</h3>
                <p className="recipe__name">{name[getCurrentLanguage()]}</p>
                {favoriteRecipeEnabled && (
                    <FavoriteRecipe
                        cssComponentClassName={cssComponentClassName}
                        favoriteRecipes={favoriteRecipes}
                        id={idRecipe}
                        isFavorite={isFavorite}
                        toggleFavorite={toggleFavorite}
                    />
                )}
                {rateRecipeEnabled && (
                    <RateRecipe
                        recipeId={idRecipe}
                        internalId={id}
                        reset={resetUnderComponent}
                        onRateRecipe={onRateRecipeClicked}
                    />
                )}
            </div>
        </>
    ) : (
        <>
            <div id={idRecipe} key={key} className={className} onClick={e => onRecipeClick(e, recipe)}>
                <p className="recipe__name">{name[getCurrentLanguage()]}</p>
                {favoriteRecipeEnabled && (
                    <FavoriteRecipe
                        cssComponentClassName={cssComponentClassName}
                        favoriteRecipes={favoriteRecipes}
                        id={idRecipe}
                        isFavorite={isFavorite}
                        toggleFavorite={toggleFavorite}
                    />
                )}
                {rateRecipeEnabled && (
                    <RateRecipe
                        recipeId={idRecipe}
                        internalId={id}
                        reset={resetUnderComponent}
                        onRateRecipe={onRateRecipeClicked}
                    />
                )}
            </div>
        </>
    );
};

export default Recipe;
