/**Packages */
import { combineReducers } from "redux";

/**Reducers */
import { userReducer } from "./userReducer";
import { canteenReducer } from "./canteenReducer";
import { loaderReducer } from "./loaderReducer";
import { pageStateReducer } from "./pageStateReducer";
import { splashReducer } from "./splashReducer";
import { registerReducer } from "./registerReducer";
import { newsReducer } from "./newsReducer";
import { menuReducer } from "./menuReducer";
import { menuRulesReducer } from "./menuRulesReducer";

export const reducers = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    pageState: pageStateReducer,
    splash: splashReducer,
    canteen: canteenReducer,
    register: registerReducer,
    news: newsReducer,
    menu: menuReducer,
    menuRules: menuRulesReducer,
});
