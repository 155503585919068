import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Component */
import OnChange from "../../components/OnChange";
import Input from "../../components/Input";
import { Radio } from "../../components/Radio";
import { AvatarList } from "../../components/AvatarList";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/**Utils */
import {
    SITE_TITLE,
    MENU_DATE_FORMAT,
    DEFAULT_DIET,
    DEFAULT_YEAR,
    PAGES,
    LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY,
} from "../../helpers/constants";
import { AVATAR_IMAGES_KEYS } from "../../helpers/assets";
import {
    getYearCreateProfileHtml,
    getDietCreateProfileHtml,
    getCidsFilteredByRange,
    checkIsCanteenEnabled,
    getCidsByProfiles,
    getCurrentLanguage,
    getFormattedCid,
    openUrl,
} from "../../helpers/utils";
import { getFormattedMenu, isValidMenu, mergeDiets, mergeYears } from "../../helpers/menu";
import { getIfModifyNameIsEnabled, getIfAvatarModeIsEnabled } from "../../helpers/enviroment";
import { getFromLocalStorage, removeFromLocalStorage } from "../../helpers/localStorage";
import { getAndOpenStaticPageByUrl } from "../../helpers/url";
import { enableOverflowY } from "../../helpers/overflow";
import { success, error } from "../../helpers/toast";
import { checkString } from "../../helpers/validators";
import { seo } from "../../helpers/seo";
import history from "../../helpers/history";

/**API */
import { api } from "../../api/index";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { FINISH_REGISTRATION } from "../../redux/actions/registerActions";
import { USER_ADD_NEW_CANTEEN } from "../../redux/actions/userActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./__create_profile.scss";

/**Checking if privacy already confirmed */
const checkPrivacy = (canteenPrivacy, userPrivacy, canteen_id, language) => {
    const canteens = Object.keys(userPrivacy);
    if (canteens.indexOf(canteen_id) !== -1) {
        const userCanteenPrivacyData = userPrivacy[canteen_id];
        return userCanteenPrivacyData.privacy;
    }

    return false;
};

class CreateProfile extends OnChange {
    constructor(props) {
        super(props);

        const state = {
            canteen_id: null,
            canteensFormattedToSelect: [],
            step: 0,
            privacyText: "",
            termsText: "",
            newProfileName: "",
            privacyAccepted: false,
            termsAccepted: false,
            visible: "block",
            date: moment().format(MENU_DATE_FORMAT),
            dietsFormatted: {},
            diets: [],
            dietsToDisplay: [],
            years: [],
            yearsToDisplay: [],
            selectedDiet: DEFAULT_DIET,
            selectedYear: DEFAULT_YEAR,
            selectedAvatar: AVATAR_IMAGES_KEYS[0],
            setAsDefault: false,
            passedByRegistration: false,
            isUserAnonymous: false,
        };

        if (props.register?.fromRegistration && props.register?.canteen_id) {
            state.passedByRegistration = true;
            state.canteen_id = props.register.canteen_id;
            state.step = 1;
        }

        const newCanteenToAdd = getFromLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY);

        if (newCanteenToAdd && newCanteenToAdd !== "") {
            state.canteen_id = newCanteenToAdd;
            state.step = 1;
        }

        this.state = state;

        this.changeCanteenSelected = this.changeCanteenSelected.bind(this);
        this.toggleSetAsDefault = this.toggleSetAsDefault.bind(this);
        this.yearSelected = this.yearSelected.bind(this);
        this.dietSelected = this.dietSelected.bind(this);
        this.createProfile = this.createProfile.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.getStep = this.getStep.bind(this);
        this.openCustomUrl = this.openCustomUrl.bind(this);
        this.setPrivacyTermsData = this.setPrivacyTermsData.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.setSelectedAvatar = this.setSelectedAvatar.bind(this);

        this.inputCidRef = React.createRef();
        this.inputTermRef = React.createRef();
        this.inputPrivacyRef = React.createRef();
    }

    async loadMenuPerDate() {
        const canteen_id = getFormattedCid(this.state.canteen_id);

        if (canteen_id) {
            const token = await this.context.api.getToken();
            const firstMenuAvailable = await api.menu.getFirstMenuAvailable(token, canteen_id, this.state.date);
            const menu = getFormattedMenu(firstMenuAvailable);

            //const years = isValidMenu(menu) ? getFilteredYears(menu.diets, DEFAULT_DIET) : [];
            const years = isValidMenu(menu) ? Object.keys(menu.dietsFormatted) : [];
            const yearsToDisplay = mergeYears(years);
            const selectedYear = yearsToDisplay.length > 0 ? yearsToDisplay[0] : DEFAULT_YEAR;
            //const diets = isValidMenu(menu) ? menu.diets : [];
            const diets = isValidMenu(menu) ? menu.dietsFormatted[selectedYear] : [];
            const dietsToDisplay = mergeDiets(diets);
            const selectedDiet = dietsToDisplay.length > 0 ? dietsToDisplay[0] : DEFAULT_YEAR;

            this.setState(
                {
                    dietsFormatted: menu?.dietsFormatted ? menu?.dietsFormatted : [],
                    diets,
                    years,
                    selectedDiet,
                    selectedYear,
                    dietsToDisplay,
                    yearsToDisplay,
                },
                () => {
                    this.yearSelected(null, selectedYear);
                },
            );
        }
    }

    async componentDidMount(previousProps, previousState) {
        enableOverflowY();

        seo({
            title: SITE_TITLE + " || Create Profile",
            metaDescription: "",
        });

        try {
            this.props.activateLoader();

            switch (this.state.step) {
                case 0: {
                    const token = await this.context.api.getToken();
                    const canteensToSelect = getCidsByProfiles(this.props.user?.profiles);
                    const apiResult = await api.profile.getCanteensCodeWithName(token, canteensToSelect);
                    let canteensFormattedToSelect = [];
                    if (apiResult.success) {
                        canteensFormattedToSelect = getCidsFilteredByRange(apiResult.data.canteens);
                    }
                    const canteen_id =
                        canteensFormattedToSelect && canteensFormattedToSelect.length > 0
                            ? canteensFormattedToSelect[0].cid
                            : null;

                    this.setState({
                        canteen_id,
                        canteensFormattedToSelect,
                    });
                    break;
                }
                case 1: {
                    await this.setPrivacyTermsData();
                    break;
                }
                case 2: {
                    await this.loadMenuPerDate();
                    //this.forceUpdate();
                    break;
                }
                default: {
                    break;
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            removeFromLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY);
            this.props.deactivateLoader();
        }
    }

    async setPrivacyTermsData() {
        const self = this,
            newBaseState = {
                step: 1,
                isUserAnonymous: await self.context.api.getIfUserIsAnonymous(),
            },
            canteen_id = getFormattedCid(this.state.canteen_id);

        await this.context.api.getCanteenData(canteen_id);

        const canteenPrivacy = this.props.canteen?.privacy;
        const userPrivacy = this.props.user?.canteens;
        const lng = getCurrentLanguage();

        const result = checkPrivacy(canteenPrivacy, userPrivacy, canteen_id, lng);

        if (result) {
            this.setState(
                {
                    ...newBaseState,
                },
                () => {
                    this.nextStep(null, true);
                },
            );
        } else {
            const privacyText = canteenPrivacy[0][lng] ? canteenPrivacy[0][lng] : canteenPrivacy[0]["en"];
            const termsText = canteenPrivacy[1][lng] ? canteenPrivacy[1][lng] : canteenPrivacy[1]["en"];
            this.setState({
                ...newBaseState,
                privacyText,
                termsText,
            });
        }
    }

    checkForm() {
        let returnValue = true;
        if (!this.state.canteen_id || this.state.canteen_id === "") {
            error(this.props.t("create_profile.error.no_id_canteen"));
            returnValue = false;
        }
        if (!this.state.privacyAccepted) {
            error(this.props.t("create_profile.error.privacy_needed"));
            returnValue = false;
        }

        return returnValue;
    }

    async nextStep(e, skipStep = false) {
        if (e) {
            e.preventDefault();
        }
        const self = this;
        //Mi baso sullo step terminato
        try {
            this.props.activateLoader();
            switch (this.state.step) {
                case 0: {
                    if (checkIsCanteenEnabled(getFormattedCid(this.state.canteen_id))) {
                        await self.setPrivacyTermsData();
                    } else {
                        error(this.props.t("create_profile.error.no_id_canteen"));
                    }
                    break;
                }
                case 1: {
                    const formChecked = skipStep ? true : this.checkForm();

                    if (!skipStep && formChecked) {
                        const [token, userIsAnonymous] = await Promise.all([
                            this.context.api.getToken(),
                            this.context.api.getIfUserIsAnonymous(),
                        ]);

                        const cidFormatted = getFormattedCid(this.state.canteen_id);

                        const userCanteenData = {
                            id: cidFormatted,
                            privacy: this.state.privacyAccepted,
                            terms: !userIsAnonymous ? this.state.termsAccepted : false,
                        };

                        const result = await api.profile.setUserCanteenData(token, userCanteenData);

                        if (result && result.success) {
                            this.props.addNewUserCanteenData(userCanteenData);
                        } else {
                            error(this.props.t("error_general"));
                        }
                    }

                    if (skipStep || formChecked) {
                        this.setState(
                            {
                                step: 2,
                            },
                            async () => {
                                await self.loadMenuPerDate();
                                self.forceUpdate();
                            },
                        );
                    }
                    break;
                }
                case 2: {
                    break;
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    changeCanteenSelected(e) {
        const elem = e.currentTarget;
        const value = elem.value;
        this.setState({
            canteen_id: value,
        });
    }

    openCustomUrl(e, type = "privacy") {
        e.preventDefault();

        if (!type || type === "") return;

        const cid =
            this.props && this.props.canteen && this.props.canteen.canteen_id ? this.props.canteen.canteen_id : "";

        getAndOpenStaticPageByUrl(cid, getCurrentLanguage(), type);
    }

    yearSelected(e, year) {
        if (!year || year === "") return;

        const { dietsFormatted } = this.state;

        const newState = {
            selectedYear: year,
            dietsToDisplay: [],
        };

        newState.dietsToDisplay = dietsFormatted[year];
        newState.selectedDiet = newState.dietsToDisplay.length > 0 ? newState.dietsToDisplay[0] : DEFAULT_DIET;

        this.setState(newState);
    }

    dietSelected(e, diet) {
        if (!diet || !diet.diet_id) return;

        const newState = {
            selectedDiet: diet,
        };

        this.setState(newState);
    }

    setSelectedAvatar(e, selectedAvatar) {
        e.preventDefault();
        if (selectedAvatar) {
            this.setState({
                selectedAvatar,
            });
        }
    }

    toggleSetAsDefault(e) {
        const oldValue = this.state.setAsDefault;
        this.setState({
            setAsDefault: !oldValue,
        });
    }

    async createProfile(e) {
        e.preventDefault();
        let allOk = false;
        try {
            this.setState({ visible: "none" });
            this.props.activateLoader();
            const { selectedYear, selectedDiet } = this.state;
            if (selectedYear === DEFAULT_YEAR) {
                error(this.props.t("create_profile.error.year"));
                return;
            }

            if (selectedDiet === DEFAULT_DIET) {
                error(this.props.t("create_profile.error.diet"));
                return;
            }

            if (getIfModifyNameIsEnabled()) {
                if (!checkString(this.state.newProfileName, 1) && !this.state.isUserAnonymous) {
                    error(this.props.t("create_profile.error.name"));
                    return;
                }
            }

            if (getIfAvatarModeIsEnabled()) {
                if (!checkString(this.state.selectedAvatar, 4)) {
                    error(this.props.t("create_profile.error.avatar"));
                    return;
                }
            }

            const token = await this.context.api.getToken();
            let favorite = false;
            const dietToAdd = {
                id: selectedDiet.diet_id,
                name: selectedDiet.diet_name,
                occasion_id: selectedDiet.occasion_id,
                occasion_name: selectedDiet.occasion_name,
            };

            favorite = false;

            const result = await api.profile.addProfile(
                token,
                this.state.canteen_id,
                dietToAdd,
                favorite,
                getIfModifyNameIsEnabled(),
                this.state.newProfileName !== "" ? this.state.newProfileName : this.state.isUserAnonymous ? "" : null,
                getIfAvatarModeIsEnabled(),
                this.state.selectedAvatar !== "" ? this.state.selectedAvatar : null,
            );
            if (result && result.success) {
                allOk = true;
                success(this.props.t("create_profile.profile_generated"));

                if (this.state.passedByRegistration) {
                    this.props.resetFromRegister();
                    await this.context.api.deleteSignUpProcess();
                }

                //Setting correct canteen data
                await this.context.api.getCanteenData(this.state.canteen_id);
                //Reloading profiles
                const docId = await this.context.api.reloadProfiles(result?.data?.id);
                //Updating preferred
                await this.context.api.setSelectedProfile(docId);
                //Go to home;
                history.pushWithCheck("/" + PAGES.HOME);
            } else {
                const message =
                    result.message && typeof result.message === "string" ? result.message : result.message.message;
                error(message);
            }
        } catch (e) {
            console.log(e);
        } finally {
            if (!allOk) {
                this.setState({ visible: "block" });
            }
            this.props.deactivateLoader();
        }
    }

    renderInsertOrSelectCanteen() {
        const self = this;
        return (
            <>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="create-profile">
                                <div className="head-area">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-sm-12 col-md-12">
                                            <div className="default-heading text-uppercase">
                                                <h2>
                                                    <strong>{this.props.t("create_profile.title_1")}</strong>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-area top-row">
                                    <div className="default-heading text-center">
                                        <h3>
                                            <strong>{this.props.t("create_profile.canteen_selection")}</strong>
                                        </h3>
                                    </div>
                                </div>
                                {this.state.canteensFormattedToSelect.length > 0 ? (
                                    <>
                                        <div className="col-8 offset-2 text-center">
                                            <select
                                                id="select-canteen_id"
                                                className="select-canteenids"
                                                onChange={this.changeCanteenSelected}
                                            >
                                                {self.state.canteensFormattedToSelect.map(({ cid, name }) => {
                                                    let htmlToReturn = null;

                                                    const cid_splitted = cid.split("C_");
                                                    const cid_number = cid_splitted[1];

                                                    if (cid === cid_number) {
                                                        htmlToReturn = (
                                                            <option selected key={cid} value={cid_number}>
                                                                {cid_number} -{" "}
                                                                {name && name.length < 32
                                                                    ? name
                                                                    : name.substring(0, 31) + "..."}
                                                            </option>
                                                        );
                                                    } else {
                                                        htmlToReturn = (
                                                            <option key={cid} value={cid_number}>
                                                                {cid_number} -{" "}
                                                                {name && name.length < 32
                                                                    ? name
                                                                    : name.substring(0, 31) + "..."}
                                                            </option>
                                                        );
                                                    }

                                                    return htmlToReturn;
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-12 text-center mt-3">{this.props.t("or")}</div>
                                    </>
                                ) : null}
                                <div className="col-8 offset-2">
                                    <form>
                                        {/*Codice collegio*/}
                                        <Input
                                            innerRef={this.inputCidRef}
                                            type="number"
                                            className="form-control"
                                            id="signup_input_cid"
                                            placeholder={this.props.t("signup.cid")}
                                            value={this.state.canteen_id}
                                            onChange={e => this.onChange(e, "canteen_id")}
                                        />
                                    </form>
                                </div>

                                <div className="back-btn">
                                    <Link to="#" onClick={this.nextStep} className="back-arrow">
                                        <i className="icon-small-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderPrivacyTermsModal() {
        const userIsAnonymous = this.state.isUserAnonymous;

        return (
            <React.Fragment>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="head-area">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <div className="default-heading text-uppercase">
                                            <h2>
                                                {/**
                                                <strong>{this.props.t("create_profile.title_2")}</strong>
                                                 */}
                                                <strong>{this.props.t("privacy")}</strong>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="privacy-container">
                                    <span
                                        className="privacy-container__title clickable"
                                        onClick={e => this.openCustomUrl(e, "privacy")}
                                    >
                                        {this.props.t("privacy")}
                                        {":"}
                                    </span>
                                    <br />
                                    <span className="display-text">{this.state.privacyText}</span>
                                    <div className="checkbox-container">
                                        <Radio
                                            id="radio-input-privacy-accept"
                                            name="privacy"
                                            onChange={e => {
                                                this.setState({ privacyAccepted: true });
                                            }}
                                            checked={this.state.privacyAccepted}
                                            value={true}
                                            text={this.props.t("accept")}
                                        />
                                        <Radio
                                            id="radio-input-privacy-decline"
                                            name="privacy"
                                            onChange={e => {
                                                this.setState({ privacyAccepted: false });
                                            }}
                                            checked={!this.state.privacyAccepted}
                                            value={false}
                                            text={this.props.t("decline")}
                                        />
                                    </div>
                                </div>
                                {!userIsAnonymous && (
                                    <div className="terms-container">
                                        <span
                                            className="terms-container__title clickable"
                                            onClick={e => this.openCustomUrl(e, "terms")}
                                        >
                                            {this.props.t("terms")}
                                            {":"}
                                        </span>
                                        <br />
                                        <span className="display-text">{this.state.termsText}</span>
                                        <div className="checkbox-container">
                                            <Radio
                                                id="radio-input-terms-accept"
                                                name="terms"
                                                onChange={e => {
                                                    this.setState({ termsAccepted: true });
                                                }}
                                                checked={this.state.termsAccepted}
                                                value={true}
                                                text={this.props.t("accept")}
                                            />
                                            <Radio
                                                id="radio-input-terms-decline"
                                                name="terms"
                                                onChange={e => {
                                                    this.setState({ termsAccepted: false });
                                                }}
                                                checked={!this.state.termsAccepted}
                                                value={false}
                                                text={this.props.t("decline")}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="back-btn">
                                    {/**
                                            <Link to="#" onClick={this.nextStep} className="back-arrow">
                                                <i className="icon-small-arrow-left" />
                                            </Link>
                                         */}
                                    <Link to="#" onClick={this.nextStep} className="back-arrow">
                                        <i className="icon-small-arrow-right" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderCreateProfileModal() {
        return (
            <React.Fragment>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="create-profile">
                                <div className="head-area">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-12 col-sm-12 col-md-12">
                                            <div className="default-heading text-uppercase">
                                                <h2>
                                                    <strong>{this.props.t("create_profile.title_2")}</strong>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-area top-row">
                                    <div className="default-heading text-center">
                                        <h4 className="school-name">{this.props.canteen?.name}</h4>
                                    </div>
                                    <div className="default-heading text-center">
                                        <h3>
                                            <strong>{this.props.t("create_profile.year_selection")}</strong>
                                        </h3>
                                    </div>
                                    <div className="row align-items-start justify-content-center">
                                        {this.state.yearsToDisplay.map((year, idx) => {
                                            return (
                                                <div
                                                    key={"year-" + idx}
                                                    className="col-4 col-sm-4 col-md-3"
                                                    onClick={e => {
                                                        this.yearSelected(e, year);
                                                    }}
                                                >
                                                    {getYearCreateProfileHtml(
                                                        year,
                                                        null,
                                                        this.props.t,
                                                        year === this.state.selectedYear,
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className="row-area bottom-row">
                                    <div className="default-heading text-center">
                                        <h3>
                                            <strong>{this.props.t("create_profile.diet_selection")}</strong>
                                        </h3>
                                    </div>
                                    <div className="row align-items-start justify-content-center">
                                        {this.state.dietsToDisplay.map((diet, idx) => {
                                            return (
                                                <div
                                                    key={"diet-" + idx}
                                                    className="col-4 col-sm-3 col-md-2"
                                                    onClick={e => {
                                                        this.dietSelected(e, diet);
                                                    }}
                                                >
                                                    {getDietCreateProfileHtml(
                                                        diet,
                                                        this.props.t,
                                                        diet?.diet_id === this.state.selectedDiet?.diet_id,
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {getIfModifyNameIsEnabled() && !this.state.isUserAnonymous ? (
                                    <div className="row-area bottom-row">
                                        <div className="default-heading text-center">
                                            <h3>
                                                <strong>{this.props.t("create_profile.insert_name_profile")}</strong>
                                            </h3>
                                        </div>
                                        <div className="row align-items-start justify-content-center">
                                            <Input
                                                id="new-profile-name"
                                                className="form-control w-100"
                                                value={this.state.newProfileName}
                                                onChange={e => {
                                                    this.onChange(e, "newProfileName", true, "generic");
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {getIfAvatarModeIsEnabled() ? (
                                    <div className="row-area bottom-row">
                                        <div className="default-heading text-center">
                                            <h3>
                                                <strong>{this.props.t("create_profile.select_avatar")}</strong>
                                            </h3>
                                        </div>
                                        <div className="row align-items-start justify-content-center">
                                            <AvatarList
                                                selectedAvatar={this.state.selectedAvatar}
                                                onClick={this.setSelectedAvatar}
                                                useCollapsed={true}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <div className="back-btn">
                                    <Link to="#" onClick={this.createProfile} className="back-arrow">
                                        <i className="icon-small-arrow-right" />
                                    </Link>
                                    <div className="custom-control custom-checkbox element-hidden">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck2"
                                            value={this.state.setAsDefault}
                                            onChange={this.toggleSetAsDefault}
                                        />
                                        <label className="custom-control-label" htmlFor="customCheck2">
                                            {this.props.t("create_profile.remember_selection")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    getStep() {
        switch (this.state.step) {
            case 0: {
                return this.renderInsertOrSelectCanteen();
            }
            case 1: {
                return this.renderPrivacyTermsModal();
            }
            case 2: {
                return this.renderCreateProfileModal();
            }
            default: {
                return this.renderInsertOrSelectCanteen();
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                {/*
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade show scrollable" 
                    */}
                <div
                    className={this.props.register?.fromRegistration ? "modal fade show topper" : "modal fade show"}
                    id="create-profile-container"
                    style={{ display: this.state.visible }}
                >
                    {this.getStep()}
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        resetFromRegister: () => dispatch({ type: FINISH_REGISTRATION }),
        addNewUserCanteenData: data => dispatch({ type: USER_ADD_NEW_CANTEEN, payload: data }),
    };
};

CreateProfile.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateProfile));
