/**Packages */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";

/**Utils */
import { checkIfStringIsHtml, mediaButtonOnClick, getBaseOptionsForSlider } from "../../helpers/utils";

/**SCSS */
import "./_activitiesslider.scss";

const selector = ".activevades.owl-carousel";
const activeSelector = selector + " .owl-item.active .item";

class ActivitySlider extends React.Component {
    constructor(props) {
        super(props);

        const options = {};

        if (props.responsive) {
            options.responsive = props.responsive;
        } else {
            options.items = 1;
        }

        const baseOptions = getBaseOptionsForSlider();

        this.state = {
            useTitle: props.useTitle ? props.useTitle : false,
            useSubTitle: props.useSubTitle ? props.useSubTitle : false,
            activities: props && props.activities ? props.activities : [],
            options: {
                ...baseOptions,
                margin: 0,
                center: true,
                responsiveClass: true,
                dots: true,
                nav: false,

                //navContainer: "<div style='display: none'>",
                //navText: '',
                //navElement: "<div style='display: none'>",

                //callbacks: true,
                startPosition: 0,
                onTranslated: function () {
                    if ($(activeSelector)) {
                        if ($(activeSelector).attr("data")) {
                            const selected = JSON.parse($(activeSelector).attr("data"));
                            if (selected) {
                                //console.log(selected);
                                //self.props.onDietSelected(event, selected);
                            }
                        }
                    }
                },
                ...options,
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        let newState = {};
        if (nextProps && nextProps.activities) {
            newState = {
                activities: nextProps.activities,
                optionsNewsSlider: {
                    ...this.state.optionsNewsSlider,
                    loop: nextProps.activities?.length > 1,
                    startPosition: 0,
                },
            };
        } else {
            newState = {
                activities: [],
                optionsNewsSlider: {
                    ...this.state.optionsNewsSlider,
                },
            };
        }

        this.setState(newState, e => {
            this.refresh({});
        });
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    getOnClickMethodForActivity(e, activity) {
        const { t, onClick } = this.props;

        if (activity) {
            if (activity.file) {
                return mediaButtonOnClick(e, activity?.file);
            } else {
                return onClick(e, activity);
            }
        }

        return null;
    }

    render() {
        const { t, onClick, noItemsText = null } = this.props;
        const { activities, options, useTitle, useSubTitle } = this.state;

        return (
            <React.Fragment>
                {activities && Array.isArray(activities) && activities.length > 0 ? (
                    <OwlCarousel className="activevades owl-carousel" {...options}>
                        {activities.map((activity, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="item clickable"
                                    //onClick={e => this.getOnClickMethodForActivity(e, activity)}
                                    onClick={e => onClick(e, activity)}
                                >
                                    {useTitle ? (
                                        <div className="default-heading home-activity-title">
                                            {checkIfStringIsHtml(activity.title) ? (
                                                <h2
                                                    dangerouslySetInnerHTML={{
                                                        __html: activity.title,
                                                    }}
                                                />
                                            ) : (
                                                <h2>{activity.title}</h2>
                                            )}
                                        </div>
                                    ) : null}
                                    <figure className="scorri-gruppo" style={{ minHeight: "150px" }}>
                                        {activity.img ? (
                                            <img src={activity.img ? activity.img : null} alt="immagine-attività" />
                                        ) : null}
                                    </figure>
                                    {useSubTitle ? (
                                        <div className="activity__subtitle">
                                            {checkIfStringIsHtml(activity.title) ? (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: activity.title,
                                                    }}
                                                />
                                            ) : (
                                                <span>{activity.title}</span>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="info-text">
                                            <p>{activity.abstract}</p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </OwlCarousel>
                ) : (
                    <div className="box-area no-data-container">{noItemsText || t("news.no_data_activities")}</div>
                )}
            </React.Fragment>
        );
    }
}

export default ActivitySlider;
