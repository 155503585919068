export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_UPDATE_FIELDS = "USER_UPDATE_FIELDS";
export const USER_SELECTED_PROFILE = "USER_SELECTED_PROFILE";
export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";
export const USER_UPDATE_NAME_PROFILE = "USER_UPDATE_NAME_PROFILE";
export const USER_UPDATE_AVATAR_PROFILE = "USER_UPDATE_AVATAR_PROFILE";
export const USER_ADD_NEW_CANTEEN = "USER_ADD_NEW_CANTEEN";
export const USER_DELETE_PROFILE = "USER_DELETE_PROFILE";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_CHANGE_CANTEEN = "USER_CHANGE_CANTEEN";
export const USER_CHANGE_PROFILE = "USER_CHANGE_PROFILE";
export const SET_USER_PROFILES = "SET_USER_PROFILES";

export const UPDATE_USER_PROFILE_FAVORITE_RECIPES = "UPDATE_USER_PROFILE_FAVORITE_RECIPES";
