const Margin = ({ marginTop = "auto", marginBottom = "auto", marginLeft = "auto", marginRight = "auto" }) => {
    const marginConfig = {
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        backgroundColor: "transparent",
        border: "none",
    };
    return <div style={marginConfig}></div>;
};

export default Margin;
