/*
const getWindowHeight = () => {
    return getWindowSize().height;
};

const getWindowWidth = () => {
    return getWindowSize().width;
};
*/

/**
 *
 * @returns The method return the window size
 */
export const getWindowSize = () => {
    return {
        height: window.innerHeight,
        width: window.innerWidth,
    };
};
/**
 *
 * @returns The method returns if current screen is mobile or not
 */
export const getIfMobile = () => {
    return getWindowSize().width < 768;
};
