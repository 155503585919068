import { useEffect, useState, useContext } from "react";
import { Route, Switch, Redirect, Router, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

/**Helpers */
import { INDEX_URL, PAGES } from "../../helpers/constants";
import { historyPageViewListener } from "../../helpers/dataLayer";
import { getIfAvatarModeIsEnabled } from "../../helpers/enviroment";
import history from "../../helpers/history";

/**Pages */
import Registration from "../../pages/Registration";
import Login from "../../pages/Login";
import Menu from "../../pages/Menu";
import RecipeDetail from "../../pages/RecipeDetail";
import ChangePassword from "../../pages/ChangePassword";
import Messages from "../../pages/Messages";
import ResetPassword from "../../pages/ResetPassword";
import Account from "../../pages/Account";
import Activity from "../../pages/Activity";
import Chat from "../../pages/Chat";
import CreateProfile from "../../pages/Profile";
import EditProfile from "../../pages/EditProfile";
import MailBox from "../../pages/MailBox";
import News from "../../pages/News";
import Activities from "../../pages/Activities";
import Favorites from "../../pages/Favorites";
import Allergens from "../../pages/Allergens";

/**Components */
import { PrivateRoute } from "../PrivateRoute";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

const PrivateRouteHandler = (isLogged, path, component) => (
    <PrivateRoute isLogged={isLogged} exact path={path} component={component} />
);

function Main() {
    const { api } = useContext(FirebaseContext);
    const { search, pathname } = useLocation();
    const user = useSelector(state => state.user);
    const userFromFB = api.getCurrentUser();
    const [isLogged, setIsLogged] = useState(false);

    //Listener page handling ref;
    let listenRef = null;

    /**LISTENER USER IS LOGGED */
    useEffect(() => {
        setIsLogged(user !== null && user.uid !== null && userFromFB !== null);
    }, [user, userFromFB]);

    /**LISTENER CHANGING PAGE */
    useEffect(() => {
        if (listenRef) {
            listenRef();
        }

        historyPageViewListener({ pathname });

        listenRef = history.listen(historyPageViewListener);

        return () => {
            if (listenRef) {
                listenRef();
            }
        };
    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route exact path={`${INDEX_URL}${PAGES.SIGNUP}`} component={Registration} />
                <Route exact path={`${INDEX_URL}${PAGES.LOGIN}`} component={Login} />
                <Route exact path={`${INDEX_URL}${PAGES.RESET_PASSWORD}`} component={ResetPassword} />
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.MAIL_BOX}`, MailBox)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.ACTIVITY}`, Activity)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.CHAT}`, Chat)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.MESSAGES}`, Messages)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.NEWS}`, News)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.ACTIVITIES}`, Activities)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.CREATE_PROFILE}`, CreateProfile)}
                {getIfAvatarModeIsEnabled() &&
                    PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.EDIT_PROFILE}`, EditProfile)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.ACCOUNT}`, Account)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.CHANGE_PASSWORD}`, ChangePassword)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.RECIPE_DETAIL}`, RecipeDetail)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.FAVORITES_RECIPES}`, Favorites)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.ALLERGENS}`, Allergens)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}${PAGES.HOME}`, Menu)}
                {PrivateRouteHandler(isLogged, `${INDEX_URL}:canteen`, Menu)}
                <Redirect from="*" to={`${INDEX_URL}${PAGES.HOME}${search}`} />
            </Switch>
        </Router>
    );
}
export default Main;
