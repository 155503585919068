/**
 *
 * @param {*} value - string to check
 * @param {*} minLength - minimum number of characters to check
 * @returns true if string is validated otherwise false
 */
export const checkString = (value, minLength = 0) => {
    if (!typeof value === "string" || value.length < minLength) return false;

    return true;
};
/**
 *
 * @param {*} value - email to check
 * @returns true if email is validated otherwise false
 */
export const validateEmail = value => {
    if (!checkString(value)) return false;

    const mailRegex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    return value.match(mailRegex);
};
/**
 *
 * @param {*} - password to check
 * @returns true if password is validated otherwise false
 */
export const validatePassword = value => {
    if (!checkString(value)) return false;

    return value.length >= 6;
};
/**
 *
 * @param {*} value_1 - first string to check
 * @param {*} value_2 - second sting to check
 * @returns true if strings are equals, otherwise false
 */
export const validateEquality = (value_1, value_2) => {
    if (!checkString(value_1) || !checkString(value_2)) return false;

    return value_1 === value_2;
};
/**
 *
 * @param {*} value - string to check
 * @param {*} minLength - minimum number of characters to check
 * @returns true if string is validated otherwise false
 */
export const validateGenericString = (value, minLength = 0) => {
    if (!checkString(value)) return false;

    return value.length >= minLength;
};
