/**API */
import { menuAPIs } from "./menu";
import { newsAPIs } from "./news";
import { canteenAPIs } from "./canteen";
import { profileAPIs } from "./profile";
import { sessionAPIs } from "./session";

export const api = {
    menu: menuAPIs,
    news: newsAPIs,
    canteen: canteenAPIs,
    profile: profileAPIs,
    session: sessionAPIs,
};
