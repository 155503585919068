/**Packages */
import React from "react";

/**Helpers */
import history from "../../helpers/history";
import { PAGES } from "../../helpers/constants";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { getFromSessionStorage } from "../../helpers/sessionStorage";

/**Components */
import OnChange from "../../components/OnChange";
class HandlerUser extends OnChange {
    constructor(props) {
        super(props);
        this.state = {
            viewVisible: false,
        };

        this.checkUserData = this.checkUserData.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidUpdate() {
        /*
        if (this.checkUserData()) {
            history.pushWithCheck("/" + PAGES.HOME);
        } else if (!this.state.viewVisible) {
            this.setState({ viewVisible: true });
        }
        */
        if (!this.checkUserData() && !this.state.viewVisible) {
            this.setState({ viewVisible: true });
        }
    }

    checkUserData() {
        if (
            (this.props.user && this.props.user.uid) ||
            this.context.api.getCurrentUser() ||
            getFromSessionStorage("uid") ||
            getFromLocalStorage("uid")
        ) {
            return true;
        }

        return false;
    }

    render(page) {
        return this.props.splash.splashVisible || this.props.loader.loaderVisible ? null : page;
    }
}

export default HandlerUser;
