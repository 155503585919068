/**Packages */
import { useState } from "react";

/**Helpers */
import { AVATAR_IMAGES_KEYS, getAvatarImage } from "../../helpers/assets";
import { getIfMobile } from "../../helpers/screen";

/**SCSS */
import "./_avatarlist.scss";

const LAST_KEY = "LAST";

export const AvatarList = ({ selectedAvatar = null, onClick = null, useCollapsed = false }) => {
    const [useAllAvatar, setUseAllAvatar] = useState(false);

    let avatarListHtml = null;
    if (useCollapsed) {
        const breakElementIndex = getIfMobile() ? 5 : 7;
        const allItems = AVATAR_IMAGES_KEYS ? [...AVATAR_IMAGES_KEYS] : [];
        const items = !useAllAvatar ? [...allItems.splice(0, breakElementIndex), LAST_KEY] : [...allItems];
        avatarListHtml = (
            <div className="avatar-list-container w-100">
                {items.map((avatarKey, i) => {
                    if (avatarKey !== LAST_KEY) {
                        const className = avatarKey === selectedAvatar ? "avatar selected" : "avatar";
                        return (
                            <div
                                className="avatar-img"
                                onClick={e => {
                                    onClick(e, avatarKey);
                                }}
                                key={"avatar-" + i}
                            >
                                <img
                                    className={className}
                                    src={getAvatarImage(avatarKey)}
                                    alt={`avatar-${avatarKey}`}
                                />
                            </div>
                        );
                    } else {
                        return (
                            <div
                                className="avatar-img clickable"
                                key={"avatar-" + i}
                                onClick={e => {
                                    setUseAllAvatar(true);
                                }}
                            >
                                <div className="avatar-button-last">{"Vedi tutti"}</div>
                            </div>
                        );
                    }
                })}
            </div>
        );
    } else {
        avatarListHtml = (
            <div className="avatar-list-container">
                {AVATAR_IMAGES_KEYS.map((avatarKey, i) => {
                    const className = avatarKey === selectedAvatar ? "avatar selected" : "avatar";
                    return (
                        <div
                            className="avatar-img"
                            onClick={e => {
                                onClick(e, avatarKey);
                            }}
                            key={"avatar-" + i}
                        >
                            <img className={className} src={getAvatarImage(avatarKey)} alt={`avatar-${avatarKey}`} />
                        </div>
                    );
                })}
            </div>
        );
    }

    return avatarListHtml;
};
