/**Packages */
import { useState } from "react";
import { useTranslation } from "react-i18next";

/**Helpers */
import { getFullLanguageName, enabledLanguages, setCurrentLanguage } from "../../helpers/utils";

/**Components */
import Modal from "../Modal";

/**Images */
import pencilSvg from "../../images/pencil.svg";

/**SCSS */
import "./_language_selector.scss";

const LanguageSelector = ({ languages }) => {
    const [language_popup, setLanguagePopup] = useState(false);
    const { t, i18n } = useTranslation();

    const changeLanguage = (e, lng) => {
        if (i18n.language !== lng) {
            setCurrentLanguage(lng);
        }

        setLanguagePopup(false);
    };

    return (
        <>
            <div className="row d-flex justify-content-center language-container">
                <div className="col-9 text-left justify-content-left">{getFullLanguageName(i18n.language, t)}</div>
                <div className="col-3 text-right justify-content-right" onClick={e => setLanguagePopup(true)}>
                    <img className="pencil-img" src={pencilSvg} alt="pencil" />
                </div>
            </div>
            <Modal id="language-selector-modal" visible={language_popup} close={e => setLanguagePopup(false)}>
                <div className="col-12">
                    <div className="row d-flex justify-content-center default-heading">
                        <h3>{t("language_selector.title")}</h3>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <ul id="language-selector-ul" className="language-selector-container">
                            {languages.map((lng, idx) => {
                                const selected = lng === i18n.language;
                                const className =
                                    idx === 0
                                        ? "language-selector-li-container first"
                                        : idx === languages.length - 1
                                        ? "language-selector-li-container last"
                                        : "language-selector-li-container";
                                return selected ? (
                                    <div key={"language-" + idx} className={`${className} col-12`}>
                                        <li
                                            className="language-selector-li"
                                            onClick={e => changeLanguage(e, lng)}
                                            key={lng}
                                        >
                                            <span className="text-left w-100">{getFullLanguageName(lng, t)}</span>
                                            <i className="fa fa-check text-right check-icon"></i>
                                        </li>
                                    </div>
                                ) : (
                                    <div key={"language-" + idx} className={`${className} col-12`}>
                                        <li
                                            className="language-selector-li"
                                            onClick={e => changeLanguage(e, lng)}
                                            key={lng}
                                        >
                                            {getFullLanguageName(lng, t)}
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LanguageSelector;
