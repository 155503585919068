/**Packages */
import $ from "jquery";

/**Utils */
import { getFormattedCid } from "./utils";

/**Config */
import { default as config } from "./../firebase/config";
/**
 *
 * @param {*} cid - Canteen id
 * @returns The script to add to index.html, in order to import the custom css
 */
export const importCustomCSS = (cid = null) => {
    if (!cid || cid === "") return;

    /**Checking if cid is correctly created */
    cid = getFormattedCid(cid);

    /**Removing old custom css style */
    $("#customCSS, #parentCustomCSS").remove();

    /**Generating the full link to get the canteen style*/
    const cssPath = config.API_URL + "/api/canteenStyle?cid=" + cid;

    /**Append it to the head to load*/
    $("head").append("<link rel='stylesheet' media='screen' href='" + cssPath + "' id='customCSS' />");
};
