/**Packages */
import Select from "react-select";

/*Helpers */
import { getCurrentLanguage } from "../../helpers/utils";
import { getTranslatedString } from "../../helpers/translation";

/*SCSS */
import "./custom_select.scss";

const getFormattedOptions = (options, selectedItem, defaultItem) => {
    if (!options || options.length === 0) return null;

    return options.map((option, idx) => {
        const occasion = option;
        if (occasion) {
            const hrefOccasionTab = getTranslatedString(occasion.occasion_name, getCurrentLanguage()).toLowerCase(),
                id = hrefOccasionTab + "-tab",
                href = "#" + hrefOccasionTab,
                aria_controls = hrefOccasionTab,
                selected =
                    selectedItem === defaultItem ? idx === 0 : occasion?.occasion_id === selectedItem?.occasion_id;
            return (
                <option
                    id={id}
                    key={idx}
                    data-toggle="tab"
                    role="tab"
                    aria-controls={aria_controls}
                    aria-selected={selected}
                    href={href}
                    value={occasion.occasion_id}
                >
                    {occasion.occasion_name[getCurrentLanguage()]}
                </option>
            );
        }
    });
};

const onChangeRoot = (e, occasions, occasionSelected) => {
    const id = Number(e.target.value);

    if (id) {
        const occasion = occasions.find(occasion => {
            return occasion.occasion_id === id;
        });

        if (occasion) {
            occasionSelected(e, occasion);
        }
    }
};

const onChangeRoot2 = (e, occasionSelected) => {
    if (e) {
        occasionSelected(e, e.fullData);
    }
};

const CustomSelect = ({
    id = "custom-select",
    items = [],
    selectedItem = null,
    defaultItem = null,
    onChange = () => {
        console.log("onChange");
    },
}) => {
    if (!items || items.length === 0) return null;

    selectedItem = selectedItem ? selectedItem : items[0];

    const selectedId = selectedItem.occasion_id + "";

    return (
        <select id={id} value={selectedId} onChange={e => onChangeRoot(e, items, onChange)} className="custom-select">
            {getFormattedOptions(items, selectedItem, defaultItem)}
        </select>
    );
};

const getSingleOption = item => {
    return {
        label: item.occasion_name[getCurrentLanguage()],
        value: item.occasion_id,
        fullData: item,
    };
};

const getFormattedOptionsForSelect = items => {
    if (!items || items.length === 0) return null;

    return items.map(item => {
        return getSingleOption(item);
    });
};

const customStyles = {
    /*
    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
    }),
    */
    control: (provided, state) => ({
        // none of react-select's styles are passed to <Control />
        ...provided,
        borderColor: "#283897",
    }),
    /*
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return {
            ...provided,
            //opacity, transition
        };
    },
    */
    indicatorSeparator: (provided, state) => {
        return {
            isDisabled: true,
        };
    },
    dropdownIndicator: (provided, state) => {
        return {
            display: "flex",
            padding: "8px",
            color: "red",
        };
    },
};

const CustomSelect2 = ({
    id = "custom-select",
    items = [],
    selectedItem = null,
    defaultItem = null,
    onChange = () => {
        console.log("onChange");
    },
}) => {
    if (!items || items.length === 0) return null;

    selectedItem = selectedItem ? selectedItem : items[0];

    return (
        <Select
            id={id}
            styles={customStyles}
            value={getSingleOption(selectedItem)}
            options={getFormattedOptionsForSelect(items, selectedItem, defaultItem)}
            onChange={e => onChangeRoot2(e, onChange)}
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            className="custom-select-2"
        />
    );
};

export default CustomSelect2;
