/**Packages */
import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Utils */
import {
    getYearCreateProfileHtml_profile,
    getDietCreateProfileHtml_profile,
    getYearByDietName,
    getIconByYear,
} from "../../helpers/utils";
import { getIfToggleSwitchModeIsEnabled, getIfModifyNameIsEnabled } from "./../../helpers/enviroment";
import { getAvatarImage } from "./../../helpers/assets";

/**Firebase */
import { FirebaseContext } from "./../../firebase/firebase";

/**Redux */
import { USER_UPDATE_NAME_PROFILE } from "../../redux/actions/userActions";

/**Components */
import { Input } from "../Input";
import { Point } from "../Point";
import { ActionButton } from "../ActionButton";
import Margin from "../Margin";

/**Images */
import pencilSvg from "../../images/pencil_w.svg";

/**SCSS */
import "./_profile.scss";

const getBoxesProfileData = (
    profile,
    name,
    dataBoxClassName,
    isCurrentSelected,
    year,
    t,
    onClick,
    characterLimits = false,
    canModifyName,
    onModifyNameHandler,
    modifyNameStatus,
    onProfileNameChange = null,
    newName,
    goToEditProfilePage = null,
    seeName = true,
    canSeeAvatar = true,
) => {
    const id = profile.docId;
    return (
        <div className={"col-12 profile-container clickable"}>
            {seeName ? (
                <div className="dropdown-header">
                    {!modifyNameStatus ? (
                        <div className="profile-name-container">
                            {name}
                            <span className="school-name">{profile.canteen_name}</span>
                        </div>
                    ) : (
                        <div className="profile-name-container">
                            <Input
                                id={"profile-input-text-" + id}
                                type="text"
                                placeholder=""
                                value={newName}
                                onChange={e => onProfileNameChange(e, e?.target?.value)}
                            />
                            <span className="school-name">{profile.canteen_name}</span>
                        </div>
                    )}
                </div>
            ) : null}
            <>
                <div className="profile-data-container" onClick={onClick}>
                    {
                        //isCurrentSelected ? <Point /> : null
                    }
                    <div className={dataBoxClassName}>{getYearCreateProfileHtml_profile(year, null, t, false)}</div>
                    <div className={dataBoxClassName + " profile__diet-box"}>
                        {getDietCreateProfileHtml_profile(profile.diet, t, false)}
                    </div>
                    {profile.avatar && canSeeAvatar ? (
                        <div className={dataBoxClassName}>
                            <div className="item">
                                <img src={getAvatarImage(profile.avatar)} className="avatar" />
                                <div className="default-heading">
                                    <h2 className="avatar-text">{"___"}</h2>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                {canModifyName
                    ? getModifyName(onModifyNameHandler, profile, modifyNameStatus, goToEditProfilePage, t)
                    : null}
            </>
        </div>
    );
};

const getFullName = (name, characterLimits) => {
    if (!characterLimits) return name;

    return name.length > characterLimits ? name.substr(0, characterLimits) + "..." : name;
};

const getModifyName = (
    onModifyNameHandler = null,
    profile = null,
    modifyNameStatus = false,
    goToEditProfilePage = null,
    t,
) => {
    const onClick = goToEditProfilePage
        ? e => {
              e.preventDefault();
              e.stopPropagation();
              goToEditProfilePage(e, profile);
          }
        : e => {
              e.preventDefault();
              onModifyNameHandler(e, profile);
          };

    /*
    return (
        <div className="modify-name-container" onClick={onClick}>
            {!modifyNameStatus ? (
                <img className="pencil-img" src={pencilSvg} alt="pencil" />
            ) : (
                <i className="fa fa-check" />
            )}
        </div>
    );
    */

    return (
        <div className="modify-name-container" onClick={onClick}>
            {!modifyNameStatus ? (
                <button className="btn btn-primary modify-button clickable">
                    {t("modify")} &nbsp;&nbsp;
                    <img className="pencil-img" src={pencilSvg} alt="pencil" />
                </button>
            ) : (
                <i className="fa fa-check" />
            )}
        </div>
    );
};

const getProfileName = (profile, characterLimits, t) => {
    return getIfModifyNameIsEnabled()
        ? profile?.name
            ? profile.name
            : getFullName(`${t("account.profile")} ${profile.pos}`, characterLimits)
        : getFullName(`${t("account.profile")} ${profile.pos}`, characterLimits);
};

/**
 * 
 * @param {*} param0
 * {
 *  profile: profile data
    onClick: onClick method
    isCurrentSelected: current selected flag
    className = null,
    useLittle = false,
    characterLimits = false,
    canModifyName = false,
    onDeleteClick,
    useSimplifyVersion = false
 * } 
 * @returns 
 */
const Profile = ({
    profile,
    onClick,
    isCurrentSelected,
    className = null,
    useLittle = false,
    characterLimits = false,
    canModifyName = false,
    onDeleteClick,
    useSimplifyVersion = false,
    seePen = false,
    goToEditProfilePage = null,
    seeName = true,
    canSeeAvatar = true,
    onChildClick = () => {},
}) => {
    const firebase = useContext(FirebaseContext);
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const updateUserName = (id, name) => dispatch({ type: USER_UPDATE_NAME_PROFILE, payload: { id, name } });

    const [modifyNameStatus, setModifyNameStatus] = useState(false);
    const [cancelable, setCancelable] = useState(false);

    const year = getYearByDietName(profile.diet);
    /*

    Get full name with canteen

    const name = profile?.name
    ? profile.name
    : getFullName(
          `${t("account.profile")} ${profile.pos} -\n${
              profile.canteen_name ? " " + profile.canteen_name : " " + profile.cid
          }`,
          characterLimits,
          
      );
    */

    const name = getProfileName(profile, characterLimits, t);

    const [newName, setNewName] = useState(name);

    let dataBoxClassName = "data-box";
    dataBoxClassName = !useLittle ? dataBoxClassName : dataBoxClassName + " little";
    dataBoxClassName = !isCurrentSelected ? dataBoxClassName : dataBoxClassName + " selected";

    const toggleModifyName = async (event, profile) => {
        event.preventDefault();
        event.stopPropagation();

        if (getIfModifyNameIsEnabled()) {
            if (modifyNameStatus && newName) {
                const result = await firebase.api.updateProfileName(profile?.docId, newName);
                if (result) {
                    updateUserName(profile?.docId, newName);
                }
            }
        }

        if (canModifyName) {
            setModifyNameStatus(!modifyNameStatus);
        }

        setCancelable(!cancelable);
    };

    const onProfileNameChange = (e, newValue) => {
        e.preventDefault();
        e.stopPropagation();

        if (newValue && newValue !== "") {
            setNewName(newValue);
        } else {
            setNewName("");
        }
    };

    const htmlToReturn = !useSimplifyVersion ? (
        profile ? (
            cancelable ? (
                <div className={"col-12 profile-container"}>
                    <div className="dropdown-header">
                        {!canModifyName || !modifyNameStatus ? (
                            name
                        ) : (
                            <Input
                                id={"profile-input-text-" + profile.docId}
                                type="text"
                                placeholder=""
                                value={newName}
                                onChange={e => onProfileNameChange(e, e?.target?.value)}
                            />
                        )}
                    </div>
                    <div className={"profile-data-container"}>
                        {isCurrentSelected ? <Point /> : null}
                        <div className={dataBoxClassName + " clickable"} onClick={onClick}>
                            {getYearCreateProfileHtml_profile(year, null, t, false)}
                        </div>
                        <div className={dataBoxClassName + " profile__diet-box" + " clickable"} onClick={onClick}>
                            {getDietCreateProfileHtml_profile(profile.diet, t, false)}
                        </div>
                    </div>
                    <Margin marginTop="8px" />
                    <ActionButton
                        text={t("account.confirm_modify")}
                        type="confirm"
                        onClick={e => {
                            toggleModifyName(e, profile);
                        }}
                    />
                    <Margin marginTop="8px" />
                    <ActionButton
                        text={t("account.delete_profile")}
                        type="delete"
                        onClick={e => {
                            onDeleteClick(e, profile);
                            setModifyNameStatus(false);
                            setCancelable(false);
                        }}
                    />
                </div>
            ) : (
                getBoxesProfileData(
                    profile,
                    name,
                    dataBoxClassName,
                    isCurrentSelected,
                    year,
                    t,
                    onClick,
                    characterLimits,
                    canModifyName || seePen,
                    toggleModifyName,
                    modifyNameStatus,
                    onProfileNameChange,
                    newName,
                    goToEditProfilePage,
                    seeName,
                    canSeeAvatar,
                )
            )
        ) : null
    ) : profile ? (
        <>
            <div
                className={`profile-avatar-container profile-container-simplify clickable ${
                    isCurrentSelected ? "selected" : ""
                }`}
                onClick={onClick}
            >
                {/*
                        getIconByYear(year, true, false)
                    */}
                {profile.avatar ? (
                    <img className="col-4 pr-0 pl-0 avatar" src={getAvatarImage(profile.avatar)} alt="Avatar" />
                ) : (
                    <div className="col-4 pr-0 pl-0">{getIconByYear(year, true, false)}</div>
                )}
                <span className="col-6 pr-0 pl-0 profile-name">{name}</span>
            </div>
            {getIfToggleSwitchModeIsEnabled() ? (
                <div
                    className="child-mode-container clickable"
                    onClick={e => {
                        onChildClick(e, profile);
                    }}
                >{`${t("header.links.toggle_mode")} ${name}`}</div>
            ) : null}
        </>
    ) : null;

    return htmlToReturn;
};

export default Profile;
/*
                !profile.avatar || profile.avatar === "" ? (
                    <div
                        className={`profile-data-container profile-container-simplify clickable ${
                            isCurrentSelected ? "selected" : ""
                        }`}
                        onClick={onClick}
                    >
                        {getIconByYear(year, true, false)}
                        {!modifyNameStatus ? (
                            <span className="profile-name">{name}</span>
                        ) : (
                            <Input
                                id={"profile-input-text-" + profile.docId}
                                type="text"
                                placeholder=""
                                value={newName}
                                onChange={e => onProfileNameChange(e, e?.target?.value)}
                            />
                        )}
                        {isCurrentSelected ? <Point /> : null}
                    </div>
                    */
