import { Component } from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";

/**Utils */
import { getDietHtml } from "../../helpers/utils";

/**SCSS */
import "./_menuslider.scss";

const selector = ".owl-carousel.menu-general";
const activeSelector = selector + " .owl-item.active .item";

/**
 *
 * @param {array | null} diets - array of diets
 * @param {array | null} selectedDiet - selected diet
 * @returns {number} the position of the item in the array
 */
const getSelectedDietPerCarousel = (diets = [], selectedDiet = null) => {
    const index = diets.findIndex(itm => {
        return itm.diet_id === selectedDiet.diet_id;
    });

    return index !== -1 ? index : 0;
};
class MenuGeneralSlider extends Component {
    constructor(props) {
        super(props);

        const self = this,
            diets = props && props.diets ? props.diets : props.selectedDiet ? [props.selectedDiet] : [];

        this.state = {
            diets,
            selectedDiet: props && props.selectedDiet ? props.selectedDiet : {},
            optionsMenuGeneralSlider: {
                loop: diets && Array.isArray(diets) && diets.length > 1,
                items: 1,
                margin: 0,
                center: true,
                responsiveClass: true,
                autoplay: false,
                dots: false,
                nav: true,
                callbacks: true,
                navText: ["<i class='icon-small-arrow-left' />", "<i class='icon-small-arrow-right' />"],
                startPosition: getSelectedDietPerCarousel(diets, props.selectedDiet),
                onTranslated: function (event) {
                    if ($(activeSelector)) {
                        if ($(activeSelector).attr("data")) {
                            const selected = JSON.parse($(activeSelector).attr("data"));
                            if (selected) {
                                self.props.onDietSelected(event, selected);
                            }
                        }
                    }
                },
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        let newState = {};
        if (nextProps && nextProps.diets && nextProps.selectedDiet) {
            const diets =
                    nextProps.diets && nextProps.diets.length > 0
                        ? nextProps.diets
                        : nextProps.selectedDiet
                        ? [nextProps.selectedDiet]
                        : [],
                index = getSelectedDietPerCarousel(diets, nextProps.selectedDiet);

            newState = {
                diets,
                selectedDiet: nextProps.selectedDiet,
                optionsMenuGeneralSlider: {
                    ...this.state.optionsMenuGeneralSlider,
                    loop: diets && Array.isArray(diets) && diets.length > 1,
                    startPosition: index,
                },
            };
        } else {
            newState = {
                diets: [],
                selectedDiet: {},
                optionsMenuGeneralSlider: {
                    ...this.state.optionsMenuGeneralSlider,
                    loop: false,
                },
            };
        }

        this.setState(newState, e => {
            this.refresh();
        });
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    render() {
        const { t, isDesktop, useLittleVersion = false } = this.props,
            { diets, optionsMenuGeneralSlider } = this.state,
            className = `box-area owl-carousel menu-general${useLittleVersion ? " little" : ""}`;

        return (
            <>
                {diets && Array.isArray(diets) && diets.length > 0 && diets[0].diet_id !== -1 ? (
                    <OwlCarousel id="menu-general-id" className={className} {...optionsMenuGeneralSlider}>
                        {diets.map(diet => {
                            return getDietHtml(diet, t, useLittleVersion);
                        })}
                    </OwlCarousel>
                ) : (
                    <div className={`${className} no-data-container`}>{t("menu.no_menu_data")}</div>
                )}
            </>
        );
    }
}

export default MenuGeneralSlider;
