/**
 *
 * @param {*} key - key of the value
 * @param {*} value - value to save
 * The method save the value with identifier key in the sessionStorage
 */
export const addToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};
/**
 *
 * @param {*} key - key of the value
 * The method return the value with identifier key from the sessionStorage
 */
export const getFromSessionStorage = key => {
    return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
};
/**
 *
 * @param {*} key - key of the value
 * The method remove the value with identifier key from the sessionStorage
 */
export const removeFromSessionStorage = key => {
    localStorage.removeItem(key);
};
