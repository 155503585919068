import { MENU_RECEIVED, MENU_RESET } from "./../actions/menuActions";

const initialState = {};

export const menuReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case MENU_RECEIVED: {
            const newState = { ...state };

            newState[payload.date] = payload.menu;

            return newState;
        }
        case MENU_RESET:
            return state;
        default:
            return state;
    }
};
