class Result {
    static default(message = "", data = {}) {
        return {
            success: false,
            message,
            data,
        };
    }
    static success(message = "", data = {}) {
        return {
            success: true,
            message,
            data,
        };
    }
    static fail(message = "", data = {}) {
        return {
            success: false,
            message,
            data,
        };
    }
}

export default Result;
