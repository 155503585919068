/**
 * The method scroll the page to the top
 */
export const scrollTop = () => {
    window.scrollTo(0, 0);

    /*
    window.scroll({
        top: 0,
        behavior: "smooth",
    });
    */
};
/**
 * The method scroll the page to the top with behavior
 */
export const scrollTopWithBehaviour = (behavior = "smooth") => {
    window.scroll({
        top: 0,
        behavior,
    });
};
