/**Packages */
import { Component, createRef } from "react";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";

/**Helpers */
import { YEARS_UNION_ENUM } from "../../helpers/constants";
import { getYearHtml } from "../../helpers/utils";

/**SCSS */
import "./_infantilslider.scss";

const selector = ".owl-carousel.infanital-ciclo";
const activeSelector = selector + " .owl-item.active .item";

/**
 *
 * @param {array | null} years - array of years
 * @param {array | null} selectedYear - selected year
 * @returns {number} the position of the item in the array
 */
const getSelectedYearPerCarousel = (years = [], selectedYear = null) => {
    const index = years.findIndex(itm => {
        return itm === selectedYear || (YEARS_UNION_ENUM[itm] && YEARS_UNION_ENUM[itm].includes(selectedYear));
    });

    return index !== -1 ? index : 0;
};

class InfanitalCicloSlider extends Component {
    constructor(props) {
        super(props);
        const self = this;
        const years = props && props.years ? props.years : props.selectedYear ? props.selectedYear : [];
        //const years = props && props.years ? props.years : props.selectedYear ? [] : [];

        this.state = {
            years,
            selectedYear: props && props.selectedYear ? props.selectedYear : {},
            optionsInfanitalSlider: {
                loop: years && Array.isArray(years) && years.length > 1,
                items: 1,
                margin: 0,
                center: true,
                responsiveClass: true,
                autoplay: false,
                dots: false,
                nav: true,
                callbacks: true,
                startPosition: getSelectedYearPerCarousel(years, props.selectedYear),
                navText: ["<i class='icon-small-arrow-left' />", "<i class='icon-small-arrow-right' />"],
                onTranslated: function (event) {
                    if ($(activeSelector)) {
                        if ($(activeSelector).attr("data")) {
                            const selected = JSON.parse($(activeSelector).attr("data"));
                            if (selected) {
                                self.props.onYearSelected(event, selected);
                            }
                        }
                    }
                },
            },
        };
        this.owlCarousel = createRef();
    }

    componentWillReceiveProps(nextProps) {
        let newState = {};
        if (nextProps && nextProps.years && nextProps.selectedYear) {
            const years =
                    nextProps && nextProps.years
                        ? nextProps.years
                        : nextProps.selectedYear
                        ? [nextProps.selectedYear]
                        : [],
                index = getSelectedYearPerCarousel(nextProps.years, nextProps.selectedYear);

            newState = {
                years,
                selectedYear: nextProps.selectedYear,
                optionsInfanitalSlider: {
                    ...this.state.optionsInfanitalSlider,
                    loop: years && Array.isArray(years) && years.length > 1,
                    startPosition: index,
                },
            };
        } else {
            newState = {
                years: [],
                selectedYear: {},
                optionsInfanitalSlider: {
                    ...this.state.optionsInfanitalSlider,
                    loop: false,
                },
            };
        }

        this.setState(newState, e => {
            this.refresh();
        });
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    render() {
        const { t, isDesktop, useLittleVersion = false } = this.props,
            { years, optionsInfanitalSlider } = this.state,
            className = `box-area owl-carousel infanital-ciclo${useLittleVersion ? " little" : ""}`;

        return (
            <>
                {years && Array.isArray(years) && years.length > 0 ? (
                    <OwlCarousel
                        ref={this.owlCarousel}
                        id="infanital-slider-id"
                        className={className}
                        {...optionsInfanitalSlider}
                    >
                        {years.map(year => {
                            return getYearHtml(year, null, t, useLittleVersion);
                        })}
                    </OwlCarousel>
                ) : (
                    <div className={`${className} no-data-container`}>{t("menu.no_menu_data")}</div>
                )}
            </>
        );
    }
}

export default InfanitalCicloSlider;
