/**Packages */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/**Components */
import Modal from "../Modal";
import MenuComponent from "../Menu";
import { Spacer } from "../Spacer";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Helpers */
import { getCurrentLanguage } from "../../helpers/utils";
import { getTranslatedString } from "../../helpers/translation";

/**SCSS */
import "./_rate_recipe_modal.scss";

/**Images */
import voteSuper from "../../images/ratings/vota-super.svg";
import voteBad from "../../images/ratings/vota-rosso.svg";

const getInitialRecipeRatingsObject = (recipes = [], useDefaultVote = false) => {
    if (!recipes || !Array.isArray(recipes) || recipes.length === 0) return new Array();

    return recipes.map((recipe, idx) => {
        return {
            id: idx,
            recipeId: recipe.id,
            occasion_id: recipe.occasion_id,
            occasion_name: recipe.occasion_name,
            diet_id: recipe.diet_id,
            diet_name: recipe.diet_name,
            course_id: recipe.course_id,
            course_name: recipe.course_name,
            name: recipe.name,
            isOccasionSingle: false,
            vote: useDefaultVote ? -1 : recipe.vote || -1,
        };
    });
};

export const RateRecipesModal = ({
    visible = false,
    recipes = [],
    close = () => {},
    onSuccess = () => {},
    onError = () => {},
    useFinalPageStep = false,
    useClose = false,
    onClick = async () => {},
}) => {
    const [t, i18n] = useTranslation();

    const [step, setStep] = useState(0);
    const [recipesRating, setRecipesRating] = useState([]);
    const [reset, setReset] = useState(false);

    const dispatch = useDispatch();
    const activateLoader = () => dispatch({ type: LOADER_VISIBLE });
    const deactivateLoader = () => dispatch({ type: LOADER_INVISIBLE });

    useEffect(() => {
        setRecipesRating(getInitialRecipeRatingsObject(recipes, true));
    }, [visible]);

    useEffect(() => {
        if ([1, 2].includes(step)) {
            setTimeout(() => {
                close();
                setStep(0);
            }, 2000);
        }
    }, [step]);

    const getRecipesFormatted = (recipesMethodRef = [], resetMethodVal = false, internalOnRecipeRating = () => {}) => {
        if (!recipes || !Array.isArray(recipes) || recipes.length <= 0) return;

        return (
            <MenuComponent
                idForMasterContainer="rate-menu"
                recipes={recipesMethodRef}
                onRecipeClick={() => {}}
                favoriteRecipeEnabled={false}
                rateRecipeEnabled={true}
                resetRateComponent={resetMethodVal}
                onlyOneColumn={false}
                onRateRecipeClicked={(e, recipeId, vote) => {
                    internalOnRecipeRating(e, recipeId, vote.value);
                }}
            />
        );
    };

    const getOccasionName = () => {
        return recipes && recipes.length > 0 ? recipes[0].occasion_name : {};
    };

    const internalOnRecipeRating = (e, recipeId, newVote) => {
        if (!recipesRating || !Array.isArray(recipesRating) || recipesRating.length <= 0) return;

        const cpRecipesRating = [...recipesRating],
            indexToUse = cpRecipesRating.findIndex((itm, idx) => {
                return idx === recipeId;
            });

        if (indexToUse >= 0 && cpRecipesRating[indexToUse]) {
            cpRecipesRating[indexToUse].vote = newVote;
        }

        setRecipesRating([...cpRecipesRating]);
    };

    const onClose = e => {
        e.preventDefault();

        close();
    };

    const onSave = async e => {
        e.preventDefault();
        activateLoader();

        const result = await onClick(e, recipesRating),
            finalMethodToUse = result && result.success ? onSuccess : onError,
            stepToUse = result && result.success ? 1 : 2;

        setReset(true);

        setTimeout(() => {
            if (useFinalPageStep) {
                setReset(false);
                deactivateLoader();
                setStep(stepToUse);
            } else {
                setReset(false);
                deactivateLoader();
                if (useClose) {
                    close();
                }
                finalMethodToUse();
            }
        }, 1000);
    };

    const getLayoutFromStep = resetMethodVal => {
        switch (step) {
            case 1: {
                return (
                    <>
                        <h2>{t("rate_recipe.modal.thanks")}!</h2>
                        <img className="rate-recipe-thanks-img" src={voteSuper} alt="Super" />
                    </>
                );
            }
            case 2: {
                return (
                    <>
                        <h2>{t("rate_recipe.modal.error")}!</h2>
                        <img className="rate-recipe-thanks-img" src={voteBad} alt="Bad" />
                    </>
                );
            }
            case 0:
            default: {
                return (
                    <>
                        <h2>{t("rate_recipe.modal.title")}</h2>
                        <p className="occasion">{getTranslatedString(getOccasionName(), getCurrentLanguage())}</p>
                        <Spacer marginTop="8px" />
                        {getRecipesFormatted(recipesRating, resetMethodVal, internalOnRecipeRating)}
                        <div className="rate-recipe-buttons-container">
                            <button className="rate-recipe-modal-button invert" onClick={onClose}>
                                {t("rate_recipe.modal.close")}
                            </button>
                            <Spacer marginLeft="8px" marginRight="8px" />
                            <button className="rate-recipe-modal-button" onClick={onSave}>
                                {t("rate_recipe.modal.send")}
                            </button>
                        </div>
                    </>
                );
            }
        }
    };

    return (
        <Modal id="rate-recipe-modal" key="rate-recipe-modal" visible={visible} close={close} fullScreen={true}>
            <div className="rate-recipe-modal-container">{getLayoutFromStep(reset)}</div>
        </Modal>
    );
};
