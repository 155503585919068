/**Packages */
import { isEmpty } from "lodash";
import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
/**Helpers */
import { seo } from "../../helpers/seo";
import { SITE_TITLE, ALL_FILTERS, INDEX_URL, PAGES } from "../../helpers/constants";
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";
import { getNewsHtml_News, getActivitiesFiltered, checkString, getCurrentLanguage } from "../../helpers/utils";
import history from "../../helpers/history";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Components */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TagsSlider from "../../components/TagsSlider";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_news.scss";

const checkTag = (tagNews, tagToCheck) => {
    return tagNews.find(tg => {
        return tagToCheck.includes(tg);
    });
};

const filterMessagesByTags = (messages, tags = []) => {
    if (!tags || tags.length === 0 || (tags.length === 1 && tags[0] === ALL_FILTERS)) {
        return messages;
    }

    const filteredMessages = [];

    for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];
        const ids = filteredMessages.map(itm => {
            return itm.id;
        });
        filteredMessages.push(
            ...messages.filter(itm => {
                return (
                    checkTag(
                        itm.tags.map(tt => {
                            return tt.id;
                        }),
                        tag.tags,
                    ) && !ids.includes(itm.id)
                );
            }),
        );
    }
    return filteredMessages;
};

const filterMessagesByText = (news, text = null) => {
    if (!checkString(text)) return news;

    const textLower = text.toLowerCase();

    return news.filter(item => {
        const abstract = item.translations.abstract[getCurrentLanguage()].toLowerCase();
        const subject = item.translations.subject[getCurrentLanguage()].toLowerCase();
        const text = item.translations.text[getCurrentLanguage()].toLowerCase();
        return (
            abstract.indexOf(textLower) !== -1 || subject.indexOf(textLower) !== -1 || text.indexOf(textLower) !== -1
        );
    });
};

const getNews = (news, t, self) => {
    return news && news.length > 0 ? (
        news.map(itm => {
            return getNewsHtml_News(itm, self.onNewsClick);
        })
    ) : (
        <div className="no-data-container">{t("news.no_data_activities")}</div>
    );
};

const getNewsByMode = props => {
    const idxToUse = checkIfChildrenModeIsActivaded() ? 2 : 1;
    return props?.news && props?.news?.news && props?.news?.news[idxToUse]
        ? getActivitiesFiltered(props?.news?.news, idxToUse)
        : [];
};
const getFiltriByMode = props => {
    const idxToUse = checkIfChildrenModeIsActivaded() ? 2 : 1;
    return props?.news?.filters[idxToUse]?.filtri;
};
class News extends Component {
    constructor(props) {
        super(props);

        const news = getNewsByMode(props);
        this.state = {
            searchText: "",
            tags: getFiltriByMode(props),
            activeTags: [ALL_FILTERS],
            news,
            allNews: [...news],
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.onTagClick = this.onTagClick.bind(this);
        this.onNewsClick = this.onNewsClick.bind(this);
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || News",
            metaDescription: "",
        });
    }

    /**TODO: refactor */
    onTagClick(e, tagClicked) {
        if (!tagClicked || isEmpty(tagClicked)) return;

        const newState = {},
            newsFilteredByText = filterMessagesByText(this.state.allNews, this.state.searchText),
            index = this.state.activeTags.indexOf(tagClicked);
        if (index === -1) {
            /**Case new tag */
            if (tagClicked === ALL_FILTERS) {
                newState.activeTags = [ALL_FILTERS];
                newState.news = [...newsFilteredByText];
            } else {
                const tags = [...this.state.activeTags, tagClicked];
                const indexALL = tags.indexOf(ALL_FILTERS);
                if (indexALL !== -1) {
                    tags.splice(indexALL, 1);
                }
                newState.activeTags = tags;
                newState.news = filterMessagesByTags(newsFilteredByText, tags);
            }
        } else {
            /**Case tag already selected*/
            if (tagClicked === ALL_FILTERS) {
                newState.activeTags = [ALL_FILTERS];
                newState.news = [...newsFilteredByText];
            } else {
                const activeTags = this.state.activeTags;
                activeTags.splice(index, 1);
                if (activeTags.length === 0) {
                    newState.activeTags = [ALL_FILTERS];
                    newState.news = [...newsFilteredByText];
                } else {
                    const indexALL = activeTags.indexOf(ALL_FILTERS);
                    if (indexALL !== -1) {
                        activeTags.splice(indexALL, 1);
                    }
                    newState.activeTags = activeTags;
                    newState.news = filterMessagesByTags(newsFilteredByText, activeTags);
                }
            }
        }

        this.setState(newState);
    }

    onNewsClick(e, activityClicked) {
        if (!activityClicked || activityClicked === {}) return;

        history.pushWithCheck(INDEX_URL + PAGES.ACTIVITY, { activity: activityClicked });
    }

    handleSearch(e, clickedButton = false) {
        if (!clickedButton) {
            let newState = {};
            const newText = e?.target?.value;
            const newsFilteredByTag = filterMessagesByTags(this.state.allNews, this.state.activeTags);
            if (checkString(newText)) {
                newState = {
                    searchText: newText,
                    news: filterMessagesByText(newsFilteredByTag, newText),
                };
            } else {
                newState = {
                    searchText: newText,
                    news: [...this.state.allNews],
                };
            }

            this.setState(newState);
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    render() {
        const self = this;
        const { tags, activeTags, news } = this.state;

        return (
            <>
                <Header />
                <section className="middle-content news-area">
                    <div className="container-fluid">
                        <div className="default-heading">
                            <h2>{this.props.t("news.title")}</h2>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                <TagsSlider tags={tags} activeTags={activeTags} onClick={this.onTagClick} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                <form className="search-area">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search-activities-form"
                                            value={this.state.searchText}
                                            placeholder={this.props.t("news.search")}
                                            onKeyPress={e => this.handleSearch(e, e.key === "Enter")}
                                            onChange={e => this.handleSearch(e, false)}
                                        />
                                        <button
                                            type="button"
                                            className="search-btn"
                                            onClick={e => this.handleSearch(e, true)}
                                        >
                                            <span className="icon-search">
                                                <span className="path1" />
                                                <span className="path2" />
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div
                                    className="row justify-content-center grid js-masonry"
                                    data-masonry-options='{ "itemSelector": ".grid-item" }'
                                >
                                    {getNews(news, this.props.t, self)}
                                    <div
                                        id="news-send-message-box"
                                        className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 grid-item clickable"
                                        onClick={() => {
                                            history.pushWithCheck("/" + PAGES.MAIL_BOX);
                                        }}
                                    >
                                        <div className="circling-area dark flex-column">
                                            <p>{this.props.t("news.link_send_message")}</p>
                                            <span>{this.props.t("news.send_message")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

News.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(News));
