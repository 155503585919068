/**Packages */
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

/**Helpers */
import { getCurrentLanguage } from "../helpers/utils";

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: window.location.origin + "/locales/{{lng}}/{{ns}}.json",
        },
        lng: localStorage.getItem("language") || process.env.REACT_APP_DEFAULT_LNG,
        fallbackLng: "en",
        supportedLngs: ["es", "en", "ca", "eu", "it", "fr", "tr"],
        debug: true,

        defaultNS: "translation",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
