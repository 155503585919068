/**Packages */
import axios from "axios";

/**Helpers */
import { default as config } from "./../firebase/config";

const addProfile = async (
    token,
    cid,
    diet,
    favorite = false,
    isNamingPermitted = false,
    profileName = null,
    isAvatarEnabled = false,
    avatarImg = null,
) => {
    const formattedCid = cid.indexOf("C_") === -1 ? "C_" + cid : cid;
    const data = await axios
        .post(
            config.API_URL + "/api/createUserProfile",
            {
                token,
                cid: formattedCid,
                diet,
                favorite,
                isNamingPermitted,
                profileName,
                isAvatarEnabled,
                avatarImg,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const setUserCanteenData = async (token, userCanteenData) => {
    const data = await axios
        .post(
            config.API_URL + "/api/setUserCanteenData",
            {
                token,
                data: userCanteenData,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const getProfiles = async token => {
    const data = await axios
        .post(
            config.API_URL + "/api/getUserProfiles",
            {
                token,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const getCanteensCodeWithName = async (token = null, cids = []) => {
    const data = await axios
        .post(
            config.API_URL + "/api/getCanteensCodeWithName",
            {
                token,
                cids,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const deleteProfile = async (token, docId) => {
    const data = await axios
        .delete(config.API_URL + "/api/deleteUserProfile", {
            method: "DELETE",
            data: {
                token,
                docId,
            },
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const updateUserProfileFavorite = async (token, newUserProfileDocId) => {
    const data = await axios
        .post(
            config.API_URL + "/api/updateUserProfileFavorite",
            {
                token,
                docID: newUserProfileDocId,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const manageUserProfileRecipesFavorite = async (token, userProfileID, recipe) => {
    const data = await axios
        .post(
            config.API_URL + "/api/manageUserProfileRecipesFavorite",
            {
                token,
                docID: userProfileID,
                recipe,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

const deleteUser = async token => {
    const data = await axios
        .delete(config.API_URL + "/api/deleteUser", {
            method: "DELETE",
            data: {
                token,
            },
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

export const profileAPIs = {
    addProfile,
    getProfiles,
    getCanteensCodeWithName,
    updateUserProfileFavorite,
    manageUserProfileRecipesFavorite,
    deleteProfile,
    deleteUser,
    setUserCanteenData,
};
