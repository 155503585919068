/**SCSS */
import "./_go_back_plus_title.scss";

const GoBackPlusTitle = ({ onClick = () => {}, goBackText = "", title = null, useH3 = false }) => {
    return (
        <div className="go-back-plus-title-head-area">
            <section className="middle-content inner-area col-12 go-back-plus-title-head">
                <button onClick={onClick} className="back-btn-top">
                    <i className="icon-small-arrow-left icon-left"></i>
                    <span>{goBackText}</span>
                </button>
            </section>
            {title && title !== "" && (
                <div className="col-10 offset-1 col-md-8 offset-md-2 text-center go-back-plus-title-title-container">
                    <div className="default-heading text-uppercase">
                        {!useH3 ? (
                            <h2>
                                <strong>{title}</strong>
                            </h2>
                        ) : (
                            <h3>
                                <strong>{title}</strong>
                            </h3>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GoBackPlusTitle;
