/**SCSS */
import "./go_back_link.scss";

const GoBackLink = ({ text = "", onClick = () => {} }) => {
    return (
        <div className="go-back-link-container clickable">
            <button onClick={e => onClick(e)} className="button">
                <i className="icon-small-arrow-left icon-left" />
                <span>{text}</span>
            </button>
        </div>
    );
};

export default GoBackLink;
