/**Helpers */
import { getIfUseImageDownload } from "../../helpers/enviroment";

/**Images */
import downloadSvg from "../../images/download_icon.svg";

const DownloadButton = props => {
    const { icon, text, date, backgroundColor, onClick, year, month, isEnabled } = props;

    const style = backgroundColor ? { backgroundColor: backgroundColor } : null;

    return isEnabled ? (
        <div className="box-area download-area clickable" onClick={e => onClick(e, date)} style={style}>
            <div className="dotted-border">
                {getIfUseImageDownload() ? <img src={downloadSvg} alt="Download" /> : <span className={icon} />}
                <div className="year">{text}</div>
            </div>
        </div>
    ) : null;
};

export default DownloadButton;
