/**
 *
 * Util to manipulate dataLayer
 *
 * Google Analitycs
 */

/**Packages */
import moment from "moment";

/**Helpers */
import { MENU_DATE_FORMAT } from "./constants";
import { getCurrentCidFromCanteen } from "./state";
import { getApplicationName, getIfGTMGAEnabled } from "./enviroment";
import { getViewKeyForPageViewEvent } from "./view";

const isDataLayerEnabled = () => {
    return getIfGTMGAEnabled() && window && Object.prototype.hasOwnProperty.call(window, "dataLayer");
};

const sendCustomEvent = (eventName = null, data = null, cid = getCurrentCidFromCanteen()) => {
    if (!eventName || !data) return;

    if (isDataLayerEnabled()) {
        window.dataLayer.push({
            event: eventName,
            ...data,
            //canteenId: cid,
        });
    }
};

export const sendCustomPageViewEvent = (fullPath = null) => {
    if (!fullPath || fullPath.indexOf("null") !== -1) return;

    const title = getApplicationName();

    sendCustomEvent("page_view", {
        title,
        location: fullPath,
    });
};

export const changeCalendarDate = (
    today = moment().format(MENU_DATE_FORMAT),
    selectedDate,
    cid = getCurrentCidFromCanteen(),
) => {
    sendCustomEvent("changeDate", {
        today,
        selectedDate,
    });
};

export const downloadMenuData = (
    selectedMonth,
    selectedYear,
    selectedData,
    language,
    canteenId,
    cid = getCurrentCidFromCanteen(),
) => {
    if (!selectedMonth || !selectedYear || !selectedData || !canteenId) return;

    sendCustomEvent("downloadPdf", {
        today: moment().format(MENU_DATE_FORMAT),
        selectedMonth,
        selectedYear,
        selectedData,
        language,
        canteenId,
    });
};

export const seeRecipeDetailData = (selectedRecipe, selectedDate, cid = getCurrentCidFromCanteen()) => {
    if (!selectedRecipe || !selectedDate) return;

    sendCustomEvent("seeRecipeDetailData", {
        today: moment().format(MENU_DATE_FORMAT),
        selectedDate,
        selectedRecipe: {
            id: selectedRecipe.id,
            name: selectedRecipe.name,
        },
    });
};

const getFullPath = (origin = window.location.origin, canteenId, pathname, mode) => {
    if (pathname.indexOf("login") !== -1 || pathname.indexOf("registration") !== -1) {
        return `${window.location.origin}${pathname}`;
    }

    return `${origin}/${canteenId}${pathname}/${mode}`;
};

export const historyPageViewListener = (location, action) => {
    const { pathname } = location;
    /*
        canteenId = getCurrentCidFromCanteen() || null,
        mode = getViewKeyForPageViewEvent();
    */

    sendCustomPageViewEvent(
        getFullPath(window.location.origin, getCurrentCidFromCanteen() || null, pathname, getViewKeyForPageViewEvent()),
    );
};
