import { PAGES } from "../../helpers/constants";
import { SET_PAGE_STATE, DELETE_PAGE_STATE } from "./../actions/pageStateActions";

const initialState = {
    [PAGES.HOME + "home"]: {},
};

export const pageStateReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case SET_PAGE_STATE:
            return {
                ...state,
                [payload.page]:
                    payload.state && typeof payload.state === "string" ? payload.state : JSON.stringify(payload.state),
            };
        case DELETE_PAGE_STATE:
            return {
                ...state,
                [payload.page]: {},
            };
        default:
            return state;
    }
};
