/**Packages */
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

/**Components */
import Back from "../../components/Back";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GoBackLink from "../../components/GoBackLink";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";
import { DownloadMediaButton } from "../../components/DownloadMediaButton";

/**Utils */
import { seo } from "../../helpers/seo";
import { scrollTop } from "../../helpers/scroll";
import { SITE_TITLE } from "../../helpers/constants";
import { getTranslatedString } from "../../helpers/translation";
import { checkIfStringIsHtml, getCurrentLanguage, getIfMediaIsDoc, mediaButtonOnClick } from "../../helpers/utils";

/**SCSS */
import "./_activity.scss";

const getTextBack = (fromHome = false, from = null, t) => {
    const key = fromHome ? "activity.back.home" : from ? `activity.back.${from}` : "activity.back.default";

    return t(key);
};

class Activity extends Back {
    constructor(props) {
        super(props);

        this.state = {
            activity: props?.location?.state?.activity,
            from: props?.location?.state?.from ? props?.location?.state?.from : null,
            fromHome: props?.location?.state?.fromHome ? props?.location?.state?.fromHome : false,
        };
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Activity",
            metaDescription: "",
        });

        scrollTop();

        if (!this.state.activity) {
            this.goBack();
        }
    }

    render() {
        const { t } = this.props,
            { activity, fromHome, from } = this.state,
            { translations, imageurl, file_list: fileList } = activity,
            { subject, text } = translations,
            textBack = getTextBack(fromHome, from, this.props.t);

        return (
            <React.Fragment>
                <Header />
                <section id="single-activity" className="middle-content inner-area">
                    <GoBackPlusTitle onClick={this.goBack} goBackText={textBack} />
                    <div className="container maxwidth activity">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="column-details">
                                    <div className="default-heading">
                                        <h3>{subject[getCurrentLanguage()]}</h3>
                                    </div>
                                    <div className="info-container">
                                        {imageurl && !getIfMediaIsDoc(imageurl) && (
                                            <img className="info__img" src={imageurl} alt="Image" />
                                        )}
                                        {checkIfStringIsHtml(text[getCurrentLanguage()]) ? (
                                            <p
                                                className="external-paragraph"
                                                dangerouslySetInnerHTML={{ __html: text[getCurrentLanguage()] }}
                                            />
                                        ) : (
                                            <p>{text[getCurrentLanguage()]}</p>
                                        )}
                                        {imageurl && getIfMediaIsDoc(imageurl) && (
                                            <div
                                                className="advice-dinner-media-container activity clickable"
                                                onClick={e => mediaButtonOnClick(e, imageurl)}
                                            >
                                                <div className="text">{t("activity.media_button_text")}</div>
                                            </div>
                                        )}
                                        {fileList &&
                                            Array.isArray(fileList) &&
                                            fileList.length > 0 &&
                                            fileList.map((singleFile, idx) => {
                                                const { buttonText, fileUrl } = singleFile;

                                                return (
                                                    <DownloadMediaButton
                                                        key={`act-med-btn-${idx}`}
                                                        url={fileUrl}
                                                        text={getTranslatedString(buttonText)}
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoBackLink text={textBack} onClick={this.goBack} />
                    </div>
                    {/**
                            <div className="recently-area">
                                <ActivitiesSlider />
                            </div>
					    */}
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default withTranslation()(Activity);
