import { Fragment, createRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { USER_LOGGED_IN } from "../../redux/actions/userActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**Utils */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import { validateEmail, validateEquality } from "../../helpers/validators";
import { success, error } from "../../helpers/toast";
import { seo } from "../../helpers/seo";
import history from "../../helpers/history";

/**Componets */
import OnChange from "../../components/OnChange";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/**Images */
import rightGraphic from "../../images/right-graphic.svg";
import leftGraphic from "../../images/left-graphic.svg";

class ResetPassword extends OnChange {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            confirmEmail: "",
        };

        this.formValidation = this.formValidation.bind(this);
        this.sendEmail = this.sendEmail.bind(this);

        this.inputEmailRef = createRef();
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Reset Password",
            metaDescription: "",
        });
    }

    formValidation() {
        const { email } = this.state;
        const validClassName = "form-control";
        const errorClassName = validClassName + " error";

        if (!validateEmail(email)) {
            this.inputEmailRef.current.className = errorClassName;
            this.inputEmailRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("login.email") }));
            return false;
        } else {
            this.inputEmailRef.current.className = validClassName;
        }

        return true;
    }

    async sendEmail(e) {
        e.preventDefault();
        try {
            this.props.activateLoader();
            const { email, confirmEmail } = this.state;
            if (this.formValidation()) {
                const result = await this.context.api.resetPassword(email);
                if (result) {
                    success(this.props.t("reset_password.success"));
                    history.pushWithCheck(INDEX_URL + PAGES.LOGIN);
                } else {
                    error(this.props.t("reset_password.error_email"));
                }
            } else {
                //error(this.props.t("reset_password.error_parameters"));
            }
        } catch (e) {
            console.log("e");
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    renderResetPasswordForm() {
        return (
            <Fragment>
                <form>
                    {/*Email*/}
                    <Input
                        innerRef={this.inputEmailRef}
                        type="email"
                        className="form-control"
                        id="reset_password_input_email"
                        placeholder={this.props.t("reset_password.email")}
                        autocomplete="off"
                        value={this.state.email}
                        onChange={e => this.onChange(e, "email")}
                        onBlur={this.onBlur}
                    />
                    {/*
						Conferma Email
						<Input 
							type="email" 
							className="form-control" 
							id="reset_password_input_confirm_email" 
							placeholder={this.props.t('reset_password.confirm_email')}
							value={this.state.confirmEmail}
							onChange={(e) => this.onChange(e, 'confirmEmail') } 	
						/>
					*/}
                    <div className="form-group">
                        <div className="btns-area">
                            <button type="submit" className="btn btn-primary" onClick={this.sendEmail}>
                                {this.props.t("reset_password.confirm")}
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                <Header />
                <div className="graphic left-graphic">
                    <img src={leftGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <div className="graphic right-graphic">
                    <img src={rightGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <section className="middle-content">
                    <div className="container form-area maxwidth">
                        <div className="head-area">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="default-heading">
                                        <h3>{this.props.t("reset_password.title")}</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="register-here">
                                        <Link to={INDEX_URL + PAGES.SIGNUP}>
                                            {this.props.t("reset_password.register")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12">{this.renderResetPasswordForm()}</div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        loggedIn: user => dispatch({ type: USER_LOGGED_IN, payload: user }),
    };
};

ResetPassword.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));
