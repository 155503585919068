/**Packages */
import axios from "axios";
import { default as config } from "./../firebase/config";

const getCurrentBrowser = () => {
    let browser = "sconosciuto";

    const userAgent = navigator.userAgent;

    if ((userAgent.indexOf("Opera") || userAgent.indexOf("OPR")) !== -1) {
        browser = "opera";
    } else if (userAgent.indexOf("Edg") !== -1) {
        browser = "edge";
    } else if (userAgent.indexOf("Chrome") !== -1) {
        browser = "chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
        browser = "safari";
    } else if (userAgent.indexOf("Firefox") !== -1) {
        browser = "firefox";
    } else if (userAgent.indexOf("MSIE") !== -1 || !!document.documentMode) {
        browser = "explorer";
    }

    return browser;
};

const register = async (token, canteenId) => {
    let isMobile = /(iPhone)|(iPod)|(android)|(Android)|(webOS)/.test(navigator.userAgent);
    if (/(iPad)/.test(navigator.userAgent) && window.innerHeight > window.innerWidth) {
        //Tablet verticale è mobile
        isMobile = true;
    }

    const data = await axios
        .post(
            config.API_URL + "/session",
            {
                canteen_id: canteenId,
                app_version: process.env.REACT_APP_WEBAPP_VERSION,
                width: window.screen.availWidth,
                height: window.screen.availHeight,
                browser: getCurrentBrowser(),
                isMobile,
            },
            {
                headers: {
                    Authorization: token,
                },
            },
        )
        .then(response => {
            return response && response.status === 200 && response.data ? response.data : null;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

export const sessionAPIs = {
    register,
};
