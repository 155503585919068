/**Packages */
import { useSelector } from "react-redux";

/**SCSS */
import "./_splashscreen.scss";

export const SplashScreen = ({ visible }) => {
    const splash = useSelector(state => state.splash);

    return visible ? (
        <div id="splashscreen" className="splashscreen-container">
            <img src={splash.value} alt="SplashScreen" />
        </div>
    ) : null;
};

export default SplashScreen;
