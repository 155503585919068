import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { USER_LOGGED_IN } from "../../redux/actions/userActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**Utils */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import history from "../../helpers/history";

/**Componets */
import OnChange from "../../components/OnChange";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/**Images */
import rightGraphic from "../../images/right-graphic.svg";
import leftGraphic from "../../images/left-graphic.svg";

class ChangePassword extends OnChange {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
        };

        this.changePassword = this.changePassword.bind(this);
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Change Password",
            metaDescription: "",
        });
    }

    async changePassword(e) {
        e.preventDefault();
        try {
            this.props.activateLoader();
            const { password, confirmPassword } = this.state;
            if (password && password.length >= 8 && confirmPassword && password === confirmPassword) {
                console.log("cambio password senza problemi");
                const result = await this.context.api.changeUserPassword(password);
                if (result) {
                    console.log("Password cambiata con successo");
                    history.pushWithCheck(INDEX_URL + PAGES.HOME);
                } else {
                    console.log("Password non cambiata, qualcosa è andato storto");
                }
            } else {
                console.log("errore qualcosa non va");
            }
        } catch (e) {
            console.log("e");
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    renderChangePasswordForm() {
        return (
            <React.Fragment>
                <form>
                    {/*Password*/}
                    <Input
                        type="password"
                        className="form-control"
                        id="change_password_input_password"
                        placeholder={this.props.t("change_password.password")}
                        autocomplete="new-password"
                        value={this.state.password}
                        onChange={e => this.onChange(e, "password")}
                    />
                    {/*Conferma Password*/}
                    <Input
                        type="password"
                        className="form-control"
                        id="change_password_input_password"
                        placeholder={this.props.t("change_password.confirm_password")}
                        autocomplete="new-password"
                        value={this.state.confirmPassword}
                        onChange={e => this.onChange(e, "confirmPassword")}
                    />
                    <div className="form-group">
                        <div className="btns-area">
                            <button type="submit" className="btn btn-primary" onClick={this.changePassword}>
                                {this.props.t("change_password.confirm")}
                            </button>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <div className="graphic left-graphic">
                    <img src={leftGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <div className="graphic right-graphic">
                    <img src={rightGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <section className="middle-content">
                    <div className="container form-area maxwidth">
                        <div className="head-area">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="default-heading">
                                        <h3>{this.props.t("change_password.title")}</h3>
                                    </div>
                                </div>

                                {/**
										<div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
											<div className="register-here">
												<Link to={INDEX_URL+'registration'}>
													{this.props.t('change_password.register')}
												</Link>
											</div>
										</div>
									 */}
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12">{this.renderChangePasswordForm()}</div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        loggedIn: user => dispatch({ type: USER_LOGGED_IN, payload: user }),
    };
};

ChangePassword.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
