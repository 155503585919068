import React from "react";
import ReactDOM from "react-dom";
//import './index.css';
//import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./css/custom.css";

import "bootstrap/dist/js/bootstrap.bundle.min";
import "./js/custom.js";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import FirebaseProvider from "./firebase/firebase";
import "./i18n/i18n";

import { Provider } from "react-redux";
import store from "./redux/store";

ReactDOM.render(
    <Provider store={store}>
        <FirebaseProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </FirebaseProvider>
    </Provider>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
