/**Helpers */
import { getMaxNumberOfNewsInHome } from "./enviroment";

/**
 * API_URL = base url
 */
export const API_URL = "";

/**
 * INDEX_URL
 */
export const INDEX_URL = "/";
/**
 * Page base title
 */
export const SITE_TITLE = process.env.REACT_APP_SEO_TITLE;
/**
 * Children css class identifier
 */
export const CHILDREN_CSS_CLASS = "children";
/**
 * User tag to rate recipes - Used to check if user has enabled to rate recipes or not
 */
export const USER_RATE_TAG = "voto";
/**
 * Splashscreen base value
 */
export const SPLASH_DEFAULT = "/logo512.png";

/**
 * All page in app
 */
export const PAGES = {
    SIGNUP: "registration",
    LOGIN: "login",
    RESET_PASSWORD: "reset-password",
    CHAT: "chat",
    MESSAGES: "messages",
    ACTIVITY: "activity",
    CHANGE_PASSWORD: "change-password",
    NEWS: "news",
    ACTIVITIES: "activities",
    CREATE_PROFILE: "create-profile",
    ACCOUNT: "account",
    MAIL_BOX: "mail-box",
    RECIPE_DETAIL: "recipe-detail",
    PRIVACY: "privacy",
    TERMS: "terms",
    FAVORITES_RECIPES: "favorite-recipes",
    EDIT_PROFILE: "edit-profile",
    CHANGE_AVATAR: "edit-profile",
    ALLERGENS: "allergens",
    HOME: "home",
    HOMEPAGE_GAMES: "homepage_games",
};

//Testing
//export const SOHAPPY_GAMES_URL = "https://preview.monforte.it/sohappy/home/home-giochi.html";
//Prod
export const SOHAPPY_GAMES_URL = "https://games.so-happy.it/";

export const SWITCH_MODE = "SWITCH MODE";

//PAGES.CHANGE_AVATAR = PAGES.EDIT_PROFILE;

export const STATIC_PAGES = {
    PRIVACY: "privacy",
    TERMS: "terms",
};

/**
 * Pages where disable overflow
 */
export const PAGES_WHERE_DISABLE_OVERFLOW = [INDEX_URL + PAGES.CHAT];
/**
 * Pages where to show the user icon
 */
export const PAGES_WHERE_SHOW_USER_ICON = [INDEX_URL + PAGES.HOME];

/**
 * FIREBASE COLLECTIONS USED
 */
export const FIREBASE_COLLECTIONS = {
    USERS: "users",
    USERS_PROFILES: "profiles",
    MENU: "menu",
    MENU_DATES: "dates",
    RECIPES: "recipes",
    CHAT: "chat",
    CANTEENS: "canteens",
    CANTEEN_RULES: "rules",

    USER_FAVORITE_RECIPES: "favorite_recipes",
};

/**
 * Key used when adding new canteen
 */
export const LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY = "newCanteenToAdd";

/**
 * Enabled languages
 */
export const DEFAULT_ENABLED_LANGUAGES = JSON.parse(process.env.REACT_APP_DEFAULT_LANGUAGES);

/**Avatar Images */
export const AVATAR_FOLDER = "../images/avatar";

/**Collaudo index url */
export const MYSODEXO_COLLAUDO_BASE_URL = "https://europe-west1-mysodexo-collaudo.cloudfunctions.net";

/**MENU */
/**
 * Date format
 */
export const MENU_DATE_FORMAT = "YYYY-MM-DD";
/**
 * Default occasion
 */
export const DEFAULT_OCCASION = {
    occasion_id: -1,
    occasion_name: {},
};
/**
 * Default diet
 */
export const DEFAULT_DIET = {
    diet_id: -1,
    diet_name: {},
};
/**
 * Default year
 */
export const DEFAULT_YEAR = null;
/**
 * Enumeration of all components used in app
 */
export const COMPONENT_ENUM = {
    PRIMERO: 0,
    PRIMO: 0,
    PRIMI: 0,
    SEGUNDO: 1,
    SECONDO: 1,
    SECONDI: 1,
    GUARNICIÓN: 2,
    CONTORNO: 2,
    CONTORNI: 2,
    POSTRE: 3,
    DESSERT: 3,
    DESSERTS: 3,
    FRUTTA: 3,
    DESAYUNO: 4,
    COLAZIONE: 4,
    MERIENDA: 5,
    MERENDA: 5,
    MERENDE: 5,
    ENSALADA: 6,
    ENSALADAS: 6,
    INSALATA: 6,
    INSALATE: 6,
    "PRODUCTOS DE PANADERÍA": 7,
    "PRODOTTO DA FORNO": 7,
    "PRODOTTI DA FORNO": 7,
    BEVANDE: 8,
    "PIATTO UNICO": 9,
    PIATTOUNICO: 9,
    PANINOTECA: 10,
    ENTRANTE: 11,
    PAN: 12,
    DEFAULT: -1,
};
/**
 * Enumeration of all years used in app
 */
export const YEARS_ENUM = {
    "(4M-1)": 0,
    NID: 0,
    NIDO: 0,
    "(1-2)": 1,
    "(1-3)": 1,
    "(3-6)": 2,
    INF: 2,
    "(7-9)": 3,
    PRI: 3,
    "(10-13)": 4,
    "(13)": 5,
    "(>13)": 5,
    SEC: 5,
    ADULTI: 5,
    SUP: 5,
};
/**
 * Enumeration of all years used in app grouped -> in order to be inserted in homepage (in boxes)
 */
export const YEARS_UNION_ENUM = {
    "(4M-1)": ["(4M-1)", "NIDO"],
    "(1-3)": ["(1-2)", "(1-3)"],
    "(3-6)": ["(3-6)", "INF"],
    "(7-9)": ["(7-9)", "(7-13)", "(10-13)", "PRI"],
    "(13)": ["(>13)", "(13)", "MED", "SEC", "SUP", "ADULTI"],
};
/**
 * Enumeration of all type of menus used in app
 */
export const MENU_TYPE_ENUM = {
    BASAL: 0,
    MENUGENERAL: 0,
    INFANTILPURES: 1,
    INFANTILSOLIDO: 2,
    SINGLUTEN: 3,
    SENZAGLUTINE: 3,
    SINLACTOSA: 4,
    SINHUEVO: 5,
    SINLEGUMBRES: 6,
    SINPROTEINADELECHEDEVACA: 7,
    "SINPESCADO/MARISCO": 8,
    HIPOCALÓRICA: 9,
    SINFRUTOSSECOS: 10,
    CARNE: 11,
    SINTERNERA: 11,
    CERDO: 12,
    SINCERDO: 12,
    FRUCTOSA: 13,
    SINFRUCTOSA: 13,
    MULTIALERGENO: 14,
    VEGETARIANO: 15,
    VEGETARIANA: 15,
    TRITURADA: 16,
    "BAJO-COLESTEROL": 17,
    BAJOCOLESTEROL: 17,
    /**TODO: check */
    MULTIALÉRGICO: 18,
    ESPECIAL: 18,
};
/**
 * Italy diet suffix
 */
export const DIET_ITALY_SUFFIX = ["inf", "sec", "pri", "sup", "adulti", "nido"];
/**
 * Italy diet name
 */
export const DIET_ITALY = ["scuole inf", "scuole pri", "scuole sec", "scuole sec / adulti", "nido"];
/**
 * Special diets
 */
export const SPECIAL_DIETS = ["basal", "especial", ...DIET_ITALY];
/**
 * Base merienda component (used in SoMeal)
 */
export const MERIENDA_COMPONET_NAME = {
    es: "Merienda",
    cl: "Merienda",
    ca: "Merienda",
    eu: "Merienda",
    it: "Merienda",
};

/**
 * Max news number in home (also for children)
 */
export const N_MAX_NEWS_HOME = getMaxNumberOfNewsInHome() || 4;
export const N_MAX_NEWS_HOME_CHILDREN = 4;

/**
 * Max activities number in home
 */
export const N_MAX_ACTIVITIES_HOME = 4;

/**NEWS */
export const HOME_TAG = "informacion";
export const ACTIVITIES_TAG = "actividad";

export const ALL_FILTERS = {
    name: "ALL",
    filtri: [
        {
            tags: ["all"],
        },
    ],
};

/**CHATS */
//export const ENABLED_CHATS = ["C_504"];
export const ENABLED_CHATS = [];
