import "./_radio.scss";

export const Radio = ({ id, name, onChange, checked, value, text = "" }) => {
    return (
        <div className="radio-button-container">
            <input
                type="radio"
                className="radio-button"
                id={id}
                name={name}
                onChange={e => onChange(e)}
                checked={checked}
                value={value}
            />
            <span>{text && text !== "" ? ` ${text}` : ""}</span>
        </div>
    );
};
