/**Helpers */
import { openUrl } from "../../helpers/utils";

/**Images */
import downloadMediaIcon from "../../images/download_media_icon.svg";

/**SCSS */
import "./download_media_button.scss";

export const DownloadMediaButton = ({ url = "", text = "" }) => {
    const onClick = e => {
        openUrl(url);
    };

    text = text || "Download file";

    return (
        <div className="download-media-button-container" onClick={onClick}>
            <img src={downloadMediaIcon} alt="Download" />
            <span className="description">{text}</span>
        </div>
    );
};
