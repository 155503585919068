/**Packages */
import axios from "axios";
import { default as config } from "./../firebase/config";

/**Helpers */
import { getModeViewForAPI } from "../helpers/view";

/**
 *
 * @param {*} token
 * @param {*} cid
 * @param {*} date
 * @param {*} limit
 * @param {*} service
 * @returns
 */
const getFirstMenuAvailable = async (token, cid, date, limit = 14, service = "menu") => {
    const data = await axios
        .get(config.API_URL + "/api/getFirstMenuAvailability", {
            params: {
                token,
                cid,
                date,
                limit,
                service,
            },
            method: "GET",
        })
        .then(response => {
            const data = response.data ? response.data : null;
            if (data.success && data.data?.menu) {
                return JSON.parse(data?.data?.menu);
            }
            return null;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};
/**
 *
 * @param {*} token
 * @param {*} canteenId
 * @param {*} day
 * @param {*} recipes
 * @param {*} occasionId
 * @param {*} dietId
 * @returns
 */
const rateRecipes = async (token, canteenId, day, recipes, occasionId, dietId) => {
    const data = await axios
        .post(config.API_URL + "/api/userRateRecipes", {
            token,
            canteenId,
            day,
            recipes,
            occasion: occasionId,
            diet: dietId,
            mode: getModeViewForAPI(),
        })
        .then(response => {
            return response && response.status === 200 && response.data ? response.data : null;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

export const menuAPIs = {
    getFirstMenuAvailable,
    rateRecipes,
};
