import { SET_NEWS, RESET_NEWS } from './../actions/newsActions';

const initialState = {
    filters: [],
    news: {}
};

export const newsReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null; 
    switch (action.type) {
        case SET_NEWS:
            return {
                ...state,
                ...payload,
            };
        case RESET_NEWS:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};