import React from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";

/**Utils */
import { getNewsHtml, getBaseOptionsForSlider } from "../../helpers/utils";

/**SCSS */
import "./_newsslider.scss";

const selector = ".owl-carousel.info-box";
const activeSelector = selector + " .owl-item.active .item";
class NewsSlider extends React.Component {
    constructor(props) {
        super(props);

        const baseOptions = getBaseOptionsForSlider();

        this.state = {
            news: props && props.news ? props.news : [],
            optionsNewsSlider: {
                ...baseOptions,
                items: 1,
                margin: 0,
                center: true,
                responsiveClass: true,
                dots: true,
                nav: true,
                startPosition: 0,
                navText: ["<i class='icon-small-arrow-left' />", "<i class='icon-small-arrow-right' />"],
                onTranslated: function (event) {
                    if ($(activeSelector)) {
                        if ($(activeSelector).attr("data")) {
                            const selected = JSON.parse($(activeSelector).attr("data"));
                            if (selected) {
                                //console.log(selected);
                            }
                        }
                    }
                },
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        let newState = {};
        if (nextProps && nextProps.news) {
            newState = {
                news: [...nextProps.news],
                optionsNewsSlider: {
                    ...this.state.optionsNewsSlider,
                    loop: nextProps.news?.length > 1,
                    startPosition: 0,
                },
            };
        } else {
            newState = {
                news: [],
                optionsNewsSlider: {
                    ...this.state.optionsNewsSlider,
                },
            };
        }

        this.setState(newState, e => {
            this.refresh({});
        });
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    render() {
        const { t } = this.props;
        const { news, optionsNewsSlider } = this.state;
        return (
            <React.Fragment>
                {news && Array.isArray(news) && news.length > 0 ? (
                    <OwlCarousel id="news-box-id" className="box-area owl-carousel info-box" {...optionsNewsSlider}>
                        {news.map(singleNews => {
                            return getNewsHtml(singleNews, t);
                        })}
                    </OwlCarousel>
                ) : (
                    <div className="box-area info-box no-data-container">{t("news.no_data_news")}</div>
                )}
            </React.Fragment>
        );
    }
}

export default NewsSlider;
