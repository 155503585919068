import { SPLASH_VISIBLE, SPLASH_INVISIBLE, SPLASH_CHANGE_VALUE } from "./../actions/splashscreenActions";

import { SPLASH_DEFAULT } from "../../helpers/constants";

const initialState = {
    splashVisible: false,
    value: SPLASH_DEFAULT,
};

export const splashReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case SPLASH_VISIBLE:
            return {
                ...state,
                splashVisible: true,
            };
        case SPLASH_INVISIBLE:
            return {
                ...state,
                splashVisible: false,
            };
        case SPLASH_CHANGE_VALUE: {
            return {
                ...state,
                value: payload && payload.value && payload.value !== "" ? payload.value : SPLASH_DEFAULT,
            };
        }
        default:
            return state;
    }
};
