export const Input = ({
    id,
    type,
    className,
    placeholder,
    autocomplete,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    disabled,
    value,
    innerRef = null,
}) => {
    type = type ? type : "text";
    className = className ? className : "form-control";
    disabled = disabled ? disabled : "";
    value = value ? value : "";
    autocomplete = autocomplete ? autocomplete : "";

    return autocomplete !== "" ? (
        <div className="form-group">
            <input
                ref={innerRef}
                type={type}
                className={className}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                autoComplete={autocomplete}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    ) : (
        <div className="form-group">
            <input
                ref={innerRef}
                type={type}
                className={className}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        </div>
    );
};

export default Input;
