/**SCSS */
import "./point.scss";

export const Point = ({ color = null }) => {
    const style = color
        ? {
              backgroundColor: color,
              borderColor: color,
          }
        : {};
    return <div className="point-container" style={style}></div>;
};
