/**SCSS */
import "./_modal.scss";

const Modal = ({ id = null, visible = false, fullScreen = false, close = () => {}, children = <div></div> }) => {
    let fullClassName = `popup ${visible ? "vis" : "hidden"}`;

    if (fullScreen) {
        fullClassName += ` fullscreen`;
    }

    return (
        <div id={id} className={fullClassName}>
            <div className="overPopup">
                <div className="contentPopup">
                    <div className="row d-flex justify-content-end close-spacer">
                        <span className="fa fa-times closer" onClick={close}></span>
                    </div>
                    <div className="row">
                        <div className="col-10 offset-1">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

/**
 * import { useEffect, useRef } from 'react';

import './style.css';

export const CustomModal = ({ children, visibility = false, innerRef = null, closeEvent = () => {} }) => {
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
            * Alert if clicked on outside of element
            function handleClickOutside(event) {
                if (ref && ref.current && !ref.current.contains(event.target)) {
                    closeEvent();
                }
            }
            
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideAlerter(innerRef);

    return visibility ? 
    (
        <div className="custom-modal-container">
            {children}
        </div>
    ) 
    : 
    null;
}
 */
