/**Packages */
import { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**API */
import { api } from "../../api/index";

/**Helpers */
import { seo } from "../../helpers/seo";
import { SITE_TITLE } from "../../helpers/constants";
import { sendPageViewEvent } from "../../helpers/dataLayer";
import {
    getIconByComponent,
    getCurrentLanguage,
    getClassNameByComponent,
    getIfUserHasEnabledToRate,
} from "../../helpers/utils";
import {
    getNutritionalDataHtml,
    getNutrinionalData,
    getCssFavoriteClassName,
    getIfFavorite,
    getIfNutritionalDataAreEnabled,
} from "../../helpers/menu";
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";
import {
    getIfParentRatingRecipesIsEnabled,
    getIfChildrenRatingRecipesIsEnabled,
    checkIfRateRecipeIsEnabled,
} from "../../helpers/enviroment";
import { success, error } from "../../helpers/toast";
import Result from "../../helpers/out";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Components */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import GoBackLink from "../../components/GoBackLink";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";
import Margin from "../../components/Margin";
import { RateRecipeBaseModal } from "../../components/RateRecipeBaseModal";
import { RateRecipe } from "../../components/RateRecipe";
import { FavoriteRecipe } from "../../components/FavoriteRecipe";

/**SCSS */
import "./_recipe_detail.scss";

class RecipeDetail extends Back {
    constructor(props) {
        super(props);

        this.state = {
            detailedInfoToggle: true,
            currentRecipe: props?.location?.state?.recipe,
            additionalData: {
                allergens: [],
                ingredients: [],
                description: {},
            },
            currentVote: -1,
            statusModal: -1,
            rateBaseModalVisible: false,
            reset: false,
        };

        this.closeRateModal = this.closeRateModal.bind(this);
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.setDataAfterRate = this.setDataAfterRate.bind(this);
        this.onRateSingleRecipe = this.onRateSingleRecipe.bind(this);
        this.toggleDetailedNutritionalInfo = this.toggleDetailedNutritionalInfo.bind(this);
    }

    async componentDidMount() {
        this.props.activateLoader();
        try {
            seo({
                title: SITE_TITLE + " || Recipe Detail",
                metaDescription: "",
            });

            if (!this.state.currentRecipe) {
                this.goBack();
            }

            const additionalData = await this?.context?.api?.getRecipeDataWithId(
                this?.state?.currentRecipe?.id,
                this?.props?.user?.canteen_id,
            );

            this.setState({
                additionalData,
            });
        } catch (e) {
            console.warn(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    async toggleFavorite(e) {
        e.preventDefault();
        this.props.activateLoader();
        try {
            await this.context?.api?.toggleRecipeFavorite(
                this.props?.user?.selectedProfile.docId,
                this.state.currentRecipe,
            );
        } catch (e) {
            console.log("errore");
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    toggleDetailedNutritionalInfo(e) {
        this.setState({
            detailedInfoToggle: !this.state.detailedInfoToggle,
        });
    }

    closeRateModal() {
        this.setState({
            rateBaseModalVisible: false,
            reset: false,
        });
    }

    /*
    Using Modal
    setDataAfterRate(out) {
        let statusModal = 0;

        if (out && out.success) {
            statusModal = 1;
        }

        this.setState({
            rateBaseModalVisible: true,
            statusModal,
            reset: true,
        });
    }
    */

    setDataAfterRate(out) {
        const self = this;

        if (out && out.success) {
            success(this.props.t("rate_recipe.modal.thanks"));
        } else {
            error(`${this.props.t("rate_recipe.modal.error")}!`);
        }

        this.setState(
            {
                reset: true,
            },
            () => {
                self.setState({ reset: false });
            },
        );
    }

    async onRateSingleRecipe(e, recipeId, status) {
        const out = Result.default();

        try {
            e.preventDefault();

            if (recipeId && status && status.hasOwnProperty("value") && status.value > 0) {
                const { cid, selectedDiet, selectedOccasion, selectedDate } = this.props.location.state;

                //API - firebase function
                const dataReturned = await api.menu.rateRecipes(
                    await this.context?.api?.getToken(),
                    cid,
                    selectedDate,
                    [
                        {
                            course_id: this.state.currentRecipe.course_id,
                            course_name: this.state.currentRecipe.course_name,
                            recipeId,
                            vote: status.value,
                        },
                    ],
                    selectedOccasion.occasion_id,
                    selectedDiet.diet_id,
                );

                Object.assign(out, dataReturned);
            } else {
                console.log("No recipes has been rated");
            }
        } catch (error) {
            console.log("error in onRateRecipes");
            console.log(error);
        } finally {
            this.setDataAfterRate(out);
        }
    }

    render() {
        const { currentRecipe, currentVote, additionalData, rateBaseModalVisible, statusModal, reset } = this.state,
            {
                id,
                name,
                occasion_id,
                occasion_name,
                diet_id,
                diet_name,
                course_id,
                course_name,
                fat,
                kcal,
                carbs,
                proteins,
                protein,
            } = currentRecipe,
            { allergens, ingredients, description } = additionalData;

        const cssComponentClassName = getClassNameByComponent(
            occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
        );

        const favoriteRecipes = this.props?.user?.selectedProfile?.favorite_recipes
            ? this.props?.user?.selectedProfile?.favorite_recipes
            : [];

        const isFavorite = getIfFavorite(favoriteRecipes, id);

        return (
            <Fragment>
                <Header />
                <section id="recipe-detail-container" className="middle-content inner-area">
                    <GoBackPlusTitle onClick={this.goBack} goBackText={this.props.t("recipe_detail.go_home")} />

                    <div className="container maxwidth">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className={`column-details ${cssComponentClassName}`}>
                                    {getIconByComponent(
                                        occasion_id && occasion_id !== 4
                                            ? course_name[process.env.REACT_APP_DEFAULT_LNG]
                                            : "merienda",
                                    )}
                                    <div className={`default-heading ${cssComponentClassName}`}>
                                        <h3>{name[getCurrentLanguage()]}</h3>
                                    </div>
                                </div>

                                <div className="ingredientes">
                                    <div className="ingredientes-list">
                                        <div className={`default-heading ${cssComponentClassName}`}>
                                            <h4>{this.props.t("recipe_detail.ingredients")}</h4>
                                        </div>
                                        <ul>
                                            {ingredients && Array.isArray(ingredients) && ingredients.length > 0 ? (
                                                ingredients.map(ingredient => {
                                                    const key = "ingredient-" + ingredient.name[getCurrentLanguage()],
                                                        translatedName = ingredient.name[getCurrentLanguage()];
                                                    return <li key={key}>{translatedName}</li>;
                                                })
                                            ) : (
                                                <li>{this.props.t("recipe_detail.no_ingredients")}</li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="ingredientes-list">
                                        <div className={`default-heading ${cssComponentClassName}`}>
                                            <h4>{this.props.t("recipe_detail.allergens")}</h4>
                                        </div>
                                        <ul>
                                            {allergens && Array.isArray(allergens) && allergens.length > 0 ? (
                                                allergens.map(allergen => {
                                                    const key = "allergen-" + allergen.name;
                                                    return (
                                                        <li key={key}>
                                                            {this.props.t(
                                                                "recipe_detail.allergens_description." + allergen.name,
                                                            )}
                                                        </li>
                                                    );
                                                })
                                            ) : (
                                                <li>{this.props.t("recipe_detail.no_allergens")}</li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="slide-area d-flex justify-content-between">
                                    <span>{this.props.t("recipe_detail.nutritional_details")}</span>
                                    <i
                                        className={
                                            this.state.detailedInfoToggle
                                                ? "icon-top-arrow no-rotate icon-left"
                                                : "icon-top-arrow icon-left"
                                        }
                                        onClick={this.toggleDetailedNutritionalInfo}
                                    />
                                </div>
                                {getIfNutritionalDataAreEnabled(this.props.canteen) && (
                                    <div
                                        className={
                                            this.state.detailedInfoToggle
                                                ? "information-area opened"
                                                : "information-area closed"
                                        }
                                    >
                                        <div className={`default-heading ${cssComponentClassName}`}>
                                            <h2>{this.props.t("recipe_detail.nutritional_information")}</h2>
                                        </div>
                                        {getNutritionalDataHtml(
                                            getNutrinionalData([
                                                { kcal, carbs, proteins: proteins || protein || 0, fat },
                                            ]),
                                            this.props.t,
                                        )}
                                    </div>
                                )}
                                <FavoriteRecipe
                                    cssComponentClassName={cssComponentClassName}
                                    favoriteRecipes={favoriteRecipes}
                                    id={id}
                                    isFavorite={isFavorite}
                                    toggleFavorite={this.toggleFavorite}
                                />
                                {checkIfRateRecipeIsEnabled() && getIfUserHasEnabledToRate() && (
                                    <>
                                        <Margin marginTop="16px" />
                                        <div className="rate-recipe-area-container">
                                            <div className={`default-heading ${cssComponentClassName}`}>
                                                <h2>{this.props.t("recipe_detail.rate_recipe_title")}</h2>
                                            </div>
                                            <RateRecipe
                                                recipeId={id}
                                                internalId={null}
                                                vote={currentVote}
                                                onRateRecipe={this.onRateSingleRecipe}
                                                reset={reset}
                                            />
                                        </div>
                                    </>
                                )}
                                <Margin marginTop="32px" />
                                <GoBackLink text={this.props.t("recipe_detail.go_home")} onClick={this.goBack} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                {/**
                        <RateRecipeBaseModal
                            visible={rateBaseModalVisible}
                            status={statusModal}
                            close={this.closeRateModal}
                        />
                     */}
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

RecipeDetail.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(RecipeDetail));

{
    /*
                                        <div className="vocation-area">
                                            <div className={`default-heading ${cssComponentClassName}`}>
                                                <h3>Votación</h3>
                                            </div>
                                            <div className="starts">
                                                <i className="icon-solidstar"></i>
                                                <i className="icon-blankstar"></i>
                                                <i className="icon-blankstar"></i>
                                                <i className="icon-blankstar"></i>
                                                <i className="icon-blankstar"></i>
                                            </div>
                                        </div>
                                    */
}
