/**Packages */
import { useState } from "react";
import { useTranslation } from "react-i18next";

/**Helpers */
import { getIfFavorite, getCssFavoriteClassName } from "../../helpers/menu";

/**SCSS */
import "./favorite_recipe.scss";

export const FavoriteRecipe = ({
    cssComponentClassName,
    favoriteRecipes,
    id,
    toggleFavorite,
    isFavorite = false,
    useNew = true,
}) => {
    const [t, i18n] = useTranslation();
    const [seeText, setSeeText] = useState(true);

    /**
     * Hover effect disabled on 31/01/2022 on Viola request
     */

    return !useNew ? (
        <div
            className={`add-to-favourite-container clickable ${cssComponentClassName}`}
            //onMouseEnter={() => setSeeText(true)}
            //onMouseLeave={() => setSeeText(false)}
            onClick={toggleFavorite}
        >
            <div className="add-to-favourite--text">
                {t(isFavorite ? "children_mode.remove_from_favourite" : "children_mode.add_to_favourite")}
                {"  "}
                <span className={getCssFavoriteClassName(favoriteRecipes, id)} />
            </div>
        </div>
    ) : isFavorite ? (
        <div
            className={`favorite-remove-container clickable ${cssComponentClassName}`}
            //onMouseEnter={() => setSeeText(true)}
            //onMouseLeave={() => setSeeText(false)}
            onClick={toggleFavorite}
        >
            <span className="hoverable-icon" />
            <span className="hoverable-text">{seeText ? t("children_mode.remove_from_favourite") : " "}</span>
        </div>
    ) : (
        <div
            className={`favorite-add-container clickable ${cssComponentClassName}`}
            //onMouseEnter={() => setSeeText(true)}
            //onMouseLeave={() => setSeeText(false)}
            onClick={toggleFavorite}
        >
            <span className="hoverable-icon" />
            <span className="hoverable-text">{seeText ? t("children_mode.add_to_favourite") : " "}</span>
        </div>
    );
};
