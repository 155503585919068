/**Packages */
import toast from "react-hot-toast";

/**Helpers */
import { checkString } from "./utils";

/**
 *
 * @param {*} message string to display
 * @param {*} options = {}
 * @returns Nothing but this method will display success dialog
 */
export const success = (message, options) => {
    if (!checkString(message)) return null;

    toast.success(message);
};
/**
 *
 * @param {*} message string to display
 * @param {*} options = {}
 * @returns Nothing but this method will display error dialog
 */
export const error = (message, options) => {
    if (!checkString(message)) return null;

    toast.error(message);
};

/*
TODO: info with correct background

export const info = (message, options) => {
    if(!checkString(message)) return null;

    toast.info(message);
}
*/
