/**Packages */
import axios from "axios";

/**Config */
import { default as config } from "./../firebase/config";

/**Utils */
import { getEnvironment } from "../helpers/enviroment";
import { getCurrentLanguage } from "./../helpers/utils";

const getPDFBytes = async (token, cid, month, year, language = getCurrentLanguage(), dataSelected, filters) => {
    const data = await axios
        .post(
            //getPDFByOccasionDietMonth
            config.API_URL + "/api/getPDFBytesByOccasionDietMonth",
            {
                token,
                cid,
                month,
                year,
                language,
                dataSelected,
                filters,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};
const getPDFBytesV2 = async (token, cid, month, year, language = getCurrentLanguage(), dataSelected, filters) => {
    const data = await axios
        .post(
            //getPDFByOccasionDietMonth
            config.API_URL + "/api/getPDFBytesByOccasionDietMonth/v2",
            {
                cid,
                month,
                year,
                language,
                dataSelected,
                filters,
            },
            {
                method: "POST",
                headers: {
                    Authorization: token,
                },
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};
const getPDFBytesHandler = async (token, cid, month, year, language = getCurrentLanguage(), dataSelected, filters) => {
    switch (getEnvironment()) {
        case "es": {
            return await getPDFBytesV2(token, cid, month, year, language, dataSelected, filters);
        }
        default: {
            return await getPDFBytes(token, cid, month, year, language, dataSelected, filters);
        }
    }
};
const getDataPerPDF = async (token, cid, month, year, language = getCurrentLanguage(), filters) => {
    const data = await axios
        .post(
            config.API_URL + "/api/getMenuDataForCanteenPerMonth",
            {
                token,
                cid,
                month,
                year,
                language,
                filters,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};
const getPdfBaseMenu = async (token, cid, month, year, language = getCurrentLanguage()) => {
    const data = await axios
        .post(
            config.API_URL + "/api/getPdfBaseMenu",
            {
                token,
                cid,
                month,
                year,
                language,
            },
            {
                method: "POST",
            },
        )
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};
const checkCode = async canteen_id => {
    const data = await axios
        .post(config.API_URL + "/checkCanteen", {
            canteen_id,
        })
        .then(response => {
            /*
            Response on success
            console.log(response);
            out['exists'] = true;
            out['data'] = canteen_data.privacy;
            out['check_domain'] = canteen_data.auth_domains_required;
            out['domains'] = canteen_data.auth_domains;
            out['message'] = '';
            */
            return response.data;
        })
        .catch(err => {
            console.warn("err");
            console.warn(err);
            return null;
        });

    return data;
};
const checkCanteenByReferrer = async referrer => {
    const data = await axios
        .get(config.API_URL + "/api/checkCanteenByReferrer?referrer=" + referrer)
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn("err");
            console.warn(err);
            return null;
        });

    return data;
};

export const canteenAPIs = {
    checkCode,
    getPDFBytes,
    getPDFBytesV2,
    getPDFBytesHandler,
    getDataPerPDF,
    getPdfBaseMenu,
    checkCanteenByReferrer,
};
