import { 
    SET_CANTEEN_DATA,
    DELETE_CANTEEN_DATA,
} from './../actions/canteenActions';

/**
 * BASE CASE
 */
let initialState = {
    canteen_id: null,
    bo_logo: null,
    privacy: [],
    address: ''
};

export const canteenReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null; 
    switch (action.type) {
        case SET_CANTEEN_DATA: {
                return {
                    ...state,
                    ...payload  
                };
            }
        case DELETE_CANTEEN_DATA:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};