/**Packages */
import { Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
//import TagManager from "react-gtm-module";

/**Redux */
import { useSelector } from "react-redux";

/**Components */
import Main from "./components/Main";
import ReactLoader from "./components/Loader";
import SplashScreen from "./components/SplashScreen";

/**Utils */
import { disabledOverflowY } from "./helpers/utils";
import { enableOverflowY, disableOverflowY } from "./helpers/overflow";

/**CSS */
import "./App.css";

const Loader = value => <ReactLoader visible={value} />;
const SplashScreenW = value => <SplashScreen visible={value} />;

const toasterOptions = { style: { fontSize: "18px" } };
const toasterStyle = { "z-index": 1000000 };

function App() {
    const loaderVisible = useSelector(state => state?.loader?.loaderVisible);
    const splashVisible = useSelector(state => state?.splash?.splashVisible);
    const isOverflowYToDisable = disabledOverflowY();

    /**
     *
     * To disable overflow-y on certain pages recognized from url pathname;
     */

    /**To manipulate overflowY on modal opening */
    useEffect(() => {
        if (loaderVisible || splashVisible || isOverflowYToDisable) {
            disableOverflowY();
        } else {
            enableOverflowY();
        }
    }, [loaderVisible, splashVisible, isOverflowYToDisable]);

    /**
     * GTM configuration; 
        useEffect(() => {
            if (process.env.REACT_APP_GTM_CODE && process.env.REACT_APP_GTM_CODE !== "") {
                const tagManagerArgs = {
                    gtmId: process.env.REACT_APP_GTM_CODE,
                };
    
                TagManager.initialize(tagManagerArgs);
            }
        }, []);

        
     * */

    return (
        <Suspense fallback={Loader(true)}>
            <>
                {SplashScreenW(splashVisible)}
                {Loader(loaderVisible)}
                <Main />
                <Toaster position="top-center" toastOptions={toasterOptions} style={toasterStyle} />
            </>
        </Suspense>
    );
}

export default App;
