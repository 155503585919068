/**Helpers */
import { getCurrentLanguage } from "./utils";

const defaultLng = process.env.REACT_APP_DEFAULT_LNG;

/**
 *
 * @param {*} translations object of translations -> ex. {'it': '', 'es': '', ...}
 * @param {*} lngToCheck string to check existence
 * @returns True if lngToCheck is included in translations, otherwise false
 */
const checkTranslationExistence = (translations, lngToCheck) => {
    if (!(translations || lngToCheck || translations instanceof Object)) return false;

    return Object.prototype.hasOwnProperty.call(translations, lngToCheck) && translations[lngToCheck] !== "";
};
/**
 *
 * @param {*} translations object of translations -> ex. {'it': '', 'es': '', ...}
 * @param {*} lngToCheck language to get value
 * @returns The string if exists, otherwise empty string
 */
export const getTranslatedString = (translations, lngToCheck = getCurrentLanguage()) => {
    if (!(translations || lngToCheck || translations instanceof Object)) return "";

    //const languages = Object.keys(translations);

    return checkTranslationExistence(translations, lngToCheck)
        ? translations[lngToCheck]
        : checkTranslationExistence(translations, defaultLng)
        ? translations[defaultLng]
        : checkTranslationExistence(translations, "en")
        ? translations["en"]
        : "";
};
