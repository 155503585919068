/**
 *
 * @param {*} key - key of the value
 * @param {*} value - value to save
 * The method save the value with identifier key in the localStorage
 */
export const addToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};
/**
 *
 * @param {*} key - key of the value
 * The method return the value with identifier key from the localStorage
 */
export const getFromLocalStorage = key => {
    return localStorage.getItem(key) ? localStorage.getItem(key) : null;
};
/**
 *
 * @param {*} key - key of the value
 * The method remove the value with identifier key from the localStorage
 */
export const removeFromLocalStorage = key => {
    localStorage.removeItem(key);
};
