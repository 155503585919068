/**Store */
import store from "../redux/store";

export const getState = () => {
    return store.getState();
};

export const getCurrentUser = () => {
    const state = getState();

    if (state && state.user) {
        return state.user;
    }

    return null;
};

export const getCurrentUserSelectedProfile = () => {
    const state = getState();

    if (state && state.user && state.user.selectedProfile) {
        return state.user.selectedProfile;
    }

    return null;
};

export const getCurrentCidFromCanteen = () => {
    const state = getState();

    if (state && state.canteen) {
        return state.canteen.canteen_id;
    }

    return null;
};

export const getCurrentCanteen = () => {
    const state = getState();

    if (state && state.canteen) {
        return state.canteen;
    }

    return null;
};

export const getMenuRules = () => {
    const state = getState();

    if (state && state.menuRules) {
        return state.menuRules;
    }

    return null;
};
