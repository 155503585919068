/*
@Deprecated
import LZString from "lz-string";

OLD TEST
Trigger the browser to download the PDF document
download(new TextEncoder().encode(pdfBytes), fileName, documentType);
download(fromBytesStringToUint8Array(pdfBytesString), fileName, documentType);

const fromBytesStringToUint8Array = fullStr => {
    if (!fullStr || fullStr === "") return null;

    const lzStringDecompressed = fullStr; //LZString.decompress(fullStr);
    if (lzStringDecompressed) {
        const arrBytes = lzStringDecompressed.split(",");
        return Uint8Array.from(arrBytes);
    }

    return null;
};
*/

/**
 *
 * @param {*} base64 - base64 string of content
 * @returns The method returns the base64 string parsed in ArrayBuffer (array of binary)
 */
function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}
/**
 *
 * @param {*} param0 = { pdfBase64String, fileName, documentType }
 * @returns The methods permits the download of a file with filename, documentType and content (= pdfBase64String = content)
 */
export const downloadPdf = ({ pdfBase64String, fileName, documentType }) => {
    if (!pdfBase64String || pdfBase64String === "" || !fileName || fileName === "") return;
    //Trigger the browser to download the PDF document
    // eslint-disable-next-line no-undef
    download(_base64ToArrayBuffer(pdfBase64String), fileName, documentType);
};
