/**Helpers */
import { SITE_TITLE } from "./constants";

/**
 *
 * @param {*} data = { title: "", metaDescription: "" }
 * The method set title and metaDescription for the current page
 */
export function seo(data = { title: "", metaDescription: "" }) {
    //Commented for GTM/GA management
    //data.title = data.title || "Default title";
    data.title = SITE_TITLE;
    data.metaDescription = data.metaDescription || "Default description";
    data.metaDescription = data.metaDescription || "Default description";

    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute("content", data.metaDescription);
}
