/**Helpers */
import { CHILDREN_CSS_CLASS } from "./constants";
import { addToLocalStorage, removeFromLocalStorage } from "./localStorage";

/**
 * Method used to toggle from parent to child mode and the other way around
 */
export const toggleView = () => {
    addToLocalStorage(CHILDREN_CSS_CLASS, true);
    const element = document.getElementsByTagName("body")[0];
    if (element) {
        element.classList.toggle(CHILDREN_CSS_CLASS);
    }
};
/**
 * Method used to return to parent mode
 */
export const returnToParentMode = () => {
    removeFromLocalStorage(CHILDREN_CSS_CLASS);
    const element = document.getElementsByTagName("body")[0];
    if (element) {
        element.classList.remove(CHILDREN_CSS_CLASS);
    }
};
/**
 *
 * @returns true if children mode is activaded, otherwise false
 */
export const checkIfChildrenModeIsActivaded = () => {
    const element = document.getElementsByTagName("body")[0];
    return element?.classList?.contains(CHILDREN_CSS_CLASS);
};
/**
 *
 * @returns "children" if children mode is activated, otherwise "parent"
 */
export const getViewKeyForPageViewEvent = () => {
    return checkIfChildrenModeIsActivaded() ? "children" : "parent";
};
/**
 *
 * @returns "children" if children mode is activated, otherwise "parent"
 */
export const getModeViewForAPI = () => {
    return checkIfChildrenModeIsActivaded() ? "children" : "parent";
};
