/**Config */
import { default as config } from "./../firebase/config";

/**Helpers */
import { MYSODEXO_COLLAUDO_BASE_URL, PAGES } from "./constants";
import { getCidByState, getCurrentLanguage, openUrl } from "./utils";
import { getBaseCanteenPrivacyTermsUrl } from "./enviroment";

const getIfValidCid = cid => {
    return cid.indexOf("C_") !== -1;
};

const getStaticPageFullUrl = (cid, language = getCurrentLanguage(), page = "privacy") => {
    if (!cid || cid === "" || !getIfValidCid(cid)) cid = getBaseCanteenPrivacyTermsUrl();

    const cidFormatted = getCidByState(cid);

    if (config.API_URL.indexOf(MYSODEXO_COLLAUDO_BASE_URL) === -1) {
        return `${config.API_URL}/staticpage/${cidFormatted}/${page}/${new Date().getTime()}/${language}`;
    }

    return `${config.API_URL}/staticpage?cid=${cid}&page=${page}&l=${language}`;
};

export const getAndOpenStaticPageByUrl = (cid, language = getCurrentLanguage(), page = "privacy") => {
    openUrl(getStaticPageFullUrl(cid, language, page));
};

export const getStaticPageByUrl = (cid, language = getCurrentLanguage(), page = "privacy") => {
    return getStaticPageFullUrl(cid, language, page);
};

export const getIfLocationContainCanteenName = (pathname = window.location.pathname) => {
    if (!pathname || ["", "/"].includes(pathname)) return false;

    const keys = Object.keys(PAGES);

    const result = keys.find(key => {
        return `/${PAGES[key]}` === pathname;
    });

    return result === undefined;
};
