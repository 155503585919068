import { FROM_REGISTRATION, FINISH_REGISTRATION } from './../actions/registerActions';

const initialState = {
    fromRegistration: false,
    canteen_id: null
};

export const registerReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null; 
    switch (action.type) {
        case FROM_REGISTRATION:
            return {
                ...state,
                fromRegistration: true,
                canteen_id: payload.canteen_id
            };
        case FINISH_REGISTRATION: 
            return {
                ...state,
                ...initialState,
            }
        default:
            return state;
    }
};