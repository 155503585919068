/**Packages */
import React from "react";
import OwlCarousel from "react-owl-carousel";

/**Utils */
import { SOHAPPY_GAMES_URL } from "../../helpers/constants";
import { getBaseOptionsForSlider, openUrl } from "../../helpers/utils";

/**Images */
import game_1 from "../../images/sohappy_games/rigolo/game_1.png";

/**SCSS */
import "./_games.scss";

const selector = ".games.owl-carousel";
const activeSelector = selector + " .owl-item.active .item";

class GamesSlider extends React.Component {
    constructor(props) {
        super(props);

        const options = {};

        if (props.responsive) {
            options.responsive = props.responsive;
        } else {
            options.items = 1;
        }

        const baseOptions = getBaseOptionsForSlider();

        this.state = {
            useTitle: props.useTitle ? props.useTitle : false,
            useSubTitle: props.useSubTitle ? props.useSubTitle : false,
            games: [
                {
                    img: game_1,
                    url: SOHAPPY_GAMES_URL,
                    text: this.props.t("games.games.one"),
                },
            ],
            options: {
                ...baseOptions,
                margin: 0,
                center: true,
                responsiveClass: true,
                dots: true,
                nav: false,
                startPosition: 0,
                ...options,
            },
        };
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    render() {
        const { t, onClick, noItemsText = null } = this.props;
        const { games, options, useTitle, useSubTitle } = this.state;

        return (
            <React.Fragment>
                {games && Array.isArray(games) && games.length > 0 ? (
                    <OwlCarousel className="games owl-carousel" {...options}>
                        {games.map((game, idx) => {
                            const { img, url, text } = game;

                            return (
                                <div key={`games-slider-${idx}`} className="item">
                                    <img
                                        src={img}
                                        alt={`Game ${idx}`}
                                        className="clickable"
                                        onClick={e => openUrl(url)}
                                    />
                                    {useSubTitle && (
                                        <div className="info-text clickable" onClick={e => openUrl(url)}>
                                            <p>{text}</p>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </OwlCarousel>
                ) : (
                    <div className="box-area no-data-container">{noItemsText || t("news.no_data_activities")}</div>
                )}
            </React.Fragment>
        );
    }
}

export default GamesSlider;
