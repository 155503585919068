/**CSS */
import "./style.css";

export const ActionButton = ({ text = "", type = "delete", onClick = () => {} }) => {
    let classNames = [];

    if (type === "delete") {
        classNames.push(
            ...[
                "profile-delete-button-container clickable",
                "profile-delete-button-icon-container clickable",
                "profile__delete-button",
                "profile-delete__text",
            ],
        );
    } else if (type === "undo") {
        classNames.push(
            ...[
                "profile-confirm-button-container clickable",
                "profile-confirm-button-icon-container clickable",
                "profile__confirm-button icon-small-arrow-left",
                "profile-confirm__text",
            ],
        );
    } else {
        classNames.push(
            ...[
                "profile-confirm-button-container clickable",
                "profile-confirm-button-icon-container clickable",
                "profile__confirm-button fa fa-check",
                "profile-confirm__text",
            ],
        );
    }

    return (
        <div className={classNames[0]} onClick={onClick}>
            <div className={classNames[1]}>
                <span className={classNames[2]} />
            </div>
            <h2 className={classNames[3]}>{text}</h2>
        </div>
    );
};
