/**Helpers */
import { getIfMobile } from "./screen";

/**
 *
 * @param {*} checkScreenSize - check is mobile mode activated or not
 * The method disable overflow-y feature if is enabled, otherwise do nothing
 */
export const disableOverflowY = (checkScreenSize = false) => {
    if (!checkScreenSize || (checkScreenSize && getIfMobile())) {
        document.body.style.overflowY = "hidden";
    }
};
/**
 * The method disable overflow-x
 */
export const disableOverflowX = () => {
    document.body.style.overflowX = "hidden";
};
/**
 * The method enable overflow-y
 */
export const enableOverflowY = () => {
    document.body.style.overflowY = "auto";
};
/**
 * The method enable overflow-x
 */
export const enableOverflowX = () => {
    document.body.style.overflowX = "auto";
};
