import axios from "axios";
import { default as config } from "./../firebase/config";
import { getCurrentLanguage } from "./../helpers/utils";

const getAllNews = async (token, cid, date, lang = getCurrentLanguage()) => {
    const data = await axios
        .get(config.API_URL + "/api/getNewsByCid", {
            params: {
                token,
                cid,
                date,
                lang,
            },
            method: "GET",
        })
        .then(response => {
            return response.data;
        })
        .catch(err => {
            console.warn(err);
            return null;
        });

    return data;
};

export const newsAPIs = {
    getAllNews,
};
