import {
    USER_LOGGED_IN,
    USER_LOGGED_OUT,
    USER_UPDATE_FIELDS,
    USER_UPDATE_PROFILE,
    USER_UPDATE_NAME_PROFILE,
    USER_UPDATE_AVATAR_PROFILE,
    USER_CHANGE_PROFILE,
    USER_SELECTED_PROFILE,
    USER_ADD_NEW_CANTEEN,
    USER_DELETE_PROFILE,
    SET_USER_PROFILES,
    UPDATE_USER_PROFILE_FAVORITE_RECIPES,
} from "./../actions/userActions";

/**
 * BASE CASE
 */
let initialState = {
    uid: null,
    canteen_id: null,
    canteens: [],
    profiles: [],
    selectedProfile: {},
};

export const userReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case USER_LOGGED_IN: {
            return {
                ...state,
                ...payload,
            };
        }
        case USER_SELECTED_PROFILE:
            return {
                ...state,
                canteen_id: payload.cid,
                selectedProfile: payload,
            };
        case SET_USER_PROFILES:
            return {
                ...state,
                canteen_id: payload.selectedProfile?.cid,
                ...payload,
            };
        case USER_UPDATE_FIELDS: {
            return {
                ...state,
                ...payload,
            };
        }
        case USER_UPDATE_PROFILE: {
            const idInArray = state.profiles.findIndex(itm => {
                return itm.docId === payload.id;
            });

            if (idInArray >= 0) {
                state.profiles[idInArray] = { ...payload.profile };
            }

            return {
                ...state,
            };
        }
        case USER_UPDATE_AVATAR_PROFILE: {
            const idInArray = state.profiles.findIndex(itm => {
                return itm.docId === payload.id;
            });

            if (idInArray >= 0) {
                state.profiles[idInArray].avatar = payload.avatar;
            }

            return {
                ...state,
            };
        }
        case USER_UPDATE_NAME_PROFILE: {
            const idInArray = state.profiles.findIndex(itm => {
                return itm.docId === payload.id;
            });

            if (idInArray >= 0) {
                state.profiles[idInArray].name = payload.name;
            }

            return {
                ...state,
            };
        }
        case UPDATE_USER_PROFILE_FAVORITE_RECIPES: {
            /**
             * id -> user profile id;
             * recipe -> recipe
             */
            const { id: userProfileId, recipe } = payload;

            const idInArray = state.profiles.findIndex(itm => {
                return itm.docId === userProfileId;
            });

            /**
             * Commented operation on profiles array
             *
             * Becuase selectedProfile and profiles[idx] are the same object
             */

            if (idInArray >= 0 && state.selectedProfile?.docId === userProfileId) {
                if (state.selectedProfile?.favorite_recipes) {
                    const recipeIndex = state.selectedProfile?.favorite_recipes.findIndex(itm => {
                        return itm.id === recipe.id;
                    });
                    if (recipeIndex >= 0) {
                        //To delete
                        state.selectedProfile?.favorite_recipes.splice(recipeIndex, 1);
                        //state.profiles[idInArray].favorite_recipes.splice(recipeIndex, 1);
                    } else {
                        //To add
                        state.selectedProfile?.favorite_recipes.push(recipe);
                        //state.profiles[idInArray].favorite_recipes.push(recipe);
                    }
                } else {
                    state.selectedProfile.favorite_recipes = [];
                    state.selectedProfile.favorite_recipes.push(recipe);
                    //state.profiles[idInArray].favorite_recipes = [];
                    //state.profiles[idInArray].favorite_recipes.push(recipe);
                }
            }

            return {
                ...state,
            };
        }
        case USER_ADD_NEW_CANTEEN: {
            if (!state.canteens[payload.id]) {
                state.canteens[payload.id] = {};
                state.canteens[payload.id] = payload;
            }
            return {
                ...state,
            };
        }
        case USER_DELETE_PROFILE: {
            const idx = state.profiles.findIndex(itm => {
                return itm.docId === payload.docId;
            });

            if (idx !== -1) {
                state.profiles.splice(idx, 1);
            }

            return {
                ...state,
            };
        }
        case USER_LOGGED_OUT:
            return {
                ...initialState,
            };
        case USER_CHANGE_PROFILE:
            //TODO: manipulate data
            return state;
        default:
            return state;
    }
};
