import { Component } from "react";
import { checkString, validateEmail, validateGenericString, validatePassword } from "../../helpers/validators";
const validationWithCases = (value, caseToUse) => {
    if (!checkString(caseToUse) || !checkString(value)) return false;

    switch (caseToUse) {
        case "email": {
            return validateEmail(value);
        }
        case "password": {
            return validatePassword(value);
        }
        case "generic": {
            return validateGenericString(value);
        }
        default: {
            return true;
        }
    }
};
class OnChange extends Component {
    onChange(e, label, checkContent = false, typeOfToCheck = null) {
        if (label && label !== "") {
            const newState = this.state;
            const value = e.target && e.target.value && e.target.value !== "" ? e.target.value : "";
            if (
                typeof value === "string" &&
                (!checkContent || (checkContent && validationWithCases(value, typeOfToCheck)))
            ) {
                newState[label] = value;
                this.setState(newState);
            } else {
                console.log(`Per la ${label} nessun valore`);
            }
        } else {
            console.log(`${label} errore`);
        }
    }
    onChangeCheckBox(e, label) {
        if (label && label !== "") {
            const newState = this.state;
            newState[label] = !newState[label];
            this.setState(newState);
        } else {
            console.log(`Per la ${label} nessun valore`);
        }
    }
    onChangeRadio(e, label, newValue) {
        if (label && label !== "") {
            const newState = this.state;
            newState[label] = newValue;
            this.setState(newState);
        } else {
            console.log(`Per la ${label} nessun valore`);
        }
    }

    onBlur(e) {
        if (e && e.target) {
            e.target.className = "form-control";
        }
    }
}

export default OnChange;
