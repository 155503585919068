/**Packages */
import $ from "jquery";
import jQuery from "jquery";

window.jQuery = jQuery;
// For fixed top bar
/*

function checkScroll() {
    //const scrollFromTop = $(window).scrollTop(),
    const scrollFromTop = window.pageYOffset,
        classes = "menu-fixed animated fadeInDown",
        //classesSticky = "menu-sticky animated fadeInDown",
        startFixPosition = 0,
        //startFixPosition = 20,
        header = document.getElementById("header"),
        offsetTop = header.offsetTop;

    console.log("header");
    console.log(header);
    console.log("offsetTop");
    console.log(offsetTop);
    console.log("scrollFromTop");
    console.log(scrollFromTop);

    if (scrollFromTop > offsetTop + startFixPosition) {
        //console.log("aggiungo");
        $(".headerSection").addClass(classes);
    } else {
        //console.log("tolgo");
        $(".headerSection").removeClass(classes);
    }
}

window.onscroll = function () {
    checkScroll();
};
*/

$(document).on("ready", function () {});

$(function () {
    $(".slide-area .icon-top-arrow").click(function () {
        $(".information-area").toggleClass("closed");
        $(".slide-area .icon-top-arrow").toggleClass("ninety");
    });

    $(".owl-prev").html('<i class="icon-small-arrow-left"></i>');
    $(".owl-next").html('<i class="icon-small-arrow-right"></i>');
});
