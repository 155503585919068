/**Packages */
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**Components */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Margin from "../../components/Margin";
import Back from "../../components/Back";
import GoBackLink from "../../components/GoBackLink";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";

/**SCSS */
import "./_allergens.scss";

class Allergens extends Back {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    render() {
        return (
            <Fragment>
                <Header />
                <div id="favorites-container-id" className="middle-content inner-area favorites-area">
                    <GoBackPlusTitle
                        onClick={this.goBack}
                        goBackText={this.props.t("allergens.go_home")}
                        title={this.props.t("allergens.title")}
                    />

                    <div className="container maxwidth">
                        <div className="row justify-content-flex-start allergens-text-container">
                            <p>
                                L&rsquo;entrata in vigore del Regolamento UE N. 1169/2011, obliga gli operatori a
                                fornire informazioni sugli alimenti ai consumatori e nella sua specifica applicazione
                                per quanto riguarda le indicazioni sulla presenza di allergeni negli alimenti forniti
                                alle collettivit&agrave;.
                            </p>
                            <p>Sono 14 gli allergeni considerati dalla norma:</p>
                            <p>
                                Per qualsiasi informazione &egrave; possibile e consigliato di rivolgersi al personale
                                di cucina.
                            </p>

                            <ul className="ul-allergens-outside">
                                <li>
                                    <p>
                                        1. Cereali contenenti glutine, cio&egrave;: grano, segale, orzo, avena, farro,
                                        kamut o i loro ceppi ibridati e prodotti derivati, tranne:
                                    </p>
                                    <ul className="ul-allergens-inside">
                                        <li>
                                            <p>a) sciroppi di glucosio a base di grano, incluso destrosio;</p>
                                        </li>
                                        <li>
                                            <p>b) maltodestrine a base di grano;</p>
                                        </li>
                                        <li>
                                            <p>c) sciroppi di glucosio a base di orzo;</p>
                                        </li>
                                        <li>
                                            <p>
                                                d) cereali utilizzati per la fabbricazione di distillati alcolici,
                                                incluso l&rsquo;alcol etilico di origine agricola.
                                            </p>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <p>2. Crostacei e prodotti a base di crostacei.</p>
                                </li>
                                <li>
                                    <p>3. Uova e prodotti a base di uova.</p>
                                </li>
                                <li>
                                    <p>4. Pesce e prodotti a base di pesce, tranne:</p>
                                    <ul className="ul-allergens-inside">
                                        <li>
                                            <p>
                                                a) gelatina di pesce utilizzata come supporto per preparati di vitamine
                                                o carotenoidi;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                b) gelatina o colla di pesce utilizzata come chiarificante nella birra e
                                                nel vino.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>5. Arachidi e prodotti a base di arachidi.</p>
                                </li>
                                <li>
                                    <p>6. Soia e prodotti a base di soia, tranne:</p>
                                    <ul className="ul-allergens-inside">
                                        <li>
                                            <p>a) olio e grasso di soia raffinato ( 1 );</p>
                                        </li>
                                        <li>
                                            <p>
                                                b) tocoferoli misti naturali (E306), tocoferolo D-alfa naturale,
                                                tocoferolo acetato D-alfa naturale, tocoferolo succinato D-alfa naturale
                                                a base di soia;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                c) oli vegetali derivati da fitosteroli e fitosteroli esteri a base di
                                                soia;
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                d) estere di stanolo vegetale prodotto da steroli di olio vegetale a
                                                base di soia.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>7. Latte e prodotti a base di latte (incluso lattosio), tranne:</p>
                                    <ul className="ul-allergens-inside">
                                        <li>
                                            <p>
                                                a) siero di latte utilizzato per la fabbricazione di distillati
                                                alcolici, incluso l&rsquo;alcol etilico di origine agricola;
                                            </p>
                                        </li>
                                        <li>
                                            <p>b) lattiolo.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>
                                        8. Frutta a guscio, vale a dire: mandorle (Amygdalus communis L.), nocciole
                                        (Corylus avellana), noci (Juglans regia), noci di acagi&ugrave; (Anacardium
                                        occidentale), noci di pecan [Carya illinoinensis (Wangenh.) K. Koch], noci del
                                        Brasile (Bertholletia excelsa), pistacchi (Pistacia vera), noci macadamia o noci
                                        del Queensland (Macadamia ternifolia), e i loro prodotti, tranne per la frutta a
                                        guscio utilizzata per la fabbricazione di distillati alcolici, incluso
                                        l&rsquo;alcol etilico di origine agricola.
                                    </p>
                                </li>
                                <li>
                                    <p>9. Sedano</p>
                                </li>
                                <li>
                                    <p>10. Senape e prodotti a base di senape.</p>
                                </li>
                                <li>
                                    <p>11. Semi di sesamo e prodotti a base di semi di sesamo.</p>
                                </li>
                                <li>
                                    <p>
                                        12. Anidride solforosa e solfiti in concentrazioni superiori a 10 mg/kg o 10
                                        mg/litro in termini di SO2 totale da calcolarsi per i prodotti cos&igrave; come
                                        proposti pronti al consumo o ricostituiti conformemente alle istruzioni dei
                                        fabbricanti.
                                    </p>
                                </li>
                                <li>
                                    <p>13. Lupini e prodotti a base di lupini.</p>
                                </li>
                                <li>
                                    <p>14. Molluschi e prodotti a base di molluschi.</p>
                                </li>
                            </ul>
                            <p>
                                I piatti che contengo per COMPOSIZIONE degli ingredienti sostanze o prodotti che
                                provocano allergie o intolleranze sopra nominate sono indicate nelle varie ricette.
                            </p>
                            <p>
                                SI INFORMA CHE GLI ALIMENTI INDICATI POTREBBERO CONTENERE UNO O PIU&rsquo; DEI SEGUENTI
                                ALLERGENI COME INGREDIENTE O IN TRACCE DERIVANTI DAL PROCESSO PRODUTTIVO
                            </p>
                            <p>
                                Per qualsiasi informazione &egrave; possibile e consigliato di rivolgersi al personale
                                di cucina.
                            </p>
                            <div className="col-12 col-sm-12 col-md-12">
                                <Margin marginTop="32px" />
                                <GoBackLink text={this.props.t("allergens.go_home")} onClick={this.goBack} />
                                <Margin marginBottom="32px" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

Allergens.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Allergens));
