import { default as config } from "./../../firebase/config";
import { useTranslation } from "react-i18next";

import "./_version.scss";

export const Version = ({ color }) => {
    const [t, i18n] = useTranslation();
    return (
        <div className="version-container" style={{ color }}>
            {t("version")}: {process.env.REACT_APP_WEBAPP_VERSION}
        </div>
    );
};
