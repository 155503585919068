/**Packages */
import { createRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Component */
import HandlerUser from "../../components/HandlerUser";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/**Images */
import iconeHP from "../../images/icone-hp.svg";
import rightGraphic from "../../images/right-graphic.svg";
import leftGraphic from "../../images/left-graphic.svg";

/**Helpers */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import history from "../../helpers/history";
import { seo } from "../../helpers/seo";
import { getCidByState, getParameterByUrl, checkIsCanteenEnabled, getFormattedCid } from "../../helpers/utils";
import { checkString, validateEmail, validatePassword } from "../../helpers/validators";
import { error, success } from "../../helpers/toast";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import { USER_LOGGED_IN } from "../../redux/actions/userActions";
import { FROM_REGISTRATION } from "../../redux/actions/registerActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

import "./_registration.scss";

//const InputWithRef = forwardRef((props, ref) => <Input innerRef={ref} {...props} />);

class Registration extends HandlerUser {
    constructor(props) {
        super(props);

        const cid = getCidByState(props.history?.location?.state?.from?.search) || getParameterByUrl("m") || "";

        this.state = {
            ...this.state,
            name: "",
            surname: "",
            email: "",
            password: "",
            confirm_password: "",
            cid,
            receivedByUrl: cid !== "",
        };
        this.signUp = this.signUp.bind(this);
        this.formValidation = this.formValidation.bind(this);
        this.inputNameRef = createRef();
        this.inputSurnameRef = createRef();
        this.inputEmailRef = createRef();
        this.inputPasswordRef = createRef();
        this.inputConfirmPasswordRef = createRef();
        this.inputCidRef = createRef();
    }

    componentDidMount() {
        seo({
            title: SITE_TITLE + " || Registration",
            metaDescription: "",
        });

        const cid = getCidByState(history?.location?.state?.from?.search) || getParameterByUrl("m") || "";
        //const cid = getCidByState(this.props.history?.location?.state?.from?.search) || getParameterByUrl('m') || '';

        this.setState({
            cid,
            receivedByUrl: cid !== "",
        });
    }

    formValidation() {
        const { name, surname, email, password, confirm_password, cid } = this.state;
        const validClassName = "form-control";
        const errorClassName = validClassName + " error";
        if (!checkString(name)) {
            this.inputNameRef.current.className = errorClassName;
            this.inputNameRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("signup.name") }));
            return false;
        } else {
            this.inputNameRef.current.className = validClassName;
        }

        if (!checkString(surname)) {
            this.inputSurnameRef.current.className = errorClassName;
            this.inputSurnameRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("signup.surname") }));
            return false;
        } else {
            this.inputSurnameRef.current.className = validClassName;
        }

        if (!validateEmail(email)) {
            this.inputEmailRef.current.className = errorClassName;
            this.inputEmailRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("signup.email") }));
            return false;
        } else {
            this.inputEmailRef.current.className = validClassName;
        }

        //if (!validatePassword(password)) {
        if (!checkString(password)) {
            this.inputPasswordRef.current.className = errorClassName;
            this.inputPasswordRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("signup.password") }));
            return false;
        } else {
            this.inputPasswordRef.current.className = validClassName;
        }

        if (!checkString(confirm_password) || password !== confirm_password) {
            this.inputConfirmPasswordRef.current.className = errorClassName;
            this.inputConfirmPasswordRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("signup.confirm_password") }));
            return false;
        } else {
            this.inputConfirmPasswordRef.current.className = validClassName;
        }

        if (!checkString(cid) || !checkIsCanteenEnabled(getFormattedCid(cid))) {
            this.inputCidRef.current.className = errorClassName;
            this.inputCidRef?.current?.focus();
            error(this.props.t("signup.errors.no_id_canteen"));
            return false;
        } else {
            this.inputCidRef.current.className = validClassName;
        }

        return true;
    }

    async signUp(e) {
        e.preventDefault();
        const { name, surname, email, password, confirm_password, cid } = this.state;
        try {
            e.preventDefault();
            this.props.activateLoader();
            if (this.formValidation()) {
                const profile = {
                    name,
                    surname,
                    email,
                    password,
                };
                const result = await this.context.api.signUpUser(profile, cid);
                if (result) {
                    success(this.props.t("signup.success"));
                    history.pushWithCheck(INDEX_URL + PAGES.CREATE_PROFILE);
                } else {
                    error(this.props.t("signup.signup_error"));
                }
            } else {
                //error(this.props.t("signup.error_parameters"));
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    renderRegistrationForm() {
        return (
            <>
                <form>
                    {/*Nome*/}
                    <Input
                        innerRef={this.inputNameRef}
                        type="text"
                        className="form-control"
                        id="signup_input_name"
                        placeholder={this.props.t("signup.name")}
                        value={this.state.name}
                        onChange={e => this.onChange(e, "name")}
                        onBlur={this.onBlur}
                    />
                    {/*Cognome*/}
                    <Input
                        innerRef={this.inputSurnameRef}
                        type="text"
                        className="form-control"
                        id="signup_input_surname"
                        placeholder={this.props.t("signup.surname")}
                        value={this.state.surname}
                        onChange={e => this.onChange(e, "surname")}
                        onBlur={this.onBlur}
                    />
                    {/*Mail*/}
                    <Input
                        innerRef={this.inputEmailRef}
                        type="email"
                        className="form-control"
                        id="signup_input_email"
                        placeholder={this.props.t("signup.email")}
                        autocomplete="off"
                        value={this.state.email}
                        onChange={e => this.onChange(e, "email")}
                        onBlur={this.onBlur}
                    />
                    {/*Password*/}
                    <Input
                        innerRef={this.inputPasswordRef}
                        type="password"
                        className="form-control"
                        id="signup_input_password"
                        placeholder={this.props.t("signup.password")}
                        autocomplete="new-password"
                        value={this.state.password}
                        onChange={e => this.onChange(e, "password")}
                        onBlur={this.onBlur}
                    />
                    {/*Confirm Password*/}
                    <Input
                        innerRef={this.inputConfirmPasswordRef}
                        type="password"
                        className="form-control"
                        id="signup_input_confirm_password"
                        placeholder={this.props.t("signup.confirm_password")}
                        value={this.state.confirm_password}
                        autocomplete="new-password"
                        onChange={e => this.onChange(e, "confirm_password")}
                        onBlur={this.onBlur}
                    />
                    {/*Codice collegio*/}
                    <Input
                        innerRef={this.inputCidRef}
                        type="number"
                        className="form-control"
                        id="signup_input_cid"
                        disabled={this.state.receivedByUrl}
                        placeholder={this.props.t("signup.cid")}
                        value={this.state.cid}
                        onChange={e => this.onChange(e, "cid")}
                        onBlur={this.onBlur}
                    />
                    {/*
                            Privacy
                            
                            <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="customCheck1"
                                        checked={this.state.privacy}
                                        onChange={e => this.onChangeCheckBox(e, "privacy")}
                                    />
                                    <label className="custom-control-label privacy-text" htmlFor="customCheck1">
                                        {this.props.t("signup.privacy")}
                                    </label>
                                </div>
                            </div>
                        */}
                    <div className="form-group">
                        <div className="btns-area">
                            <Link to="#">{this.props.t("signup.required")}</Link>
                            <button type="submit" className="btn btn-primary" onClick={this.signUp}>
                                {this.props.t("signup.signup_btn")}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        );
    }

    render() {
        const page = (
            <>
                <Header />
                <div className="graphic left-graphic">
                    <img src={leftGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <div className="graphic right-graphic">
                    <img src={rightGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <section className="middle-content">
                    <img id="hp-icone-image" src={iconeHP} />
                    <div className="container form-area maxwidth">
                        <div className="head-area">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="default-heading">
                                        <h3>{this.props.t("signup.title")}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="register-here">
                                        <Link to={INDEX_URL + PAGES.LOGIN}>
                                            {this.props.t("signup.already_signup")}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12">{this.renderRegistrationForm()}</div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
        return super.render(page);
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        loggedIn: user => dispatch({ type: USER_LOGGED_IN, payload: user }),
        fromRegisterData: data => dispatch({ type: FROM_REGISTRATION, payload: data }),
    };
};

Registration.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Registration));
