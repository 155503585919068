import { MENU_RULES_RECEIVED, MENU_RULES_RESET } from "./../actions/menuRulesActions";

const initialState = {};

export const menuRulesReducer = function (state = initialState, action) {
    const payload = action.payload ? action.payload : null;
    switch (action.type) {
        case MENU_RULES_RECEIVED: {
            return payload;
        }
        case MENU_RULES_RESET:
            return state;
        default:
            return state;
    }
};
