/**Packages */
import { Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

/**Redux */
import { FROM_REGISTRATION } from "../../redux/actions/registerActions";

/**Helpers */
import { INDEX_URL, PAGES, LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY } from "../../helpers/constants";
import { addToLocalStorage } from "../../helpers/localStorage";
import { getIfLocationContainCanteenName } from "../../helpers/url";
import { getParameterByUrl, getFormattedCid } from "../../helpers/utils";
import history from "../../helpers/history";

const getCanteenNameByPathname = (pathname = "") => {
    return pathname.split("/")[1];
};

const getRenderComponentsWithStatus = (isLogged, props, Component, fromRegisterData) => {
    const mParameterByUrl = getParameterByUrl("m");

    if (mParameterByUrl && mParameterByUrl !== "") {
        addToLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY, getFormattedCid(mParameterByUrl));
    }

    if (isLogged) {
        return <Component {...props} />;
    } else if (mParameterByUrl !== "") {
        return (
            <Redirect
                to={{
                    pathname: `${INDEX_URL}${PAGES.SIGNUP}`,
                    state: {
                        from: props.location,
                    },
                }}
            />
        );
    } else if (getIfLocationContainCanteenName()) {
        return (
            <Redirect
                to={{
                    pathname: `${INDEX_URL}${PAGES.LOGIN}`,
                    state: {
                        from: props.location,
                        checkCanteenName: true,
                        referrer: window.location.href,
                        canteenRef: getCanteenNameByPathname(window.location.pathname),
                    },
                }}
            />
        );
    } else if (!history.checkIfAlreadyInLogin(props?.location?.pathname)) {
        return <Redirect to={{ pathname: `${INDEX_URL}${PAGES.LOGIN}`, state: { from: props.location } }} />;
    }
};

export const PrivateRoute = ({ component: Component, isLogged, ...rest }) => {
    const dispatch = useDispatch();
    const fromRegisterData = data => dispatch({ type: FROM_REGISTRATION, payload: data });

    return (
        <Route
            {...rest}
            render={props => getRenderComponentsWithStatus(isLogged, props, Component, fromRegisterData)}
        />
    );
};
