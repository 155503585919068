/**Packages */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**Helpers */
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";
import { getIfParentRatingRecipesIsEnabled, getIfChildrenRatingRecipesIsEnabled } from "../../helpers/enviroment";

/**Images */
import votaRosso from "../../images/ratings/vota-rosso.svg";
import votaYellow from "../../images/ratings/vota-giallo.svg";
import votaGreen from "../../images/ratings/vota-verde.svg";
import votaSuper from "../../images/ratings/vota-super.svg";

/**SCSS */
import "./_rate_recipe_button.scss";

export const RateRecipeButton = ({ onClick = () => {}, actions = [] }) => {
    const [t, i18n] = useTranslation();
    const [faceToUse, setFaceToUse] = useState(0);
    const [openedOtherChildrens, setOpenedOtherChildrens] = useState(false);
    const [otherChildrens, setOthersChildren] = useState([]);

    if (
        (!getIfParentRatingRecipesIsEnabled() && !getIfChildrenRatingRecipesIsEnabled()) ||
        (checkIfChildrenModeIsActivaded() && !getIfChildrenRatingRecipesIsEnabled()) ||
        (!checkIfChildrenModeIsActivaded() && !getIfParentRatingRecipesIsEnabled)
    )
        return;

    const imagesFullNames = [votaRosso, votaYellow, votaGreen, votaSuper];

    const getLittleFacesContainer = () => {
        return (
            <div className="little-faces-container noselect">
                {imagesFullNames.map((itm, idx) => {
                    return <img key={`face_full_${idx}`} className="little-face" src={itm} alt={`face ${idx}`} />;
                })}
            </div>
        );
    };

    const getParentContent = () => {
        return (
            <li key={`action-button-${0}`} className={`rate-recipe-li-container parent noselect`} onClick={openActions}>
                <span>{t("rate_recipe.parent.rate_recipe")}</span>
                {getLittleFacesContainer()}
            </li>
        );
    };

    const openActions = () => {
        const childrens = [
            <li
                style={{ transitionDelay: `${1 * 25}ms` }}
                key={`action-button-${1}`}
                className={`rate-recipe-li-container parent action noselect`}
                onClick={e => {
                    onClick(e);
                    setOpenedOtherChildrens(false);
                    setOthersChildren([]);
                }}
            >
                {getLittleFacesContainer()}
            </li>,
        ];

        let newOpenedValue = false,
            newChildrens = [];

        if (!openedOtherChildrens) {
            newOpenedValue = true;
            newChildrens = [...childrens];
        }

        setOpenedOtherChildrens(newOpenedValue);
        setOthersChildren(newChildrens);
    };

    const getChildrenContent = () => {
        return (
            <li key={`action-button-${0}`} className={`rate-recipe-li-container children`} onClick={onClick}>
                <div className="normal-faces-container">
                    <img
                        key={`face_full_${faceToUse}`}
                        className="normal-face"
                        src={imagesFullNames[faceToUse]}
                        alt={`face full ${faceToUse}`}
                    />
                </div>
            </li>
        );
    };

    //const isChildren = checkIfChildrenModeIsActivaded() && getIfChildrenRatingRecipesIsEnabled(),
    const isAnimationActivate = true,
        //additionalCssClass = isChildren ? "children" : "parent",
        //layoutContent = isAnimationActivate ? getChildrenContent() : getParentContent();
        layoutContent = getChildrenContent();

    const facesInterval = () => {
        return setInterval(() => {
            setFaceToUse(faceToUse => (faceToUse < 3 ? faceToUse + 1 : 0));
        }, 2000);
    };

    useEffect(() => {
        if (isAnimationActivate) {
            const intervalId = facesInterval();
            return () => clearInterval(intervalId);
        }
    }, [isAnimationActivate]);

    /**
        <ul className={`rate-recipe-ul-container noselect ${additionalCssClass}`}>
     * 
     */
    return (
        <ul className={`rate-recipe-ul-container noselect`}>
            {layoutContent}
            {openedOtherChildrens && otherChildrens}
        </ul>
    );
};
