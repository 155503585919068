const API_URL = "https://api.mysodexo.app";
//const API_URL = 'http://localhost:5000/mysodexo-pwa/europe-west1';
const IMG_URL = "https://cdn.mysodexo.app";

const UA_ANALYTICS_ID = "UA-82808487-2";
const ANALYTICS_ID = "G-WP3P0XH009";
const ANALYTICS_VERSION = "new";
const GTM_ID = "GTM-5NKSG2V";

const config = {
    apiKey: "AIzaSyAmJYPAm3ZxE0YeVJI6msXomph6v1VjWjA",
    authDomain: "mysodexo-pwa.firebaseapp.com",
    databaseURL: "https://mysodexo-pwa.firebaseio.com",
    projectId: "mysodexo-pwa",
    storageBucket: "mysodexo-pwa.appspot.com",
    appId: "1:656016618189:web:5380445abd30c4a9",
    messagingSenderId: "656016618189",
};

export default {
    API_URL,
    IMG_URL,
    ANALYTICS_ID,
    ANALYTICS_VERSION,
    config,
};
