/**Packages */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

/**Helpers */
import { getAndOpenStaticPageByUrl, getStaticPageByUrl } from "../../helpers/url";
import { getCurrentLanguage } from "../../helpers/utils";
import { STATIC_PAGES } from "../../helpers/constants";

/**Images */
import footerLogo from "../../images/footer-logo.png";

/**SCSS */
import "./_footer.scss";

const Footer = props => {
    const { t, i18n } = useTranslation();
    const canteen = useSelector(state => state.canteen);

    /*
    #Old method 
    const openPrivacyUrl = e => {
        e.preventDefault();
        const cid = canteen && canteen.canteen_id ? canteen.canteen_id : "";
        getAndOpenStaticPageByUrl(cid, getCurrentLanguage(), STATIC_PAGES.PRIVACY);
    };
    */

    const getCid = () => {
        return canteen && canteen.canteen_id ? canteen.canteen_id : "";
    };

    const getLink = () => {
        return (
            <a
                href={getStaticPageByUrl(getCid(), getCurrentLanguage(), STATIC_PAGES.PRIVACY)}
                target="_blank"
                rel="noreferrer"
            >
                {t("footer.privacy")}
            </a>
        );
    };

    return (
        <>
            <footer className="footer-area img-cover-area" style={{ backgroundImage: "url(images/bg03.jpg)" }}>
                <div className="container">
                    <div className="footer-logo">
                        <figure className="small-logo">
                            <img src={footerLogo} alt="..." />
                        </figure>
                    </div>
                    <div className="copyright-container">
                        <p>
                            {process.env.REACT_APP_FOOTER} {getLink()}
                        </p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
