/**Packages */
import { isEmpty } from "lodash";

/**Helpers */
import { YEARS_UNION_ENUM, DEFAULT_DIET, DEFAULT_OCCASION, DEFAULT_YEAR } from "./constants";
import {
    getDietNameFormatted,
    getYearByDietName,
    convertDietProfileIntoDietMenu,
    getPositionInArrOfValue,
} from "./utils";
import { setAttributeToBody, removeAttributeFromBody } from "./body";
import { getMenuRules, getCurrentCanteen } from "./state";

/**
 *
 * @param {*} menuItem - item to check properties
 * @param {*} menuRulesKey - item to find in menuRules - to filter
 * @param {*} menuRules - menu rules
 * @returns true if menuItem is to hide, otherwise false
 */
const isMenuItemHidden = (menuItem = null, menuRulesKey = "", menuRules = getMenuRules()) => {
    let founded = false;

    if (!menuItem) return founded;

    if (menuRules && menuRules.length > 0) {
        for (let idx = 0; idx < menuRules.length; idx++) {
            const filter = menuRules[idx];
            if (!founded) {
                //Se è già nascosta da un altro filtro ignoro
                if (filter[menuRulesKey]) {
                    const fr = new RegExp(".*" + filter[menuRulesKey] + ".*", "i");
                    let nameMatch = false;
                    for (let n in menuItem.name) {
                        const tName = menuItem.name[n];
                        if (fr.test(tName)) {
                            nameMatch = true;
                        }
                    }

                    founded = nameMatch;
                }
            }
        }
    }

    return founded;
};
/**
 *
 * @param {*} itm - itm to parse
 * @returns Itm parsed in number if it, is a string and a valid number, otherwise return itm
 */
const parseToNumberIfString = itm => {
    const value = typeof itm === "string" ? parseFloat(itm) : itm;
    return value !== undefined && !Number.isNaN(value) ? value : 0;
};
/**
 *
 * @param {*} occasion occasion data
 * @returns Occasion formatted correctly (in order to be used in app)
 */
const getOccasionFormatted = occasion => {
    return {
        occasion_id: occasion.id,
        occasion_name: occasion.name,
    };
};
/**
 *
 * @param {*} occasion occasion data
 * @param {*} diets diets data
 * @returns Diets formatted correctly (in order to be used in app)
 */
const getDietsFormatted = (occasion, diets) => {
    return diets
        .filter(diet => {
            return !isMenuItemHidden(diet, "diet");
        })
        .map(diet => {
            return {
                occasion_id: occasion.id,
                occasion_name: occasion.name,
                diet_id: diet.id,
                diet_name: diet.name,
                year: getYearByDietName(diet).toUpperCase(),
            };
        });
};
/**
 *
 * @param {*} occasion occasion data
 * @param {*} diet diet data
 * @param {*} courses courses data
 * @returns Courses formatted correctly (in order to be used in app)
 */
const getCoursesFormatted = (occasion, diet, courses) => {
    return courses
        .filter(course => {
            //course = component0
            return !isMenuItemHidden(course, "component");
        })
        .map(course => {
            return {
                occasion_id: occasion.id,
                occasion_name: occasion.name,
                diet_id: diet.id,
                diet_name: diet.name,
                course_id: course.id,
                course_name: course.name,
            };
        });
};
/**
 *
 * @param {*} occasion occasion data
 * @param {*} diet diet data
 * @param {*} course course data
 * @param {*} recipes recipes data
 * @returns Recipes formatted correctly (in order to be used in app)
 */
const getRecipesFormatted = (occasion, diet, course, recipes) => {
    return recipes
        .filter(recipe => {
            return !isMenuItemHidden(recipe, "recipe");
        })
        .map(recipe => {
            return {
                occasion_id: occasion.id,
                occasion_name: occasion.name,
                diet_id: diet.id,
                diet_name: diet.name,
                course_id: course.id,
                course_name: course.name,
                ...recipe,
            };
        });
};
/**
 *
 * @param {*} occasion occasion data
 * @param {*} diets diets data
 * @param {*} course course data
 * @returns Recommendations formatted correctly (in order to be used in app)
 */
const getRecommendationsFormatted = (occasion, diet, course) => {
    return {
        occasion_id: occasion.id,
        occasion_name: occasion.name,
        diet_id: diet.id,
        diet_name: diet.name,
        course_id: course.id,
        course_name: course.name,
        recipes: course.recipes,
    };
};
/**
 *
 * @param {*} menu menu data
 * @returns True if menu is valid, otherwise false
 */
export const isValidMenu = menu => {
    /**
     * Check if menu has expected value or not
     */
    return menu && menu.occasions && menu.occasions.length > 0;
};
/**
 *
 * @returns The menu bse object (without data, all empty)
 */
export const getBaseMenuObjFormatted = () => {
    return {
        occasions: [],
        diets: [],
        courses: [],
        recommendations: [],
        recipes: [],
        dietsFormatted: {},
    };
};
/**
 *
 * @param {*} diets - diets to be merged
 * @returns Merged diets (deleted duplication)
 */
export const mergeDiets = diets => {
    const dietsToReturn = [];
    for (let i = 0; i < diets.length; i++) {
        const diet = diets[i];
        const ids =
            dietsToReturn.length > 0
                ? dietsToReturn.map(itm => {
                      return itm.diet_id;
                  })
                : [];
        if (!ids.includes(diet.diet_id)) {
            dietsToReturn.push(diet);
        }
    }

    //TODO: sort;
    return dietsToReturn;
};
/**
 *
 * @param {*} years years to merge
 * @returns Years merged (deleted duplication)
 */
export const mergeYears = years => {
    const yearsToReturn = [];
    for (let i = 0; i < years.length; i++) {
        const year = years[i];
        const ids =
            yearsToReturn.length > 0
                ? yearsToReturn.map(itm => {
                      return itm;
                  })
                : [];
        if (!ids.includes(year)) {
            yearsToReturn.push(year);
        }
    }

    //TODO: sort;
    return yearsToReturn;
};
/**
 *
 * @param {*} menu menù data
 * @returns Menù formatted correctly (in order to be used in app)
 */
export const getFormattedMenu = menu => {
    if (!menu || menu === {} || !Object.prototype.hasOwnProperty.call(menu, "occasions")) return;
    let menuFormatted = getBaseMenuObjFormatted();
    const occasions = menu.occasions;
    for (let occasion of occasions) {
        if (!isMenuItemHidden(occasion, "occasion")) {
            menuFormatted.occasions.push(getOccasionFormatted(occasion));

            const diets = occasion.diets;
            menuFormatted.diets.push(...getDietsFormatted(occasion, diets));

            for (let diet of diets) {
                const courses = diet.courses.filter(course => {
                    return !course.isSoMeal;
                });

                /**
                 * TODO: andrà modificato poiché sulla dieta avrà più senso avercelo diverso;
                 */
                const recommendations = diet.courses.find(course => {
                    return course.isSoMeal;
                });

                if (recommendations) {
                    menuFormatted.recommendations.push(getRecommendationsFormatted(occasion, diet, recommendations));
                }

                menuFormatted.courses.push(...getCoursesFormatted(occasion, diet, courses));

                for (let course of courses) {
                    const recipes = course.recipes;
                    menuFormatted.recipes.push(...getRecipesFormatted(occasion, diet, course, recipes));
                }
            }
        }
    }

    menuFormatted.dietsFormatted = getDietsObjectFormatted(menuFormatted.diets);

    return menuFormatted;
};
/**
 *
 * @param {*} diets diets to be conctenated
 * @returns Diets divided into years
 */
export const getDietsObjectFormatted = diets => {
    const years = Object.keys(YEARS_UNION_ENUM);

    const fullDietObj = {};

    years.forEach(year => {
        const filteredDiets = mergeDiets(
            diets.filter(diet => {
                return diet.year === year || YEARS_UNION_ENUM[year?.toUpperCase()].includes(diet.year?.toUpperCase());
            }),
        );
        if (filteredDiets?.length > 0) {
            fullDietObj[year?.toUpperCase()] = filteredDiets;
        } else {
            delete fullDietObj[year?.toUpperCase()];
        }
    });

    return fullDietObj;
};
/**
 *
 * @param {*} year
 * @returns Related key year, otherwise null
 */
export const getCorrectObjFromYear = year => {
    if (!year) return null;

    let found = false,
        key = null;

    const keys = Object.keys(YEARS_UNION_ENUM);
    for (let i = 0; i < keys.length && !found; i++) {
        const item = YEARS_UNION_ENUM[keys[i]];
        if (item.includes(year?.toUpperCase())) {
            key = keys[i];
            found = true;
        }
    }

    return key ? key : null;
};
/**
 *
 * @param {*} recipes recipes data
 * @param {*} occasionId occasion id
 * @param {*} dietId diet id
 * @returns recipes filtered by occasion and diet
 */
export const getRecipesFromOccasionAndDietId = (recipes = [], occasionId = null, dietId = null) => {
    if (!recipes || !Array.isArray(recipes) || !recipes.length > 0) return [];

    return recipes.filter(recipe => {
        return recipe.occasion_id === occasionId && recipe.diet_id === dietId;
    });
};
/**
 *
 * @param {*} recipes recipes data
 * @param {*} occasionId occasion id
 * @param {*} dietId diet id
 * @returns recipes filtered by occasion and diet
 */
//Components not enabled to be rated
const RATE_COMPONENTS_DISABLED = [202, "202"];
//Occasions not enabled to be rated
const RATE_OCCASIONS_DISABLED = [-1, "-1"];
//Diets not enabled to be rated
const RATE_DIETS_DISABLED = [-1, "-1"];
//Occasion enabled to be rated
const RATE_OCCASIONS_ENABLED = [11, "11", 4, "4"];
export const getRecipesFromOccasionAndDietIdForRates = (recipes = [], occasionId = null, dietId = null) => {
    if (!recipes || !Array.isArray(recipes) || !recipes.length > 0) return [];

    return recipes.filter(recipe => {
        const { occasion_id = null, diet_id = null, course_id = null } = recipe;

        return (
            occasion_id &&
            //TODO: understand how to configure this setting
            //Andrea on 20/06/2023 decided to not do this
            //On 27/06/2023 dediced definitly to delete this check
            //RATE_OCCASIONS_ENABLED.includes(occasion_id) &&
            !RATE_OCCASIONS_DISABLED.includes(occasionId) &&
            occasion_id === occasionId &&
            !RATE_DIETS_DISABLED.includes(dietId) &&
            diet_id === dietId &&
            course_id &&
            !RATE_COMPONENTS_DISABLED.includes(course_id)
        );
    });
};
/**
 *
 * @param {*} recipes recipes data
 * @returns Get nutritional data added together
 */
export const getNutrinionalData = recipes => {
    /**Inizializzazione */
    const data = {
        kcal: 0,
        carbs: 0,
        proteins: 0,
        fat: 0,
    };

    /**Sommo tutto */
    recipes.forEach(recipe => {
        data.kcal += parseToNumberIfString(recipe.kcal);
        data.carbs += parseToNumberIfString(recipe.carbs);
        //We are receiving proteins or protein
        data.proteins += parseToNumberIfString(recipe.proteins || recipe.protein);
        data.fat += parseToNumberIfString(recipe.fat);
    });

    /**ROUND per eccesso */
    data.kcal = Math.round(data.kcal);
    data.carbs = Math.round(data.carbs);
    data.proteins = Math.round(data.proteins);
    data.fat = Math.round(data.fat);

    return data;
};

/**
 *
 * @param {*} canteenData
 * @returns {boolean} true if nutritional data are enabled, otherwise false
 */
export const getIfNutritionalDataAreEnabled = (canteenData = null) => {
    if (!canteenData) {
        canteenData = getCurrentCanteen();
    }

    return (
        canteenData &&
        (!canteenData.hasOwnProperty("nutritionalDataEnabled") ||
            (canteenData.hasOwnProperty("nutritionalDataEnabled") && canteenData.nutritionalDataEnabled))
    );
};

/**
 * NUTRiTIONAL DATA HTML
 */
/**
 *
 * @param {*} nutritionalData nutrinional data
 * @param {*} t method to get translated string
 * @returns The html related to nutrinional information
 */
export const getNutritionalDataHtml = (nutritionalData, t) => {
    return (
        <ul className="number-list">
            <li key="KCAL">
                <div className="circle-area blue active d-flex flex-column justify-content-center">
                    {nutritionalData.kcal}
                </div>
                <span className="name">{t("menu.kcal")}</span>
            </li>
            <li key="HC">
                <div className="circle-area yellow d-flex flex-column justify-content-center">
                    {nutritionalData.carbs} <span>{t("menu.carbs_unit_measure")}</span>
                </div>
                <span className="name">{t("menu.carbs_prefix")}</span>
            </li>
            <li key="LIP">
                <div className="circle-area pink d-flex flex-column justify-content-center">
                    {nutritionalData.fat} <span>{t("menu.fat_unit_measure")}</span>
                </div>
                <span className="name">{t("menu.fat_prefix")}</span>
            </li>
            <li key="PROT">
                <div className="circle-area orange d-flex flex-column justify-content-center">
                    {nutritionalData.proteins} <span>{t("menu.proteins_unit_measure")}</span>
                </div>
                <span className="name">{t("menu.proteins_prefix")}</span>
            </li>
        </ul>
    );
};
/**
 *
 * @param {*} info recipe data
 * @returns Ingredients mapped, otherwise empty array
 */
export const getIngredients = info => {
    if (!info.Ingredients) return null;

    return info.Ingredients.map((itm, idx) => {
        return {
            id: itm.Id
                ? itm.Id
                : itm.Name && itm.Name[process.env.REACT_APP_DEFAULT_LNG]
                ? itm.Name[process.env.REACT_APP_DEFAULT_LNG].toLowerCase().trim()
                : "ingredient-" + idx,
            name: itm.Name,
            unitMeasure: itm.Um ? itm.Um : "",
        };
    });
};
/**
 *
 * @param {*} info recipe data
 * @returns Allergens mapped, otherwise empty array
 */
export const getAllergens = info => {
    if (!info.Allergens) return null;

    return info.Allergens.map((itm, idx) => {
        return {
            id: itm.Id
                ? itm.Id
                : itm.Name && itm.Name[process.env.REACT_APP_DEFAULT_LNG]
                ? itm.Name[process.env.REACT_APP_DEFAULT_LNG].toLowerCase().trim()
                : "allergen-" + idx,
            name: itm.Name,
        };
    });
};
/**
 *
 * @param {*} recipes - all recipes favorites
 * @param {*} recipeId - recipe id to check
 * @returns True if recipeId is included into recipes, otherwise false
 */
export const getIfFavorite = (recipes, recipeId) => {
    if (!recipes || recipes.length === 0 || !recipeId || recipeId === "") return false;

    const ids =
        recipes.length > 0
            ? recipes.map(recipe => {
                  return recipe.id;
              })
            : [];
    return ids.includes(recipeId);
};
/**
 *
 * @param {*} recipes - all recipes favorites
 * @param {*} recipeId - recipe id to check
 * @returns Correct className to use (if favorite or not)
 */
export const getCssFavoriteClassName = (recipes, recipeId) => {
    let className = "icon-blankstar";
    if (getIfFavorite(recipes, recipeId)) {
        className = "icon-solidstar";
    }

    return className;
};
/**
 *
 * @param {*} diets array of diets
 * @param {*} oldDiet diet to find in new Year
 * @returns return diet if in newYear id founded, otherwise the first diet
 */
export const checkDietExistenceInNewYear = (diets, oldDiet) => {
    if (!oldDiet || oldDiet === DEFAULT_DIET || isEmpty(oldDiet)) return null;

    const oldDietName = getDietNameFormatted(oldDiet);
    const dietFounded = diets.find(diet => {
        let currentDietName = getDietNameFormatted(diet);

        return oldDietName === currentDietName;
    });

    return dietFounded ? dietFounded : diets[0];
};
/**
 *
 * @param {*} courses_ids array od courses ids
 * @param {*} recipes array of recipes
 * @param {*} courses array of courses
 * @returns recipes splitted in all courses_ids passed to the method, otherwise array empty
 */
export const getRecipesByComponentsIds = (courses_ids = [], recipes = [], courses = []) => {
    if (courses_ids.length === 0 || recipes.length === 0 || courses.length === 0) return {};

    return courses_ids.reduce((fullObj, course_id) => {
        const recipesFiltered = recipes.filter(recipe => {
            return recipe.course_id === course_id;
        });
        return {
            ...fullObj,
            [course_id]: {
                ...courses.find(course => {
                    return course.id === course_id;
                }),
                recipes: recipesFiltered,
            },
        };
    }, {});
};
/**
 *
 * @param {*} selectedDiet - diet selected
 * @param {*} occasions - occasions from menù
 * @param {*} recipes - recipes
 * @returns The occasions with some recipes, otherwise the first, otherwise occasions.length <= 0 the DEFAULT
 */
const getFirstOccasionWithRecipes = (selectedDiet = DEFAULT_DIET, occasions = [], recipes = []) => {
    if (occasions?.length > 0) {
        const diet_id = selectedDiet.diet_id;
        const foundedOccasion = occasions.find((occasion, occasionArrayIdx) => {
            const occasion_id = occasion.occasion_id;
            return getRecipesFromOccasionAndDietId(recipes, occasion_id, diet_id).length > 0;
        });
        return foundedOccasion ? foundedOccasion : occasions[0];
    } else {
        return DEFAULT_OCCASION;
    }
};
/**
 *
 * @param {*} selectedDiet - diet selected
 * @param {*} occasions - occasions from menù
 * @param {*} recipes - recipes
 * @returns The occasions with some recipes, otherwise occasions.length <= 0 the DEFAULT
 */
const getOccasionsWithRecipes = (selectedDiet = DEFAULT_DIET, occasions = [], recipes = []) => {
    if (occasions?.length > 0) {
        const diet_id = selectedDiet.diet_id;
        return occasions.filter((occasion, occasionArrayIdx) => {
            const occasion_id = occasion.occasion_id;
            return getRecipesFromOccasionAndDietId(recipes, occasion_id, diet_id).length > 0;
        });
    } else {
        return [DEFAULT_OCCASION];
    }
};
/**
 *
 * @param {*} selectedDiet - diet selected
 * @param {*} occasions - all occasion for date selected
 * @param {*} diets - all diets downloaded
 * @returns all valid occasions, otherwise DEFAULT_OCCASION
 */
const getAllValidOccasions = (selectedDiet = DEFAULT_DIET, occasions = [], diets = []) => {
    if (occasions?.length > 0) {
        const diet_id = selectedDiet.diet_id;
        const dietWithOccasions = diets.map(diet => {
            return {
                diet_id: diet.diet_id,
                occasion_id: diet.occasion_id,
            };
        });

        return occasions.filter(occasion => {
            const occasion_id = occasion.occasion_id;
            let founded = 0;

            dietWithOccasions.map(dietWithOccasion => {
                if (diet_id === dietWithOccasion.diet_id) {
                    founded++;
                }
            });

            return founded > 0;
        });
    } else {
        return [DEFAULT_OCCASION];
    }
};
export const getValidAndWithRecipesOccasions = (menu, selectedDiet, diets) => {
    menu = menu ? menu : getBaseMenuObjFormatted();

    const validOccasions = getAllValidOccasions(selectedDiet, menu.occasions, diets);
    const occasions = getOccasionsWithRecipes(selectedDiet, validOccasions, menu.recipes);

    return occasions;
};
const getIds = (items = [], prop = "id") => {
    if (!items || items.length <= 0) return [];

    return items.map(itm => {
        return itm[prop];
    });
};
/**
 *
 * @param {*} state - state from the page
 * @param {*} props - props from the application (including redux state)
 * @param {*} menu - menu data
 * @param {*} clickedSwiperDate - flag that indicates if swiper date was clicked or not
 * @param {*} getNutrinionalDataByOccasion - method to get the nutrional data
 * @returns The object witha ll props to initialize the homepage
 */
export const getFullMenuDataFormatted = (state, props, menu, clickedSwiperDate, getNutrinionalDataByOccasion) => {
    const selectedMenu = menu || getBaseMenuObjFormatted();

    const years = isValidMenu(selectedMenu)
        ? Object.keys(selectedMenu.dietsFormatted)
        : props?.user?.selectedProfile?.diet
        ? [getCorrectObjFromYear(getYearByDietName(convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)))]
        : [];

    const selectedYear = !clickedSwiperDate
        ? props?.user?.selectedProfile?.diet &&
          years.includes(
              getCorrectObjFromYear(
                  getYearByDietName(convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)).toUpperCase(),
              ),
          )
            ? getCorrectObjFromYear(
                  getYearByDietName(convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)).toUpperCase(),
              )
            : isValidMenu(selectedMenu)
            ? Object.keys(selectedMenu?.dietsFormatted)[0].toUpperCase()
            : DEFAULT_YEAR
        : state?.selectedYear && Object.keys(selectedMenu?.dietsFormatted).includes(state?.selectedYear)
        ? state?.selectedYear
        : props?.user?.selectedProfile?.diet
        ? getCorrectObjFromYear(
              getYearByDietName(convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)).toUpperCase(),
          )
        : selectedMenu?.dietsFormatted && Object.keys(selectedMenu?.dietsFormatted).length > 0
        ? Object.keys(selectedMenu?.dietsFormatted)[0].toUpperCase()
        : DEFAULT_YEAR;

    const yearKey = getCorrectObjFromYear(selectedYear);

    const selectedDiet = !clickedSwiperDate
        ? props?.user?.selectedProfile?.diet &&
          isValidMenu(selectedMenu) &&
          getIds(selectedMenu?.dietsFormatted[yearKey], "diet_id").includes(props?.user?.selectedProfile?.diet.id)
            ? convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)
            : isValidMenu(selectedMenu)
            ? selectedMenu?.dietsFormatted[yearKey][0]
            : DEFAULT_DIET
        : state.selectedDiet &&
          getIds(selectedMenu?.dietsFormatted[yearKey], "diet_id").includes(state?.selectedDiet?.diet_id)
        ? state.selectedDiet
        : props?.user?.selectedProfile?.diet
        ? convertDietProfileIntoDietMenu(props?.user?.selectedProfile?.diet)
        : selectedMenu?.dietsFormatted &&
          selectedMenu?.dietsFormatted[yearKey] &&
          selectedMenu?.dietsFormatted[yearKey].length > 0
        ? selectedMenu?.dietsFormatted[yearKey][0]
        : DEFAULT_DIET;
    const diets =
        selectedDiet && isValidMenu(selectedMenu)
            ? selectedMenu?.dietsFormatted[yearKey]
            : selectedDiet
            ? [selectedDiet]
            : isValidMenu(selectedMenu)
            ? selectedMenu?.diets
            : [];

    const occasions = getValidAndWithRecipesOccasions(selectedMenu, selectedDiet, diets);

    const selectedOccasion = !clickedSwiperDate
        ? isValidMenu(selectedMenu)
            ? getFirstOccasionWithRecipes(selectedDiet, occasions, selectedMenu?.recipes)
            : DEFAULT_OCCASION
        : state?.selectedOccasion?.occasion_id === -1
        ? isValidMenu(selectedMenu)
            ? getFirstOccasionWithRecipes(selectedDiet, occasions, selectedMenu?.recipes)
            : DEFAULT_OCCASION
        : state?.selectedOccasion;

    const filteredRecipes = getRecipesFromOccasionAndDietId(
        selectedMenu?.recipes ? selectedMenu?.recipes : [],
        selectedOccasion?.occasion_id,
        selectedDiet?.diet_id,
    );

    const nutritionalData = isValidMenu(selectedMenu) ? getNutrinionalData(filteredRecipes) : getNutrinionalData([]);

    return {
        selectedMenu,
        selectedOccasion,
        selectedYear,
        selectedDiet,
        occasions,
        diets,
        years,
        nutritionalData,
    };
};
/**
 *
 * @param {*} token
 * @param {*} canteen_id
 * @param {*} monthN
 * @param {*} yearN
 * @param {*} lang
 * @param {*} downloadPdfWithAllOccasion
 * @param {*} occasions
 * @param {*} selectedOccasion
 * @param {*} selectedDiet
 * @returns
 */
export const getDataForDownloadPdf = (
    token,
    canteen_id,
    monthN,
    yearN,
    lang,
    downloadPdfWithAllOccasion = false,
    occasions,
    selectedOccasion,
    selectedDiet,
) => {
    const dataSelected = {};
    if (downloadPdfWithAllOccasion) {
        //SoHappy
        dataSelected.occasions =
            occasions && occasions.length > 0
                ? occasions.map(occasion => {
                      return {
                          id: occasion.occasion_id,
                          name: occasion.occasion_name,
                      };
                  })
                : null;
        dataSelected.diet = selectedDiet;
    } else {
        //SoMeal
        dataSelected.idOccasion =
            selectedOccasion.occasion_id === -1 ? selectedDiet.occasion_id : selectedOccasion.occasion_id;
        dataSelected.diet = selectedDiet;
    }

    return {
        token,
        canteen_id,
        monthN,
        yearN,
        lang,
        dataSelected,
    };
};
/**
 *
 * @param {*} menuData
 * @param {*} occasionId
 * @param {*} dietId
 * @param {*} courseId
 * @param {*} recipeId
 * @returns
 */
export const getMenuDataRecipeRef = (
    menuData = null,
    occasionId = null,
    dietId = null,
    courseId = null,
    recipeId = null,
) => {
    const out = {
        success: false,
        data: {
            occasionArrIndex: -1,
            dietArrIndex: -1,
            courseArrIndex: -1,
            recipeArrIndex: -1,
        },
        message: "",
    };

    if (!(menuData || occasionId || dietId || courseId || recipeId)) {
        out.message = "No data passed";
        return out;
    }

    try {
        if (menuData && menuData.occasions) {
            const occasionArrIndex = getPositionInArrOfValue(menuData.occasions, "id", occasionId);

            if (
                occasionArrIndex >= 0 &&
                menuData.occasions[occasionArrIndex].diets &&
                Array.isArray(menuData.occasions[occasionArrIndex].diets)
            ) {
                //Saving occasion id
                out.data.occasionArrIndex = occasionArrIndex;

                const dietArrIndex = getPositionInArrOfValue(menuData.occasions[occasionArrIndex].diets, "id", dietId);

                if (
                    dietArrIndex >= 0 &&
                    menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses &&
                    Array.isArray(menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses)
                ) {
                    //Saving diet id
                    out.data.dietArrIndex = dietArrIndex;

                    const courseArrIndex = getPositionInArrOfValue(
                        menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses,
                        "id",
                        courseId,
                    );

                    if (
                        courseArrIndex >= 0 &&
                        menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[courseArrIndex].recipes &&
                        Array.isArray(
                            menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[courseArrIndex].recipes,
                        )
                    ) {
                        //Saving course id
                        out.data.courseArrIndex = courseArrIndex;

                        const recipeArrIndex = getPositionInArrOfValue(
                            menuData.occasions[occasionArrIndex].diets[dietArrIndex].courses[courseArrIndex].recipes,
                            "id",
                            recipeId,
                        );

                        if (recipeArrIndex >= 0) {
                            out.data.recipeArrIndex = recipeArrIndex;
                            out.success = true;
                        } else {
                            out.message = "invalid recipe id";
                        }
                    } else {
                        out.message = "invalid course id";
                    }
                } else {
                    out.message = "invalid diet id";
                }
            } else {
                out.message = "invalid occasion id";
            }
        }
    } catch (e) {
        console.log("error in getMenuDataRecipeRef");
        console.log(e);
    } finally {
        return out;
    }
};
/**
 *
 * @param {string | number} occasionId - occasion id
 * @returns the method add to the body attributes: "occasion" with given occasionId, otherwise remove all the menus related attributes
 */
export const setOccasionToBody = (occasionId = null) => {
    if (!occasionId || occasionId == "" || occasionId === -1 || occasionId === "-1") {
        removeAttributeFromBody("occasion");
        removeAttributeFromBody("diet");
        return;
    }

    setAttributeToBody("occasion", occasionId);
};
/**
 *
 * @param {string | number} dietId - diet id
 * @returns the method add to the body attributes: "diet" with given dietId, otherwise remove all the menus related attributes
 */
export const setDietToBody = (dietId = null) => {
    if (!dietId || dietId == "" || dietId === -1 || dietId === "-1") {
        removeAttributeFromBody("diet");
        removeAttributeFromBody("occasion");
        return;
    }

    if (dietId === -1 || dietId === "-1") {
        removeAttributeFromBody("diet");
        removeAttributeFromBody("occasion");
        return;
    }

    setAttributeToBody("diet", dietId);
};
