import React from "react";
import $ from "jquery";
import * as moment from "moment";
import { withTranslation } from "react-i18next";

/**UTILS */
import { MENU_DATE_FORMAT } from "../../helpers/constants";
import { getFirsXDaysValid, datesEqual } from "../../helpers/utils";

/**SCSS */
import "./_dateslider.scss";

class DateSlider extends React.Component {
    constructor(props) {
        super(props);

        const startingDate = new Date(props.startingDate);

        this.state = {
            dates: getFirsXDaysValid(5, startingDate, 0),
            currentDate: startingDate,
            clickCount: 0,
        };
    }

    componentDidMount() {}

    next() {
        this.setState({
            dates: getFirsXDaysValid(5, new Date(this.state.dates[4].fullDate), 1, false),
        });
        $(".date-li").removeClass("active");
    }

    prev() {
        this.setState({
            dates: getFirsXDaysValid(5, new Date(this.state.dates[0].fullDate), -1, true),
        });
        $(".date-li").removeClass("active");
    }

    handleDateClick(e, id, date) {
        $(".date-li").removeClass("active");
        $("#li-" + id).addClass("active");
        this.props.onDateSelected(e, moment(date.fullDate).format(MENU_DATE_FORMAT));
    }

    render() {
        const { dates, currentDate } = this.state;

        dates.sort(function (a, b) {
            return new Date(a.fullDate) - new Date(b.fullDate);
        });

        return (
            <React.Fragment>
                <div className="menu-slider">
                    <ul className="date-list">
                        {Object.keys(dates).map(index => {
                            const date = dates[index];
                            let className = datesEqual(date.fullDate, currentDate) ? "date-li active" : "date-li";
                            className = date && date.isWeekend ? className + " disabled" : className;
                            className += " clickable";
                            return (
                                <React.Fragment key={index}>
                                    <li
                                        id={"li-" + index}
                                        key={index}
                                        className={className}
                                        onClick={e => this.handleDateClick(e, index, date)}
                                    >
                                        <div className="extra-small-text">
                                            <div className="text">
                                                {this.props.t("date_slider.days." + date.day.toLowerCase())}
                                            </div>
                                        </div>
                                        <div className="number-counts d-flex align-items-center justify-content-center">
                                            <span>{date.date}</span>
                                        </div>
                                        <div className="extra-small-text">
                                            <div className="text">
                                                {this.props.t("date_slider.months." + date.month.toLowerCase())}
                                            </div>
                                        </div>
                                    </li>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                    <div className="buttons-area ">
                        <button type="button" role="presentation" className="arrow-prev" onClick={() => this.prev()}>
                            <i className="icon-small-arrow-left" />
                        </button>
                        <button type="button" role="presentation" className="arrow-next" onClick={() => this.next()}>
                            <i className="icon-small-arrow-right" />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(DateSlider);
