/**Packages */
import { useState, useEffect } from "react";

/**Images */
import voteRosso from "../../images/ratings/vota-rosso.svg";
import voteYellow from "../../images/ratings/vota-giallo.svg";
import voteGreen from "../../images/ratings/vota-verde.svg";
import voteSuper from "../../images/ratings/vota-super.svg";

/**SCSS */
import "./_rate_recipe.scss";

const rateStatuses = [
    {
        id: 0,
        text: "bad",
        value: 1,
        image: voteRosso,
    },
    {
        id: 1,
        text: "semi-bad",
        value: 2,
        image: voteYellow,
    },
    {
        id: 2,
        text: "good",
        value: 3,
        image: voteGreen,
    },
    {
        id: 3,
        text: "super-good",
        value: 4,
        image: voteSuper,
    },
];

const getCssClassFromStatus = (status, currentVote) => {
    let statusActive = false,
        baseCssClass = "rate-recipe-img";

    if (status == currentVote) statusActive = true;

    return statusActive ? baseCssClass : `${baseCssClass} deactive`;
};

{
    /**
    Status are four
    0 = faccia rossa,
    1 = faccia gialla,
    2 - faccia verdina,
    3 - faccia super verde
 */
}

const getStatusFormatted = (recipeId, internalId, status, currentVote, onRateRecipe) => {
    const internalOnClick = e => {
        onRateRecipe(e, recipeId, status);
    };

    return (
        <img
            key={`rate-${recipeId}-${status.id}`}
            onClick={internalOnClick}
            className={getCssClassFromStatus(rateStatuses[status.id].value, currentVote)}
            src={rateStatuses[status.id].image}
        />
    );
};

const getStatusesFormatted = (recipeId, internalId, currentVote, onRateRecipe) => {
    if (!rateStatuses || rateStatuses.length === 0) return null;

    return rateStatuses.map(rateStatus => {
        return getStatusFormatted(recipeId, internalId, rateStatus, currentVote, onRateRecipe);
    });
};

export const RateRecipe = ({
    recipeId = null,
    internalId = null,
    vote = -1,
    onRateRecipe = () => {},
    reset = false,
}) => {
    const [currentVote, setCurrentVote] = useState(vote);

    const key = `recipe-${recipeId}-rate`;

    const onRateRecipeInternal = (e, recipeId, status) => {
        e.preventDefault();

        setCurrentVote(status.value);
        onRateRecipe(e, recipeId, status);
    };

    useEffect(() => {
        if (reset) {
            setCurrentVote(-1);
        }
    }, [reset]);

    return (
        <div key={key} className="rate-recipe-faces-container">
            {getStatusesFormatted(recipeId, internalId, currentVote, onRateRecipeInternal)}
        </div>
    );
};
