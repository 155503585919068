/**Helpers */
import { getAvatarImage } from "./assets";

export const getAvatarHtml = (avatar = null, t = () => {}) => {
    if (!avatar || avatar === "" || !getAvatarImage(avatar) || getAvatarImage(avatar) === "") {
        return <div>{t("edit_profile.no_avatar")}</div>;
    }

    return <img className="avatar-img" alt="Avatar" src={getAvatarImage(avatar)} />;
};
