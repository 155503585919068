/**
 *
 * @param {string} key
 * @param {string | number} value
 * @returns add to body the attribute with given key and value
 */
export const setAttributeToBody = (key = null, value = null) => {
    if (!key || key === "") return;
    if (!value) return;

    document.body.setAttribute(key, value);
};

/**
 *
 * @param {string} key
 * @returns remove from body the attribute with given key
 */
export const removeAttributeFromBody = (key = null) => {
    if (!key || key === "") return;

    document.body.removeAttribute(key);
};

/**
 *
 * @param {string} cssClass
 * @returns add to css classes of body the css class defined by cssClass property
 */
export const addCssClassToBody = (cssClass = "") => {
    if (!cssClass || cssClass === "") return;

    document.body.classList.add(cssClass);
};

/**
 *
 * @param {string} cssClass
 * @returns remove from css classes of body the css class defined by cssClass property
 */
export const removeCssClassToBody = (cssClass = "") => {
    if (!cssClass || cssClass === "") return;

    document.body.classList.remove(cssClass);
};
