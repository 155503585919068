import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**COMPONENTS */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Chat } from "../../components/Chat";

/**Firebase Context */
import { FirebaseContext } from "../../firebase/firebase";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**UTILS */
import { SITE_TITLE, INDEX_URL, PAGES } from "../../helpers/constants";
import { seo } from "../../helpers/seo";
import { scrollTop } from "../../helpers/scroll";
import history from "../../helpers/history";

/**SCSS */
import "./_messages.scss";

const getChats = (chats, t, onClick) => {
    if (!chats || chats.length === 0) {
        return <p>{t("messages.no_messages")}</p>;
    }

    return chats.map((chat, idx) => {
        const data = chat.data;
        return <Chat key={idx} chat={data} chatId={chat.id} t={t} onClick={onClick} idx={idx + 1} />;
    });
};

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [], //new Array(8).fill(1),
        };

        this.onChatClick = this.onChatClick.bind(this);
    }

    async componentDidMount() {
        //get chats
        seo({
            title: SITE_TITLE + " || Chats",
            metaDescription: "",
        });

        scrollTop();

        await this.loadChats();
    }

    async loadChats() {
        this.props.activateLoader();
        try {
            const chats = await this.context?.api?.getChats(this.props?.user?.canteen_id);

            this.setState({
                chats,
            });
        } catch (e) {
            console.warn("error loadChats");
            console.warn(e);
        } finally {
            this.props.deactivateLoader();
        }
    }

    onChatClick(e, chat, id) {
        if (!(chat || id)) return;

        history.pushWithCheck(INDEX_URL + PAGES.CHAT, { chat, id });
    }

    render() {
        const { t } = this.props;
        const { chats } = this.state;
        return (
            <>
                <Header />
                <div className="messages-container">
                    <div className="head-area">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="default-heading text-uppercase">
                                    <h2>
                                        <strong>{t("messages.title")}</strong>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-10 col-md-8 messages__list">
                        {getChats(chats, t, this.onChatClick)}
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

Messages.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Messages));
