/**Packages */
import { Fragment } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Components */
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import GoBackLink from "../../components/GoBackLink";
import Margin from "../../components/Margin";
import Recipe from "../../components/Recipe";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";
import { FavoriteRecipe } from "../../components/FavoriteRecipe";

/**Helpers */
import { getCurrentLanguage, getClassNameByComponent } from "../../helpers/utils";
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_favorites.scss";

class Favorites extends Back {
    constructor(props) {
        super(props);

        if (!checkIfChildrenModeIsActivaded()) {
            this.goBack();
        }

        this.state = {
            recipes: this?.props?.user?.selectedProfile?.favorite_recipes || [] || null,
        };
    }

    componentDidMount() {}

    render() {
        const { recipes } = this.state;

        return (
            <Fragment>
                <Header />
                <section id="favorites-container-id" className="middle-content inner-area favorites-area">
                    <GoBackPlusTitle
                        onClick={this.goBack}
                        goBackText={this.props.t("favorites.go_home")}
                        title={this.props.t("favorites.title")}
                    />

                    <div className="container maxwidth">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-12 col-md-12">
                                {recipes?.length > 0 ? (
                                    recipes.map((recipe, idx, otherItems) => {
                                        const { id, occasion_id, course_name } = recipe;
                                        const cssComponentClassName = getClassNameByComponent(
                                            occasion_id && occasion_id !== 4
                                                ? course_name[process.env.REACT_APP_DEFAULT_LNG]
                                                : "merienda",
                                        );
                                        return (
                                            <div
                                                key={`favorite-${idx}`}
                                                className={`master-recipe-container ${cssComponentClassName}`}
                                            >
                                                <Recipe id={id} recipe={recipe} onRecipeClick={() => {}} />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="no-data-container">{this.props.t("favorites.no_items")}</div>
                                )}
                            </div>
                        </div>
                        <Margin marginTop="32px" />
                        <GoBackLink text={this.props.t("favorites.go_home")} onClick={this.goBack} />
                        <Margin marginBottom="32px" />
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
    };
};

Favorites.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Favorites));
