import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/**Utils */
import { MERIENDA_COMPONET_NAME } from "../../helpers/constants";
import { getCurrentLanguage, getIconByComponent, getClassNameByComponent } from "../../helpers/utils";
import { getRecipesByComponentsIds } from "../../helpers/menu";
import { getTranslatedString } from "../../helpers/translation";

/**Components */
import Recipe from "../Recipe";

/**SCSS */
import "./_menu.scss";

const getRecipes = (
    recipesByCourses,
    onRecipeClick,
    favoriteRecipeEnabled,
    rateRecipeEnabled,
    resetRateComponent,
    onRateRecipeClicked,
) => {
    const entries = Object.entries(recipesByCourses);

    return entries.map(([entryKeyId, fullObj]) => {
        const { recipes } = fullObj;
        const { id, course_name, name, occasion_id } = recipes[0];

        const cssComponentClassName = getClassNameByComponent(
            occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
        );
        return (
            <div key={entryKeyId} className={`master-recipe-container ${cssComponentClassName}`}>
                {getIconByComponent(
                    occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
                )}
                <h3 className="recipe__title">
                    {occasion_id && occasion_id !== 4
                        ? getTranslatedString(course_name, getCurrentLanguage())
                        : MERIENDA_COMPONET_NAME[getCurrentLanguage()]}
                </h3>
                {recipes.map((recipe, idx) => {
                    recipe.isOccasionSingle = true;
                    return (
                        <Recipe
                            id={`${entryKeyId}-${idx}`}
                            key={`${entryKeyId}-${idx}`}
                            recipe={recipe}
                            onRecipeClick={onRecipeClick}
                            favoriteRecipeEnabled={favoriteRecipeEnabled}
                            rateRecipeEnabled={rateRecipeEnabled}
                            resetUnderComponent={resetRateComponent}
                            onRateRecipeClicked={onRateRecipeClicked}
                        />
                    );
                })}
            </div>
        );
    });
};

const getRecipesWithHeader = (
    recipes,
    onRecipeClick,
    favoriteRecipeEnabled,
    rateRecipeEnabled,
    resetRateComponent,
    onRateRecipeClicked,
) => {
    const { id: course_id, course_name, name, occasion_id } = recipes[0];

    const cssComponentClassName = getClassNameByComponent(
        occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
    );
    return (
        <>
            <div className={"recipe-container-one-occasion " + cssComponentClassName}>
                {getIconByComponent(
                    occasion_id && occasion_id !== 4 ? course_name[process.env.REACT_APP_DEFAULT_LNG] : "merienda",
                )}
                <h3 className="recipe__title">
                    {occasion_id && occasion_id !== 4
                        ? getTranslatedString(course_name, getCurrentLanguage())
                        : MERIENDA_COMPONET_NAME[getCurrentLanguage()]}
                </h3>
                {recipes.map((recipe, idx) => {
                    recipe.isOccasionSingle = true;
                    return (
                        <Recipe
                            id={`${course_id}-${idx}`}
                            key={`${course_id}-${idx}`}
                            recipe={recipe}
                            onRecipeClick={onRecipeClick}
                            favoriteRecipeEnabled={favoriteRecipeEnabled}
                            rateRecipeEnabled={rateRecipeEnabled}
                            resetUnderComponent={resetRateComponent}
                            onRateRecipeClicked={onRateRecipeClicked}
                        />
                    );
                })}
            </div>
        </>
    );
};

const getCoursesFormatted = (recipes = []) => {
    return recipes && recipes.length > 0
        ? Array.from(
              new Set(
                  recipes.map(recipe => {
                      return {
                          id: recipe?.course_id,
                          name: recipe?.course_name,
                      };
                  }),
              ),
          )
        : [];
};

const MenuComponent = props => {
    const {
        recipes = [],
        onRecipeClick = () => {},
        favoriteRecipeEnabled = true,
        rateRecipeEnabled = false,
        resetRateComponent = false,
        onRateRecipeClicked = () => {},
        onlyOneColumn = false,
        idForMasterContainer = "menu",
    } = props;
    const { t, i18n } = useTranslation();

    const courses = getCoursesFormatted(recipes),
        courses_ids = courses.map(course => {
            return course.id;
        }),
        isCourseSingle = courses_ids.length <= 1,
        recipesByCourses = getRecipesByComponentsIds(courses_ids, recipes, courses);

    const className =
        onlyOneColumn || isCourseSingle || recipes?.length <= 1
            ? "row align-items-center justify-content-around menu-container menu-container__full"
            : "row align-items-center justify-content-around menu-container menu-container__middle";

    return (
        <div id={idForMasterContainer} className={className}>
            {recipes && recipes.length > 0 ? (
                isCourseSingle ? (
                    getRecipesWithHeader(
                        recipes,
                        onRecipeClick,
                        favoriteRecipeEnabled,
                        rateRecipeEnabled,
                        resetRateComponent,
                        onRateRecipeClicked,
                    )
                ) : (
                    getRecipes(
                        recipesByCourses,
                        onRecipeClick,
                        favoriteRecipeEnabled,
                        rateRecipeEnabled,
                        resetRateComponent,
                        onRateRecipeClicked,
                    )
                )
            ) : (
                <div className="recipe-no-elements-container">{t("menu.no_elements")}</div>
            )}
        </div>
    );
};

export default MenuComponent;
