/**Packages */
import { Component } from "react";
import { withTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel";

/**Helpers */
import { ALL_FILTERS } from "../../helpers/constants";
import { getCurrentLanguage } from "../../helpers/utils";
import { getTranslatedString } from "../../helpers/translation";

const getNome = (nome, nome2) => {
    if (nome && nome !== "") return nome;

    return nome2;
};

class TagsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: props && props.tags ? props.tags : [],
            activeTags: props && props.activeTags ? props.activeTags : [],
            options: {
                loop: false,
                margin: 10,
                responsiveClass: true,
                autoplay: false,
                nav: true,
                autoWidth: false,
                dots: false,
                startPosition: 0,
                responsive: {
                    0: {
                        items: 2,
                    },
                    480: {
                        items: 3,
                    },
                    1199: {
                        items: 4,
                    },
                    1200: {
                        nav: false,
                        items: 6,
                    },
                },
            },
        };
    }

    componentWillReceiveProps(nextProps) {
        let newState = {};
        if (nextProps && nextProps.tags) {
            newState = {
                tags: nextProps.tags,
                activeTags: nextProps.activeTags ? nextProps.activeTags : [],
                options: {
                    ...this.state.options,
                    startPosition: 0,
                },
            };
        } else {
            newState = {
                tags: [],
                activeTags: [],
                options: {
                    ...this.state.options,
                },
            };
        }

        this.setState(newState, e => {
            this.refresh({});
        });
    }

    refresh() {
        // re-renders the component
        this.setState({});
    }

    render() {
        const { onClick } = this.props,
            { tags, activeTags } = this.state;

        return (
            <>
                <OwlCarousel className="tags owl-carousel" {...this.state.options}>
                    <li
                        key={"tag-all"}
                        className={activeTags.length === 1 && activeTags[0] === ALL_FILTERS ? "item active" : "item"}
                        onClick={e => onClick(e, ALL_FILTERS)}
                    >
                        <span to={"#"}>{this.props.t("news.all")}</span>
                    </li>
                    {tags &&
                        Array.isArray(tags) &&
                        tags.length > 0 &&
                        tags.map((tag, idx) => {
                            return (
                                <li
                                    key={"tag-" + idx}
                                    className={activeTags.includes(tag) ? "item active" : "item"}
                                    onClick={e => onClick(e, tag)}
                                >
                                    <span to={"#"}>
                                        {
                                            //TODO: check if is correct
                                            getNome(
                                                getTranslatedString(tag?.nome, getCurrentLanguage()),
                                                tag.tags && tag.tags.length > 0 ? tag.tags[0] : "",
                                            )
                                        }
                                    </span>
                                </li>
                            );
                        })}
                </OwlCarousel>
            </>
        );
    }
}

export default withTranslation()(TagsSlider);
