// history.js
import { createBrowserHistory } from "history";

/**
 * The util export history object to use to navigate in app
 */
const history = createBrowserHistory();

history.pushWithCheck = (path = null, state = null) => {
    if (!path) return;
    if (path === history.location.pathname) return;

    history.push(path, state);

    /*
        Use here to send pageview in case is needed
        window.dataLayer.push({
            event: "pageview",
        });
    */
};

history.checkIfAlreadyInPage = (path = null) => {
    return path === history.location.pathname;
};

history.checkIfAlreadyInLogin = (path = null) => {
    return path === "/login" || path.indexOf("login") !== -1;
};

export default history;
