import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Component */
import { AvatarList } from "../../components/AvatarList";
import { ActionButton } from "../../components/ActionButton";
import Margin from "../../components/Margin";
import Profile from "../../components/Profile";
import OnChange from "../../components/OnChange";
import Modal from "../../components/Modal";
import Back from "../../components/Back";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GoBackPlusTitle from "../../components/GoBackPlusTitle";

/**Utils */
import { checkIfChildrenModeIsActivaded } from "../../helpers/view";
import { success, error } from "../../helpers/toast";
import { getAvatarImage } from "../../helpers/assets";
import { scrollTop } from "../../helpers/scroll";
import { getAvatarHtml } from "../../helpers/avatar";
import { api as httpAPI } from "../../api/index";
import { seo } from "../../helpers/seo";

/**Redux */
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";
import {
    USER_SELECTED_PROFILE,
    USER_UPDATE_PROFILE,
    USER_DELETE_PROFILE,
    USER_UPDATE_AVATAR_PROFILE,
} from "../../redux/actions/userActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_edit_profile.scss";
class EditProfile extends Back {
    constructor(props) {
        super(props);

        this.state = {
            profile: props?.location?.state?.profile,
            docId: props?.location?.state?.profile?.docId,
            id: props?.location?.state?.id,
            avatarModal: false,
        };

        this.closeChangeAvatarModal = this.closeChangeAvatarModal.bind(this);
        this.openChangeAvatarModal = this.openChangeAvatarModal.bind(this);
        this.onSelectedAvatar = this.onSelectedAvatar.bind(this);
        this.updateAvatarProfile = this.updateAvatarProfile.bind(this);
        this.onChangeProfileName = this.onChangeProfileName.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onDeleteProfile = this.onDeleteProfile.bind(this);
    }
    async componentDidMount() {
        if (!(this.state.profile || this.state.id)) {
            this.goBack();
            return;
        }

        scrollTop();
    }
    async saveProfile(e) {
        e.preventDefault();

        try {
            this.props.activateLoader();
            const result = await this.context.api.updateProfile(this.state.docId, this.state.profile);
            if (result) {
                this.props.updateProfile(this.state.docId, this.state.profile);
                success(this.props.t("edit_profile.success.update_profile"));
            } else {
                error(this.props.t("account.error.general"));
            }
        } catch (e) {
            error(this.props.t("edit_profile.error.general"));
            console.log(e);
        } finally {
            this.props.deactivateLoader();
            this.goBack();
        }
    }
    async onDeleteProfile(e) {
        e.preventDefault();

        const profile = this.state.profile;

        if (!profile || profile === {}) return;

        this.props.activateLoader();

        try {
            const token = await this.context?.api?.getToken();

            if (!token || token === "") {
                //Case problem with token id
                error(this.props.t("account.error.profile.token_error"));
                return;
            }

            if (this.props.user.profiles.length <= 1) {
                //Case at least 1 profile
                error(this.props.t("account.error.profile.at_least_one_profile"));
                return;
            }

            const docId = profile.docId;
            const wasSelectedProfile = docId === this.props.user.selectedProfile.docId;
            const result = await httpAPI.profile.deleteProfile(token, docId);

            if (result && result.success) {
                this.props.deleteProfile({ docId });
                if (wasSelectedProfile) {
                    await this.props.setSelectedProfile(this.props.user.profiles[0], this.context);
                }
                success(this.props.t("account.success.delete_profile"));
            } else {
                error(this.props.t("account.error.profile.delete_service_error"));
            }
        } catch (e) {
            console.log("Error in onDeleteProfile method");
            console.log(e);
        } finally {
            this.goBack();
            this.props.deactivateLoader();
        }
    }
    onChangeProfileName(e, value) {
        e.preventDefault();
        const profile = this.state.profile;
        profile.name = value;
        this.setState({ profile });
    }
    closeChangeAvatarModal(e) {
        this.setState({
            avatarModal: false,
        });
    }
    openChangeAvatarModal(e) {
        this.setState({
            avatarModal: true,
        });
    }
    onSelectedAvatar(e, avatar) {
        if (this.state.profile) {
            const profile = this.state.profile;
            profile.avatar = avatar;
            this.setState({ profile, avatarModal: false });
        }
    }
    async updateAvatarProfile(e, selectedAvatar) {
        this.props.activateLoader();
        try {
            if (selectedAvatar) {
                const docId = this.props.user.selectedProfile.docId;
                if (docId) {
                    const result = await this.context.api.updateProfileAvatar(docId, selectedAvatar);
                    if (result) {
                        this.props.updateAvatarProfile(docId, selectedAvatar);
                    }
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            this.props.deactivateLoader();
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header />
                {!checkIfChildrenModeIsActivaded() ? (
                    <>
                        <section id="edit-profile-container" className="middle-content inner-area">
                            <GoBackPlusTitle onClick={this.goBack} goBackText="" />
                            <div className="container maxwidth">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-10 d-flex justify-content-center flex-column">
                                        <div className={`column-details`}>
                                            <div className={`default-heading`}>
                                                <h3>{this.props.t("edit_profile.title")}</h3>
                                            </div>
                                        </div>
                                        <div className="profile-info-container">
                                            <label className="name">{this.props.t("edit_profile.name")}</label>
                                            <Input
                                                id={"profile-input-text-" + this.state.id}
                                                type="text"
                                                placeholder={this.props.t("edit_profile.name")}
                                                value={this.state.profile?.name}
                                                onChange={e => this.onChangeProfileName(e, e?.target?.value)}
                                                className="profile-name-input"
                                            />
                                        </div>
                                        <div className="school-name">{this.state.profile.canteen_name}</div>
                                        <div>
                                            <Profile
                                                key={"account-profile-" + this.state.id}
                                                profile={this.state.profile}
                                                onClick={null}
                                                onDeleteClick={null}
                                                canModifyName={false}
                                                seePen={false}
                                                seeName={false}
                                                canSeeAvatar={false}
                                            />
                                        </div>
                                        <div className="edit-avatar-container">
                                            {getAvatarHtml(this.state.profile?.avatar, this.props.t)}

                                            <button
                                                className="btn btn-primary avatar-button"
                                                onClick={this.openChangeAvatarModal}
                                            >
                                                {this.props.t("edit_profile.edit_avatar")}
                                            </button>
                                        </div>
                                        <div className="action-buttons-container">
                                            <div className="single-action-button-container">
                                                <ActionButton
                                                    text={this.props.t("edit_profile.undo")}
                                                    type="undo"
                                                    onClick={this.goBack}
                                                />
                                            </div>
                                            <div className="single-action-button-container">
                                                <ActionButton
                                                    text={this.props.t("edit_profile.save")}
                                                    type="confirm"
                                                    onClick={this.saveProfile}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="delete-bottom-button">
                            <ActionButton
                                text={this.props.t("edit_profile.delete")}
                                type="delete"
                                onClick={this.onDeleteProfile}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <section id="edit-profile-container" className="children middle-content inner-area">
                            <div className="topper-space">
                                <button className="back-btn-top" onClick={this.goBack}>
                                    <i className="icon-small-arrow-left icon-left" />
                                </button>
                                <div className="column-details">
                                    <div className="default-heading">
                                        <h3>{this.props.t("edit_profile.title")}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-8 offset-md-2 chldren-dropdown-container">
                                <div className="children-profile-name">
                                    {this.props.t("edit_profile.hello")} {this.props.user.selectedProfile.name}
                                </div>
                                {getAvatarHtml(this.state.profile?.avatar, this.props.t)}

                                <label className="change-avatar">
                                    {this.props.t("edit_profile.change_avatar_simple")}
                                </label>
                                <AvatarList
                                    selectedAvatar={this.state.profile.avatar}
                                    onClick={this.updateAvatarProfile}
                                />
                                <button className="btn btn-primary avatar-btn" onClick={this.goBack}>
                                    {this.props.t("edit_profile.confirm")}
                                </button>
                            </div>
                        </section>
                    </>
                )}
                <Footer />
                <Modal id="choose-avatar-image" visible={this.state.avatarModal} close={this.closeChangeAvatarModal}>
                    <section id="modal-select-avatar" className="middle-content inner-area">
                        <div className="container maxwidth">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 d-flex justify-content-center flex-column">
                                    <div className={`column-details`}>
                                        <div className={`default-heading`}>
                                            <h3>{this.props.t("edit_profile.change_avatar.title")}</h3>
                                        </div>
                                    </div>
                                    <AvatarList
                                        selectedAvatar={this.state.profile.avatar}
                                        onClick={this.onSelectedAvatar}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        updateProfile: (id, profile) => dispatch({ type: USER_UPDATE_PROFILE, payload: { id, profile } }),
        deleteProfile: payload => dispatch({ type: USER_DELETE_PROFILE, payload }),
        updateAvatarProfile: (id, avatar) => dispatch({ type: USER_UPDATE_AVATAR_PROFILE, payload: { id, avatar } }),
        setSelectedProfile: async (payload, context) => {
            dispatch({ type: USER_SELECTED_PROFILE, payload });
            await context?.api?.setSelectedProfile(payload ? payload.docId : null);
        },
    };
};

EditProfile.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
