/**Packages */
import { Component } from "react";

/**Utils */
import { PAGES, INDEX_URL } from "../../helpers/constants";
import history from "../../helpers/history";

class Back extends Component {
    constructor(props) {
        super(props);

        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        history.goBack();
    }

    goHome() {
        history.pushWithCheck(INDEX_URL + PAGES.HOME);
    }
}

export default Back;
