import * as moment from "moment";

/**Utils */
import { checkIfStringIsHtml } from "../../helpers/utils";
import { getIfUseImageMessage } from "../../helpers/enviroment";

/**Images */
import messageSvg from "../../images/message_icon.svg";

/**SCSS */
import "./_chat.scss";

const getLastMessageHtml = message => {
    const messageToDisaply = message.split("<br/>")[0] + "...";
    return !checkIfStringIsHtml(message) ? (
        <p className="chat__last-message">{messageToDisaply}</p>
    ) : (
        <p className="chat__last-message" dangerouslySetInnerHTML={{ __html: messageToDisaply }} />
    );
};
export const Chat = ({ chat, t, onClick, idx, chatId }) => {
    const { messages, subject, date, canteen_id } = chat;
    const lastMessage = messages[messages.length - 1].message;
    return (
        <div className="chat-container col-12">
            <div className="col-2 chat__icon">
                {getIfUseImageMessage() ? <img src={messageSvg} alt="Message" /> : <span className="fa fa-comments" />}
            </div>
            <div className="col-8 chat__last-message-container">
                <p className="chat__date">{moment(date).format("DD/MM/YYYY HH:mm:ss")}</p>
                <p className="chat__title">{subject}</p>
                {getLastMessageHtml(lastMessage)}
            </div>
            <div
                className="col-2 chat__icon center clickable"
                onClick={e => {
                    onClick(e, chat, chatId);
                }}
            >
                <span className="fa fa-angle-right" />
            </div>
        </div>
    );
};
