/**Packages */
import { createRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**Helpers */
import { SITE_TITLE, INDEX_URL, PAGES, LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY } from "../../helpers/constants";
import { api } from "../../api";
import { seo } from "../../helpers/seo";
import { success, error } from "../../helpers/toast";
import { addToLocalStorage } from "../../helpers/localStorage";
import { validateEmail, validatePassword } from "../../helpers/validators";
import { getIfSignInAnonymouslyIsEnabled } from "../../helpers/enviroment";
import history from "../../helpers/history";

/**Components */
import HandlerUser from "../../components/HandlerUser";
import Input from "../../components/Input";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

/**Images */
import iconeHP from "../../images/icone-hp.svg";
import rightGraphic from "../../images/right-graphic.svg";
import leftGraphic from "../../images/left-graphic.svg";

/**Redux */
import { USER_LOGGED_IN } from "../../redux/actions/userActions";
import { FROM_REGISTRATION } from "../../redux/actions/registerActions";
import { LOADER_VISIBLE, LOADER_INVISIBLE } from "../../redux/actions/loaderActions";

/**FirebaseContext */
import { FirebaseContext } from "../../firebase/firebase";

/**SCSS */
import "./_login.scss";
class Login extends HandlerUser {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            email: "",
            password: "",
        };

        this.login = this.login.bind(this);
        this.formValidation = this.formValidation.bind(this);
        this.signInAnonymously = this.signInAnonymously.bind(this);
        this.checkCanteenByReferrer = this.checkCanteenByReferrer.bind(this);

        this.inputEmailRef = createRef();
        this.inputPasswordRef = createRef();
    }

    async componentDidMount() {
        seo({
            title: SITE_TITLE + " || Login",
            metaDescription: "",
        });

        await this.checkCanteenByReferrer();
    }

    async checkCanteenByReferrer() {
        if (
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.checkCanteenName
        ) {
            this.props.activateLoader();
            const callResult = await api.canteen.checkCanteenByReferrer(this.props.location.state.referrer);

            if (callResult && callResult.success) {
                const { data } = callResult,
                    { id } = data;

                addToLocalStorage(LOCAL_STORAGE_NEW_CANTEEN_LOGGED_KEY, id);

                if (!this.checkUserData()) {
                    await this.signInAnonymously(null, data);
                } else {
                    //User is already logged
                    await this.context.api.getCanteenData(id);
                    history.pushWithCheck(INDEX_URL + PAGES.CREATE_PROFILE);
                }
            }

            this.props.deactivateLoader();
        }
    }

    formValidation() {
        const { email, password } = this.state;
        const validClassName = "form-control";
        const errorClassName = validClassName + " error";

        if (!validateEmail(email)) {
            this.inputEmailRef.current.className = errorClassName;
            this.inputEmailRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("login.email") }));
            return false;
        } else {
            this.inputEmailRef.current.className = validClassName;
        }
        if (!validatePassword(password)) {
            this.inputPasswordRef.current.className = errorClassName;
            this.inputPasswordRef?.current?.focus();
            error(this.props.t("errors.no_data", { field: this.props.t("login.password") }));
            return false;
        } else {
            this.inputPasswordRef.current.className = validClassName;
        }

        return true;
    }

    async login(e) {
        e.preventDefault();
        const { email, password } = this.state;
        try {
            this.props.activateLoader();
            if (this.formValidation()) {
                const user = await this.context.api.loginWithEmailAndPassword(email, password);
                if (user) {
                    //console.log("go to homepage");
                } else {
                    error(this.props.t("login.error_login"));
                    this.props.deactivateLoader();
                }
            } else {
                //error(this.props.t("login.error_parameters"));
                this.props.deactivateLoader();
            }
        } catch (e) {
            console.log(e);
            this.props.deactivateLoader();
        } finally {
            //this.props.deactivateLoader();
        }
    }

    async signInAnonymously(event = null, canteenData = null) {
        if (event) {
            event.preventDefault();
        }

        try {
            this.props.activateLoader();
            if (getIfSignInAnonymouslyIsEnabled()) {
                const user = await this.context.api.signUpUserAnonymously(canteenData);
                if (user) {
                    success(this.props.t("signup.success"));
                    history.pushWithCheck(INDEX_URL + PAGES.CREATE_PROFILE);
                } else {
                    error(this.props.t("login.error_login"));
                    this.props.deactivateLoader();
                }
            } else {
                error(this.props.t("login.error_anonymous_access_not_enabled"));
                this.props.deactivateLoader();
            }
        } catch (e) {
            console.log(e);
            this.props.deactivateLoader();
        } finally {
            //console.log("signInAnonymously method finished");
        }
    }

    renderLoginForm() {
        return (
            <>
                {/**
					For errors add error in className and a span with the error;
				 */}
                <form id="login_form">
                    {/*Mail*/}
                    <Input
                        innerRef={this.inputEmailRef}
                        type="email"
                        className="form-control"
                        id="login_input_email"
                        name="email"
                        autocomplete="off"
                        placeholder={this.props.t("login.email")}
                        value={this.state.email}
                        onChange={e => this.onChange(e, "email")}
                        onBlur={this.onBlur}
                    />
                    {/*Password*/}
                    <Input
                        innerRef={this.inputPasswordRef}
                        type="password"
                        className="form-control"
                        id="login_input_password"
                        name="password"
                        autocomplete="new-password"
                        placeholder={this.props.t("login.password")}
                        value={this.state.password}
                        onChange={e => this.onChange(e, "password")}
                        onBlur={this.onBlur}
                    />
                    <div className="form-group">
                        <div className="btns-area">
                            <Link to={"#"}>{this.props.t("login.required")}</Link>
                            <button type="submit" className="btn btn-primary" onClick={this.login}>
                                {this.props.t("login.button")}
                            </button>
                            <div className="forget-password-container">
                                <Link to={INDEX_URL + "reset-password"}>{this.props.t("login.forget_password")}</Link>
                            </div>
                            {/*
                                getIfSignInAnonymouslyIsEnabled() && (
                                    <div className="sign-in-anonymously-container">
                                        <Link className="anonymous-button" onClick={this.signInAnonymously}>
                                            {this.props.t("login.sign_in_anonymously")}
                                        </Link>
                                    </div>
                                )
                            */}
                        </div>
                    </div>
                </form>
            </>
        );
    }

    render() {
        const page = (
            <>
                <Header />
                <div className="graphic left-graphic">
                    <img src={leftGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <div className="graphic right-graphic">
                    <img src={rightGraphic} className="img-fluid ml-auto d-block" alt="..." />
                </div>

                <section className="middle-content">
                    <img id="hp-icone-image" src={iconeHP} />
                    <div className="container form-area maxwidth">
                        <div className="head-area">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="default-heading">
                                        <h3>{this.props.t("login.title")}</h3>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="register-here">
                                        <Link to={INDEX_URL + "registration"}>{this.props.t("login.register")}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-12">{this.renderLoginForm()}</div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );

        return super.render(page);
    }
}

const mapStateToProps = (state, ownProps) => {
    // ... computed data from state and optionally ownProps
    return {
        ...state,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateLoader: () => dispatch({ type: LOADER_VISIBLE }),
        deactivateLoader: () => dispatch({ type: LOADER_INVISIBLE }),
        loggedIn: user => dispatch({ type: USER_LOGGED_IN, payload: user }),
        fromRegisterData: data => dispatch({ type: FROM_REGISTRATION, payload: data }),
    };
};

Login.contextType = FirebaseContext;

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Login));
